function EditProjectMenuItem(
  parentObj,
  attachTo,
  val,
  label,
  allowdelete,
  icon,
  iconstyle,
  textstyle,
  isDisabled,
  disabledMessage
) {
  var _i = this;
  // params
  _i.parentObj = parentObj;
  _i.attachTo = attachTo;
  _i.label = label;
  _i.val = val;
  _i.icon = icon;
  _i.iconstyle = iconstyle;
  _i.textstyle = textstyle;
  _i.guid = Awe.getGuid();
  _i.isDisabled = isDisabled;
  _i.disabledMessage = disabledMessage;
  _i.isAddPhaseItem = val === -2;

  // ids
  if (label) {
    _i.btnID =
      _i.label.replace(/\s+/g, '-').toLowerCase() + '-button' + _i.guid;
  }
  _i.phasesAlertPopupForAdd = phasesAlertPopup(
    _i.disabledMessage ?? '',
    _i.container
  );

  _i.containerMouseover = function (evt) {
    cancelEvent(evt);
  };

  _i.deleteIconMouseover = function (evt) {
    const el = _i.deleteb.container ?? _i.container;
    _i.isDisabled && _i.phasesAlertPopupForDelete.show(el, 8);
    cancelEvent(evt);
  };

  _i.blur = function () {
    _i.container.className = 'blockFloatNot fnt-r-14 editprojectMenuItem';
    focusElementAfterDelay('#' + _i.deleteb.container.id);
  };

  _i.deleteItem = function () {
    if (window.accountSettings.moduleEnabled('deletion_modal')) {
      var phaseId = _i.parentObj.parentObj.phases[_i.val].phase.id;
      if (!phaseId) {
        _i.parentObj.deleteObject(_i.val, null, null);
      } else {
        window.loadDeletionModal(_i.parentObj.reactContainer, phaseId);
      }
    } else {
      xRemoveEventListener(
        _i.container,
        'mouseout',
        _i.containerMouseout,
        true
      );
      _i.container.className =
        'blockFloatNot fnt-r-14 editprojectMenuItemHighlite';
      focusFirstChildElementAfterDelay('#content');

      var confirmDelete = confirmationPopup(
        I18n.t('msg_delete_phase_confirm'),
        I18n.t('lbl_delete'),
        function () {
          _i.container.className = 'blockFloatNot fnt-r-14 editprojectMenuItem';
          xAddEventListener(
            _i.container,
            'mouseout',
            _i.containerMouseout,
            true
          );
          focusElementAfterDelay('#' + _i.deleteb.container.id);

          var error = function (response) {
            if (response && response.responseText) {
              var errorId = JSON.parse(response.responseText).errorId;
              if (errorId === 'err_cannot_delete_assignable') {
                var prompt =
                  I18n.t('err_cannot_delete_phase') +
                  '</br>' +
                  '<a target="_blank" href="https://help.smartsheet.com/articles/2481096">' +
                  I18n.t('msg_how_to_remove_data') +
                  '</a>';
                notificationPopup('', prompt, 10000, {
                  escaped: true,
                }).cancelTimeout();
              }
            }
          };
          _i.parentObj.deleteObject(_i.val, null, error);
          focusElementAfterDelay('#' + _i.btnID);
        },
        function () {
          _i.container.className = 'blockFloatNot fnt-r-14 editprojectMenuItem';
          xAddEventListener(
            _i.container,
            'mouseout',
            _i.containerMouseout,
            true
          );
          focusElementAfterDelay('#' + _i.deleteb.container.id);
        },
        _i.deleteb.container,
        _i.blur,
        190
      );
      confirmDelete.show(_i.deleteb.container);
      _i.container.className = 'blockFloatNot fnt-r-14 editprojectMenuItem';
      focusElementAfterDelay('#' + _i.deleteb.container.id);
    }
  };

  _i.containerMouseout = function (evt) {
    cancelEvent(evt);
  };

  _i.deleteIconMouseout = function (evt) {
    _i.isDisabled && _i.phasesAlertPopupForDelete.hide();
    cancelEvent(evt);
  };

  _i.onClick = function () {
    if (_i.isDisabled && _i.isAddPhaseItem) return;
    _i.parentObj.menuClickCallback(_i, _i.val);
    focusFirstChildElementAfterDelay('#content');
  };

  _i.handleKeypress = function (e) {
    if (typeof e === 'undefined' && window.event) {
      e = window.event;
    }
    if (e.key === 'Enter' || e.key === ' ') {
      _i.onClick();
      return false;
    }
    return true;
  };
  _i.disabledAddPhaseStyle =
    _i.isDisabled && _i.isAddPhaseItem ? ' disabled' : '';
  _i.select = function () {
    _i.container.className = `blockFloatNot fnt-b-14 editprojectMenuItemSelected${_i.disabledAddPhaseStyle}`;
  };

  _i.unselect = function () {
    _i.container.className = `blockFloatNot fnt-r-14 editprojectMenuItem${_i.disabledAddPhaseStyle}`;
  };

  _i.constructor = function () {
    // NOTE new container
    _i.rowWrap = document.createElement('DIV');
    _i.rowWrap.className = 'blockFloatNot editprojectWrap';
    _i.rowWrap.style.display = 'flex';
    _i.rowWrap.role = 'button';

    _i.container = document.createElement('DIV');
    _i.container.setAttribute('role', 'button');
    _i.container.tabIndex = '0';
    _i.container.className = `blockFloatNot fnt-r-14 editprojectMenuItem${_i.disabledAddPhaseStyle}`;

    _i.container.id = _i.btnID;

    if (!allowdelete) _i.container.style.width = '190px';
    _i.attachTo.appendChild(_i.rowWrap);
    _i.rowWrap.appendChild(_i.container);

    if (_i.icon) {
      icon = document.createElement('DIV');
      icon.className = _i.iconstyle;
      getSprite(_i.icon, icon);
      _i.container.appendChild(icon);
    }
    _i.tagNameText = document.createElement('DIV');
    _i.tagNameText.className = _i.textstyle
      ? _i.textstyle
      : 'blockFloat editProjectMenuText';
    if (_i.isDisabled && _i.isAddPhaseItem) {
      _i.tagNameText.className += ' disabled';
    }
    _i.tagNameText.innerText = label;
    _i.container.appendChild(_i.tagNameText);
    xAddEventListener(_i.rowWrap, platform.click, _i.onClick, false);
    xAddEventListener(_i.rowWrap, 'keypress', _i.handleKeypress, false);

    if (_i.isDisabled && _i.isAddPhaseItem) {
      const mouseInOnAddPhase = () => {
        _i.phasesAlertPopupForAdd.show(_i.container);
      };
      const mouseOutOnAddPhase = () => {
        _i.phasesAlertPopupForAdd.hide();
      };
      xAddEventListener(_i.rowWrap, 'mouseover', mouseInOnAddPhase, true);
      xAddEventListener(_i.rowWrap, 'focus', mouseInOnAddPhase, true);
      xAddEventListener(_i.rowWrap, 'mouseout', mouseOutOnAddPhase, true);
      xAddEventListener(_i.rowWrap, 'blur', mouseOutOnAddPhase, true);
    }

    if (allowdelete) {
      xAddEventListener(_i.rowWrap, 'mouseover', _i.containerMouseover);
      xAddEventListener(_i.rowWrap, 'mouseout', _i.containerMouseout);
      _i.deleteb = new spritesButton(
        `blockFloat deleteButtonCategoryPhase${
          _i.isDisabled ? ' deleteButtonDisabled' : ''
        }`,
        null,
        compSpritesButtonsIcons.deleteButtonRest,
        _i.isDisabled
          ? compSpritesButtonsIcons.deleteButtonRest
          : compSpritesButtonsIcons.deleteButtonHover,
        _i.isDisabled
          ? compSpritesButtonsIcons.deleteButtonRest
          : compSpritesButtonsIcons.deleteButtonDown,
        'delete'
      );

      if (!_i.isDisabled) {
        _i.deleteb.setCallback(_i.deleteItem);
      }
      _i.deleteb.setButtonText('');
      _i.rowWrap.appendChild(_i.deleteb.container);
      _i.phasesAlertPopupForDelete = phasesAlertPopup(
        _i.disabledMessage ?? '',
        _i.deleteb.container
      );

      xAddEventListener(
        _i.deleteb.container,
        'mouseover',
        _i.deleteIconMouseover,
        true
      );
      xAddEventListener(
        _i.deleteb.container,
        'focus',
        _i.deleteIconMouseover,
        true
      );
      xAddEventListener(
        _i.deleteb.container,
        'mouseout',
        _i.deleteIconMouseout,
        true
      );
      xAddEventListener(
        _i.deleteb.container,
        'blur',
        _i.deleteIconMouseout,
        true
      );
    }
  };
  _i.constructor();
}

function EditProjectMenu(parentObj, attachTo) {
  var _i = this;

  // params
  _i.parentObj = parentObj;
  _i.attachTo = xGetElementById(attachTo);
  _i.isPhasesIntegrationEnabled = window.accountSettings.phases_feature_enabled;

  _i.deleteObject = function (val, callback, onError) {
    _i.parentObj.deleteObject(val, callback, onError);
  };

  _i.refreshMenu = function () {
    for (var i = 0; i < _i.items.length; i++) {
      _i.items[i].tagNameText.innerText = _i.parentObj.labelForMenuItem(
        _i.items[i].val
      );
    }
  };

  _i.menuClickCallback = function (item, value) {
    if (item && _i.parentObj) {
      var oldval = _i.selected.val;
      if (value > -3 || value === -6) {
        _i.selected.unselect();
        item.select();
        _i.selected = item;
      }
      _i.parentObj.menuItemCallback(value, oldval);
    }
  };

  _i.buildMenu = function (value) {
    _i.items = [];
    var mi = new EditProjectMenuItem(
      _i,
      _i.container,
      -1,
      _i.parentObj.labelForMenuItem(-1)
    );
    if (value == -1) {
      mi.select();
      _i.selected = mi;
    }
    _i.items.push(mi);

    if (
      _i.parentObj.projectIdFromQueryString() &&
      gData.accountSettings.moduleEnabled('app_project_members')
    ) {
      mi = new EditProjectMenuItem(
        _i,
        _i.container,
        -6,
        _i.parentObj.labelForMenuItem(-6)
      );
      if (value == -6) {
        mi.select();
        _i.selected = mi;
      }
      _i.items.push(mi);
    }
    const mappedSheetId = _i.parentObj.projectData?.mapped_sheet_id;
    const sheetUrl = getSmartsheetUrl(mappedSheetId);
    const phaseDeletePopupMsg = I18n.t('lbl_phases_alert_delete', {
      sheetUrl,
      style: 'text-decoration: underline',
    });
    const phaseAddPopupMsg = I18n.t('lbl_phases_alert_add', {
      sheetUrl,
      style: 'text-decoration: underline',
    });
    const shouldDisableAddPhaseButton = !!(
      _i.isPhasesIntegrationEnabled && mappedSheetId != null
    );

    var label = document.createElement('DIV');
    label.className = 'blockFloatNot fnt-r-14 editprojectMenuItemLabel';
    label.innerHTML = I18n.t('lbl_phases');
    _i.container.appendChild(label);

    _i.reactContainer = document.createElement('DIV');
    _i.reactContainer.className = 'blockFloat settingsInfoContentContainer';
    _i.reactContainer.id = 'reactContainer';
    _i.container.appendChild(_i.reactContainer);

    for (var i = 0; i < _i.parentObj.getNumberofPhases(); i++) {
      mi = new EditProjectMenuItem(
        _i,
        _i.container,
        i,
        _i.parentObj.labelForMenuItem(i),
        true /* allowdelete */,
        null /* icon */,
        null /* iconstyle */,
        null /* textstyle */,
        shouldDisableAddPhaseButton /* isDisabled */,
        phaseDeletePopupMsg /* disabledMessage */
      );
      if (value == i) {
        mi.select();
        _i.selected = mi;
      }
      _i.items.push(mi);
    }
    mi = new EditProjectMenuItem(
      _i,
      _i.container,
      -2 /* val - -2 is the index for the add phase button */,
      _i.parentObj.labelForMenuItem(-2),
      null /* allowdelete */,
      compSpritesButtonsIcons.smallAddIcon /* icon */,
      'blockFloat editProjectMenuIcon' /* iconstyle */,
      null /* textstyle */,
      shouldDisableAddPhaseButton /* isDisabled */,
      phaseAddPopupMsg /* disabledMessage */
    );
    _i.items.push(mi);

    var hideProjectActions = false;
    if (accountSettings.moduleEnabled('use_sheet_permission')) {
      // don't hide project actions resourcing admin, portfolio editor and project editor
      hideProjectActions = ![1, 2, 8].includes(window.whoami.user_type_id);
    }

    if (_i.parentObj.projectIdFromQueryString() && !hideProjectActions) {
      label = document.createElement('DIV');
      label.className = 'blockFloatNot fnt-r-14 editprojectMenuItemLabel';
      label.innerHTML = I18n.t('lbl_project_actions');
      _i.container.appendChild(label);
      _i.copyB = new EditProjectMenuItem(
        _i,
        _i.container,
        -3,
        I18n.t('lbl_create_a_copy'),
        false,
        compSpritesButtonsIcons.copyButton,
        'blockFloat editProjectMenuIcon2',
        'blockFloat editProjectMenuText2'
      );
      if (_i.parentObj.unarchive) {
        _i.archiveB = new EditProjectMenuItem(
          _i,
          _i.container,
          -4,
          I18n.t('lbl_unarchive_project'),
          false,
          compSpritesButtonsIcons.archiveButton,
          'blockFloat editProjectMenuIcon2',
          'blockFloat editProjectMenuText2'
        );
      } else {
        _i.archiveB = new EditProjectMenuItem(
          _i,
          _i.container,
          -4,
          I18n.t('lbl_archive_project'),
          false,
          compSpritesButtonsIcons.archiveButton,
          'blockFloat editProjectMenuIcon2',
          'blockFloat editProjectMenuText2'
        );
      }
      _i.deleteB = new EditProjectMenuItem(
        _i,
        _i.container,
        -5,
        I18n.t('lbl_delete_project'),
        false,
        compSpritesButtonsIcons.deleteButtonRest,
        'blockFloat editProjectMenuIcon2',
        'blockFloat editProjectMenuText2'
      );
    }
  };

  _i.resetMenu = function (value) {
    removeAllChildren(_i.container);
    _i.buildMenu(value);
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot editprojectMenuContainer';
    _i.container.setAttribute('role', 'region');
    _i.container.setAttribute('title', 'Project Settings Control');
    _i.attachTo.appendChild(_i.container);
    _i.buildMenu(-1);
  };

  _i.constructor();
}
