function SettingsAccountSettings(attachTo) {
  var _i = this;
  if (!(this instanceof SettingsAccountSettings)) {
    return new SettingsAccountSettings(attachTo);
  }

  _i.max_shown = 12;
  _i.accountownerDataInitialValue = null;
  _i.initialID = null;
  _i.expectedcallback = 0;
  _i.settingsSavedCallback = null; // This can be set to override default behavior.
  _i.attachTo = xGetElementById(attachTo);

  // ids
  _i.accountDropdownID = 'dropdown-container' + Awe.getGuid();
  _i.workdayDropdownID = 'dropdown-container' + Awe.getGuid();
  _i.localesDropdownID = 'dropdown-container' + Awe.getGuid();
  _i.accountBtnID = 'button' + Awe.getGuid();
  _i.workdayBtnID = 'button' + Awe.getGuid();
  _i.localesBtnID = 'button' + Awe.getGuid();

  _i.settingsSavedCompanyName = function (data) {
    if (data.name) {
      gPageHeader && gPageHeader.updateOrgName(data.name);
    }
  };

  _i.defaultSettingsSavedCallback = function () {
    notificationPopup(
      I18n.t('lbl_account_settings'),
      I18n.t('lbl_settings_saved_successfully'),
      4000
    );
  };

  _i.settingsSaved = function (data) {
    _i.expectedcallback--;
    if (_i.expectedcallback <= 0) {
      (_i.settingsSavedCallback || _i.defaultSettingsSavedCallback)();
    }
  };

  _i.saveSettings = function () {
    if (
      _i.accountOwnerId &&
      _i.initialID != _i.accountOwnerId &&
      _i.accountOwnerId != null
    ) {
      _i.expectedcallback++;
      gService.replaceAccountOwnerID(_i.accountOwnerId, _i.settingsSaved);
    }
    if (_i.companynamevalue != _i.companyname.getValue()) {
      gService.replaceAccountName(
        _i.companyname.getValue(),
        _i.settingsSavedCompanyName
      );
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'company name',
        _i.companyname.getValue(),
        _i.settingsSaved
      );
    }

    var newBillingEmail = _i.billingemail.getValue();
    if (gData.accountSettings.billing_email != newBillingEmail) {
      if (!verifyEmail(_i.billingemail.getValue())) {
        _i.billingemail.setAlert(I18n.t('err_valid_email'));
        return false;
      }
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'billing email',
        newBillingEmail,
        _i.settingsSaved
      );
    }

    var newBillingAddress = _i.billingAddress.getValue();
    if (gData.accountSettings.billing_address != newBillingAddress) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'billing address',
        newBillingAddress,
        _i.settingsSaved
      );
    }

    var daymask = [0x01, 0x02, 0x04, 0x8, 0x10, 0x20, 0x40];
    var dayindex = _i.firstWorkingDaySetting;
    var daysinworkweek = Number(
      _i.workdays.getValue().replace(/[^0-9\.]+/g, '')
    );
    if (
      !isNaN(daysinworkweek) &&
      daysinworkweek > 0 &&
      daysinworkweek <= 7 &&
      Number(daysinworkweek.toFixed()) == Number(daysinworkweek.toFixed(1)) &&
      dayindex >= 0 &&
      dayindex < 7
    ) {
      daysinworkweek = Number(daysinworkweek.toFixed());
      var working_days = 0;
      while (daysinworkweek > 0) {
        working_days |= daymask[dayindex];
        dayindex++;
        if (dayindex >= daymask.length) dayindex = 0;
        daysinworkweek--;
      }
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'working days',
        working_days,
        _i.settingsSaved
      );
    }
    dayindex = _i.firstWorkingDaySetting;
    if (_i.firstWorkingDaySettingChanged) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'first working day',
        dayindex,
        _i.settingsSaved
      );
    }

    var numhours = Number(_i.numhours.getValue().replace(/[^0-9\.]+/g, ''));
    if (!isNaN(numhours) && numhours > 0 && numhours <= 24) {
      numhours = Number(numhours);
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'hours in workday',
        numhours,
        _i.settingsSaved
      );
    }
    // we use this below, so if invalid, set to last saved value
    else {
      numhours = gData.accountSettings.hours_in_workday;
    }

    if (_i.settingshoursvalue == 'hours') {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'time entry min step percent',
        0.0,
        _i.settingsSaved
      );
    } else if (_i.settingshoursvalue == 'details') {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'time entry min step percent',
        -1.0,
        _i.settingsSaved
      );
    } else {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'time entry min step percent',
        0.5,
        _i.settingsSaved
      );
    }

    if (
      _i.mandatoryTimeCategoriesCheckbox &&
      // checkbox state has changed
      _i.mandatoryTimeCategoriesCheckboxChecked ==
        (gData.accountSettings.allow_bulk_confirm_time_entries == '1')
    ) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'allow_bulk_confirm_time_entries',
        _i.mandatoryTimeCategoriesCheckboxChecked ? 0 : 1,
        _i.settingsSaved
      );
    }

    var minhours = Number(_i.minhours.getValue().replace(/[^0-9\.]+/g, ''));
    var maxhours = Number(_i.maxhours.getValue().replace(/[^0-9\.]+/g, ''));

    if (
      !isNaN(maxhours) &&
      maxhours > 0 &&
      maxhours <= 24 &&
      minhours <= maxhours
    ) {
      maxhours = Number(maxhours.toFixed(2));
      var val = maxhours / numhours;
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'time entry max percent',
        val,
        _i.settingsSaved
      );
    }

    if (
      !isNaN(minhours) &&
      minhours >= 0 &&
      minhours <= 24 &&
      minhours <= maxhours
    ) {
      minhours = Number(minhours.toFixed(2));
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'minimum hours reported',
        minhours,
        _i.settingsSaved
      );
    }

    if (_i.locale != gData.accountSettings.locale) {
      var isTranslationsModuleEnabled =
        window.accountSettings &&
        window.accountSettings.modules_enabled.find(
          (m) => m.name === 'translations' && m.value === '1'
        );

      _i.expectedcallback++;
      gData.accountSettings.locale = _i.locale;
      if (!isTranslationsModuleEnabled) {
        I18n.locale = _i.locale;
      }
      gService.updateAccountSetting('locale', _i.locale, _i.settingsSaved);
    }

    if (
      _i.customCurrencyInput.getValue() !=
      gData.accountSettings.locale_currency_symbol
    ) {
      _i.expectedcallback++;
      var currencySymbol = _i.customCurrencyInput.getValue();
      gData.accountSettings.locale_currency_symbol = currencySymbol;
      I18n.lookup('number.currency.format').unit = currencySymbol;
      gService.updateAccountSetting(
        'locale_currency_symbol',
        currencySymbol,
        _i.settingsSaved
      );
    }

    if (_i.progressdays) {
      _i.expectedcallback++;
      gService.updateAccountSetting('show progress', 'days', _i.settingsSaved);
    } else {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'show progress',
        'amounts',
        _i.settingsSaved
      );
    }

    if (_i.allocatehours) {
      _i.expectedcallback++;
      gService.updateAccountSetting('allocate unit', 'hours', _i.settingsSaved);
    } else {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'allocate unit',
        'percentage',
        _i.settingsSaved
      );
    }
    _i.expectedcallback++;
    gService.updateAccountSetting(
      'notifications_enabled',
      _i.notificationsEnabled ? '1' : '0',
      _i.settingsSaved
    );

    _i.expectedcallback++;
    gService.updateAccountSetting(
      'calendars_enabled',
      _i.calendarsEnabled ? '1' : '0',
      _i.settingsSaved
    );

    if (_i.incurredhours_using == 'confirmed-unconfirmed') {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'calc incurred using',
        'confirmed-unconfirmed',
        _i.settingsSaved
      );
    } else if (_i.incurredhours_using == 'confirmed') {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'calc incurred using',
        'confirmed',
        _i.settingsSaved
      );
    } else {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'calc incurred using',
        'approved',
        _i.settingsSaved
      );
    }

    if (_i.incurredexpenses_using == 'approved') {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'calc incurred expenses using',
        'approved',
        _i.settingsSaved
      );
    } else {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'calc incurred expenses using',
        'confirmed',
        _i.settingsSaved
      );
    }

    if (_i.today_is_incurred == '1') {
      _i.expectedcallback++;
      gService.updateAccountSetting('today_is_incurred', '1', _i.settingsSaved);
    } else {
      _i.expectedcallback++;
      gService.updateAccountSetting('today_is_incurred', '0', _i.settingsSaved);
    }

    if (_i.includeweekends.getSelection().selected) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'use weekend_holiday hours',
        1,
        _i.settingsSaved
      );
    } else {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'use weekend_holiday hours',
        0,
        _i.settingsSaved
      );
    }

    if (
      _i.timeEntryApprovalsCheckbox &&
      _i.timeEntryApprovalsCheckboxChecked !=
        (gData.accountSettings.time_entry_approvals == '1')
    ) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'time_entry_approvals',
        _i.timeEntryApprovalsCheckboxChecked ? 1 : 0,
        function (setting) {
          // This needs to be updated immediately for the bulk approve menu.
          gData.accountSettings.time_entry_approvals = setting.value;
          _i.settingsSaved();
        }
      );
    }

    if (
      _i.expenseApprovalsCheckbox &&
      _i.expenseApprovalsCheckboxChecked !=
        (gData.accountSettings.expense_approvals == '1')
    ) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'expense_approvals',
        _i.expenseApprovalsCheckboxChecked ? 1 : 0,
        function (setting) {
          // This needs to be updated immediately for the bulk approve menu.
          gData.accountSettings.expense_approvals = setting.value;
          _i.settingsSaved();
        }
      );
    }

    if (
      _i.projectBasedApprovalsCheckbox &&
      _i.projectBasedApprovalsCheckboxChecked !=
        (gData.accountSettings.project_based_approvals == '1')
    ) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'project_based_approvals',
        _i.projectBasedApprovalsCheckboxChecked ? 1 : 0,
        function (setting) {
          // This needs to be updated immediately for the bulk approve menu.
          gData.accountSettings.project_based_approvals = setting.value;
          _i.settingsSaved();
        }
      );
    }

    if (
      _i.approvalNotificationsCheckBox &&
      _i.approvalNotificationsCheckBoxChecked !=
        (gData.accountSettings.approval_notifications_enabled == '1')
    ) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'approval_notifications_enabled',
        _i.approvalNotificationsCheckBoxChecked ? 1 : 0,
        function (setting) {
          gData.accountSettings.approval_notifications_enabled = setting.value;
          _i.settingsSaved();
        }
      );
    }

    if (
      _i.approvalUnlockCheckBox &&
      _i.approvalUnlockCheckBoxChecked !=
        (gData.accountSettings.approver_unlock_approval_enabled == '1')
    ) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'approver_unlock_approval_enabled',
        _i.approvalUnlockCheckBoxChecked ? 1 : 0,
        function (setting) {
          gData.accountSettings.approver_unlock_approval_enabled =
            setting.value;
          _i.settingsSaved();
        }
      );
    }

    if (
      _i.lockTimesheetsCheckbox &&
      _i.lockTimesheetsCheckboxChecked !=
        (gData.accountSettings.lock_entries_upon_approval == '1')
    ) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'lock_entries_upon_approval',
        _i.lockTimesheetsCheckboxChecked ? 1 : 0,
        function (setting) {
          gData.accountSettings.lock_entries_upon_approval = setting.value;
          _i.settingsSaved();
        }
      );
    }

    if (
      _i.restrictedTagsButton &&
      _i.restrictedTagsSelected !=
        (gData.accountSettings.restricted_tag_mode == '1')
    ) {
      _i.expectedcallback++;
      gService.updateAccountSetting(
        'restricted_tag_mode',
        _i.restrictedTagsSelected ? 1 : 0,
        _i.settingsSaved
      );
    }
  };

  _i.popupDataFromAdminUsers = function () {
    var ret = [];
    var users = gData.getUsers(true);
    var adminTypeId;
    var nonAdminAdjustment = 0;
    for (var i = 0; i < gData.userTypes.length; i++) {
      if (gData.userTypes[i].name == 'userlevel10')
        adminTypeId = gData.userTypes[i].id;
    }
    for (var i = 0; i < users.length; i++) {
      if (users[i].user_type_id != adminTypeId) {
        nonAdminAdjustment++;
        continue;
      }
      obj = {};
      obj.type = 'item';
      obj.id = users[i].id;
      obj.value = i - nonAdminAdjustment;
      obj.label =
        (users[i].first_name || users[i].firstName) +
        ' ' +
        (users[i].last_name || users[i].lastName);
      if (users[i].account_owner) {
        _i.accountOwnerId = users[i].id;
        _i.accountownerDataInitialValue = i - nonAdminAdjustment;
        _i.initialID = users[i].id;
        obj.selected = true;
      }
      ret.push(obj);
    }
    return ret;
  };

  _i.popupDataFromDays = function () {
    var ret = [];
    var users = gData.getUsers();
    for (var i = 0; i < 7; i++) {
      obj = {};
      obj.type = 'item';
      obj.id = i;
      obj.value = i;
      obj.label = I18n.t('lbl_day' + i);
      if (gData.accountSettings.first_working_day == i) {
        _i.workingDayFirstInitialValue = i;
        obj.selected = true;
      }
      // Only Sunday, Monday, and the selected day (if different) are visible
      if (i < 2 || obj.selected) ret.push(obj);
    }
    return ret;
  };

  _i.buildLocaleData = function () {
    var ret = [];
    for (var i = 0; i < available_locales.length; i++) {
      obj = {};
      obj.type = 'item';
      obj.id = i;
      obj.value = available_locales[i].code;
      obj.label = available_locales[i].label;
      if (_i.locale == obj.value) {
        _i.localeInitialValue = i;
        obj.selected = true;
      }
      ret.push(obj);
    }
    return ret;
  };

  _i.toggleConfirmedUnconfirmed = function () {
    if (_i.incurredhours_using != 'confirmed-unconfirmed') {
      _i.incurredhours_using = 'confirmed-unconfirmed';
      _i.settingsunconfirmed.setEnabled(true);
      _i.settingsconfirmedonly.setEnabled(false);
      _i.settingsapprovedonly.setEnabled(false);
    }
  };

  _i.toggleConfirmedOnly = function () {
    if (_i.incurredhours_using != 'confirmed') {
      _i.incurredhours_using = 'confirmed';
      _i.settingsunconfirmed.setEnabled(false);
      _i.settingsconfirmedonly.setEnabled(true);
      _i.settingsapprovedonly.setEnabled(false);
    }
  };

  _i.toggleApprovedOnly = function () {
    if (_i.incurredhours_using != 'approved') {
      _i.incurredhours_using = 'approved';
      _i.settingsunconfirmed.setEnabled(false);
      _i.settingsconfirmedonly.setEnabled(false);
      _i.settingsapprovedonly.setEnabled(true);
    }
  };

  _i.toggleTodayIsIncurred = function () {
    if (_i.today_is_incurred != '1') {
      _i.today_is_incurred = '1';
      _i.settingsPastOnlyToggle.setEnabled(false);
      _i.settingsPastAndTodayToggle.setEnabled(true);
    }
  };

  _i.toggleTodayIsNotIncurred = function () {
    if (_i.today_is_incurred != '0') {
      _i.today_is_incurred = '0';
      _i.settingsPastOnlyToggle.setEnabled(true);
      _i.settingsPastAndTodayToggle.setEnabled(false);
    }
  };

  _i.toggleAllExpenses = function () {
    if (_i.incurredexpenses_using != 'confirmed') {
      _i.incurredexpenses_using = 'confirmed';
      _i.settingsapprovedexpensesonly.setEnabled(false);
      _i.settingsallexpenses.setEnabled(true);
    }
  };

  _i.toggleApprovedExpensesOnly = function () {
    if (_i.incurredexpenses_using != 'approved') {
      _i.incurredexpenses_using = 'approved';
      _i.settingsapprovedexpensesonly.setEnabled(true);
      _i.settingsallexpenses.setEnabled(false);
    }
  };

  _i.showTimeEntryApprovalOptions = function (shouldShow) {
    if (shouldShow) {
      _i.settingsapprovedonly.inputContainer.style.display = 'block';
      _i.bulkApproveButton.container.style.display = 'block';
    } else {
      _i.settingsapprovedonly.inputContainer.style.display = 'none';
      if (!_i.expenseApprovalsCheckboxChecked && _i.bulkApproveButton) {
        _i.bulkApproveButton.container.style.display = 'none';
      }
      if (_i.incurredhours_using === 'approved') {
        _i.incurredhours_using = 'confirmed';
        _i.settingsconfirmedonly.setEnabled(true);
        _i.settingsapprovedonly.setEnabled(false);
      }
    }
  };

  _i.showExpenseApprovalOptions = function (shouldShow) {
    if (shouldShow) {
      _i.expenseApprovalOptionElements.forEach(function (el) {
        el.style.display = 'block';
      });
      _i.bulkApproveButton.container.style.display = 'block';
    } else {
      _i.expenseApprovalOptionElements.forEach(function (el) {
        el.style.display = 'none';
      });
      if (!_i.timeEntryApprovalsCheckboxChecked && _i.bulkApproveButton) {
        _i.bulkApproveButton.container.style.display = 'none';
      }
      if (_i.incurredexpenses_using === 'approved') {
        _i.incurredexpenses_using = 'confirmed';
        _i.settingsallexpenses.setEnabled(true);
        _i.settingsapprovedexpensesonly.setEnabled(false);
      }
    }
  };

  _i.toggleProgressDays = function () {
    if (!_i.progressdays) {
      _i.progressdays = true;
      _i.settingsProgressDays.setEnabled(true);
      _i.settingsProgressAmounts.setEnabled(false);
    }
  };

  _i.toggleProgressAmounts = function () {
    if (_i.progressdays) {
      _i.progressdays = false;
      _i.settingsProgressDays.setEnabled(false);
      _i.settingsProgressAmounts.setEnabled(true);
    }
  };

  _i.toggleNotifications = function (enabled) {
    if (_i.notificationsEnabled != enabled) {
      _i.notificationsEnabled = enabled;
      _i.notificationsEnabledSelection.setEnabled(enabled == true);
      _i.notificationsDisabledSelection.setEnabled(enabled == false);
    }
  };

  _i.toggleCalendars = function (enabled) {
    if (_i.calendarsEnabled != enabled) {
      _i.calendarsEnabled = enabled;
      _i.calendarsEnabledSelection.setEnabled(enabled == true);
      _i.calendarsDisabledSelection.setEnabled(enabled == false);
    }
  };

  _i.toggleAllocateHours = function () {
    if (!_i.allocatehours) {
      _i.allocatehours = true;
      _i.settingsAllocateHours.setEnabled(true);
      _i.settingsAllocatePercentage.setEnabled(false);
    }
  };

  _i.toggleAllocatePercentage = function () {
    if (_i.allocatehours) {
      _i.allocatehours = false;
      _i.settingsAllocateHours.setEnabled(false);
      _i.settingsAllocatePercentage.setEnabled(true);
    }
  };

  _i.setRequiredCategoriesCheckboxState = function () {
    _i.mandatoryTimeCategoriesCheckboxChecked = false;
    _i.mandatoryTimeCategoriesCheckbox.setEnabledWithOpacity(
      _i.mandatoryTimeCategoriesCheckboxChecked,
      _i.settingshoursvalue != 'details'
    );
  };

  _i.toggleTimeTrackHours = function () {
    if (!(_i.settingshoursvalue == 'hours')) {
      _i.settingshoursvalue = 'hours';
      _i.settingsHours.setEnabled(true);
      _i.settingsHalfDays.setEnabled(false);
      _i.settingsDetailed.setEnabled(false);
      _i.setRequiredCategoriesCheckboxState();
    }
  };

  _i.toggleTimeTrackDays = function () {
    if (!(_i.settingshoursvalue == 'halfdays')) {
      _i.settingshoursvalue = 'halfdays';
      _i.settingsHours.setEnabled(false);
      _i.settingsHalfDays.setEnabled(true);
      _i.settingsDetailed.setEnabled(false);
      _i.setRequiredCategoriesCheckboxState();
    }
  };

  _i.toggleTimeTrackDetails = function () {
    if (!(_i.settingshoursvalue == 'details')) {
      _i.settingshoursvalue = 'details';
      _i.settingsHours.setEnabled(false);
      _i.settingsHalfDays.setEnabled(false);
      _i.settingsDetailed.setEnabled(true);
      _i.setRequiredCategoriesCheckboxState();
    }
  };

  _i.setApprovalWorkflowState = function () {
    if (
      !_i.timeEntryApprovalsCheckboxChecked &&
      !_i.expenseApprovalsCheckboxChecked
    ) {
      _i.lockTimesheetsCheckboxChecked = false;
      _i.lockTimesheetsCheckbox?.setEnabledWithOpacity(
        _i.lockTimesheetsCheckboxChecked,
        true
      );
      _i.projectBasedApprovalsCheckboxChecked = false;
      _i.projectBasedApprovalsCheckbox?.setEnabledWithOpacity(
        _i.projectBasedApprovalsCheckboxChecked,
        true
      );
      _i.approvalNotificationsCheckBoxChecked = false;
      _i.approvalNotificationsCheckBox.setEnabledWithOpacity(
        _i.approvalNotificationsCheckBoxChecked,
        true
      );
      _i.approvalUnlockCheckBoxChecked = false;
      _i.approvalUnlockCheckBox.setEnabledWithOpacity(
        _i.approvalUnlockCheckBoxChecked,
        true
      );
    } else {
      _i.lockTimesheetsCheckbox?.setEnabledWithOpacity(
        _i.lockTimesheetsCheckboxChecked,
        false
      );
      _i.projectBasedApprovalsCheckbox?.setEnabledWithOpacity(
        _i.projectBasedApprovalsCheckboxChecked,
        false
      );
      _i.approvalNotificationsCheckBox.setEnabledWithOpacity(
        _i.approvalNotificationsCheckBoxChecked,
        false
      );
      _i.approvalUnlockCheckBox.setEnabledWithOpacity(
        _i.approvalUnlockCheckBoxChecked,
        false
      );
    }
  };

  _i.onlocaleChanged = function (obj) {
    if (obj && obj[0].id != null) {
      _i.locale = obj[0].value;
      _i.locales.setValue(obj[0].id);
      focusElementAfterDelay('#' + _i.localesBtnID);
    }

    var translation = I18n.translations[_i.locale || 'en'];

    if (obj && obj[0].id != null) {
      _i.customCurrencyInput.setValue(translation.number.currency.format.unit);
    }

    var customCurrency = _i.customCurrencyInput.getValue();
    var currencyOptions = _.clone(translation.number.currency.format);

    if (customCurrency) currencyOptions.unit = customCurrency;

    _i.currencylabel.innerHTML =
      I18n.t('lbl_currency_colon') +
      ' ' +
      formatCurrency(25256.0, currencyOptions);
  };

  _i.onfirstworkdayChanged = function (obj) {
    if (obj[0].id != null) {
      _i.firstWorkingDaySetting = obj[0].id;
      _i.firstWorkingDaySettingChanged = true;
      _i.firstworkday.setValue(obj[0].value);
      focusElementAfterDelay('#' + _i.workdayBtnID);
    }
  };

  _i.onaccountownerChanged = function (obj) {
    if (obj[0].id != null) {
      _i.accountOwnerId = obj[0].id;
      _i.accountowner.setValue(obj[0].value);
      focusElementAfterDelay('#' + _i.accountBtnID);
    }
  };

  _i.buildPlainTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-14 settingsSpan';
    ret.innerText = str;
    return ret;
  };

  _i.renderSmartsheetIntegration = function (containerElement) {
    _i.labelSmartsheet = document.createElement('H3');
    _i.labelSmartsheet.className =
      'blockFloatNot fnt-b-14 settingsTimeTrackingLabel smartsheetIntegration';
    _i.labelSmartsheet.innerHTML = I18n.t('lbl_smartsheet_integration');
    containerElement.appendChild(_i.labelSmartsheet);

    _i.labelSmarDescription = document.createElement('DIV');
    _i.labelSmarDescription.className =
      'blockFloatNot fnt-r-12 settingsIncrementLabel';
    _i.labelSmarDescription.innerHTML =
      I18n.t('msg_smartsheet_integration_unconnected_account') +
      ' ' +
      '<a href="https://help.smartsheet.com/articles/2482610-use-smartsheet-authentication-resource-management">' +
      I18n.t('lbl_learn_more') +
      '</a>';

    containerElement.appendChild(_i.labelSmarDescription);
    _i.smarButtonContainer = document.createElement('DIV');
    _i.smarButtonContainer.className = 'smartsheetIntegration';
    containerElement.appendChild(_i.smarButtonContainer);

    var renderButton = function () {
      Promise.all([
        gService.getSmartsheetOrg(),
        gService.getSsoSettingsPromise(),
      ]).then(function (results) {
        var smarOrg = results[0];
        var ssoSettings = results[1];
        var smarSsoSetting = _.find(ssoSettings, function (ssoSetting) {
          return ssoSetting.sso_type === 'smar';
        });
        var isIntegrationDisabled = smarSsoSetting
          ? smarSsoSetting.status === 'enabled'
          : false;

        _i.smarButtonContainer.innerHTML = '';
        if (smarOrg.smartsheet_account_id) {
          if (isIntegrationDisabled) {
            _i.labelSmarDescription.innerHTML =
              I18n.t('msg_smartsheet_integration_connected_account') +
              ' ' +
              '<a href="https://help.smartsheet.com/articles/2482610-use-smartsheet-authentication-resource-management#toc-user-auto-provisioning-uap-for-resource-management">' +
              I18n.t('lbl_learn_more') +
              '</a>';
          }

          _i.labelSmarLinkedAccount = document.createElement('DIV');
          _i.labelSmarLinkedAccount.className =
            'blockFloatNot fnt-r-14 settingsIncrementLabel';
          _i.labelSmarLinkedAccount.style.marginBottom = '5px';
          _i.labelSmarLinkedAccount.innerHTML = I18n.t('lbl_linked_account');
          _i.smarButtonContainer.appendChild(_i.labelSmarLinkedAccount);

          _i.labelSmarDescription = document.createElement('DIV');
          _i.labelSmarDescription.className = 'blockFloatNot fnt-r-14';
          _i.labelSmarDescription.textContent = smarOrg.smartsheet_account_name;
          _i.smarButtonContainer.appendChild(_i.labelSmarDescription);

          if (!isIntegrationDisabled) {
            _i.smarDisconnectButton = new secondaryButton(
              'blockFloatNot settingsSecondaryButton smarConnectionBtn'
            );
            _i.smarDisconnectButton.setButtonText(
              I18n.t('lbl_disconnect_smartsheet')
            );
            _i.smarDisconnectButton.setCallback(function () {
              var confirmDelete = confirmationPopup(
                I18n.t('msg_disconnect_smartsheet'),
                I18n.t('lbl_confirm_disconnect'),
                function () {
                  gService.disconnectSmartsheetOrg().then(renderButton);
                  window.clientEventLogger &&
                    window.clientEventLogger.push({
                      eventTimestamp: Date.now(),
                      eventData: {
                        featureArea: 'AccountSettings',
                        eventName: 'ButtonClick',
                        elementLocation: 'SmartsheetIntegration',
                        elementName: 'DisconnectRMSmartsheet',
                      },
                    });
                },
                null,
                containerElement
              );
              confirmDelete.show(_i.smarDisconnectButton.container);
            });
            _i.smarDisconnectButton.container.tabIndex = 0;
            _i.smarButtonContainer.appendChild(
              _i.smarDisconnectButton.container
            );
          }
        } else if (smarOrg.auth_request_url) {
          var auth_request_url = smarOrg.auth_request_url;
          // NOTE issue with the Smartsheet Ruby SDK results in a bad auth_request_url in EU env
          // context here https://smartsheet.slack.com/archives/C5T888SPP/p1652376796824749
          if (window.location.hostname.includes('.eu')) {
            auth_request_url = auth_request_url.replace('.com', '.eu');
          }
          _i.connectButton = new primaryButton(
            'settingsPrimaryButton smarConnectionBtn'
          );
          _i.connectButton.setButtonText(
            I18n.t('lbl_activate_smartsheet_authentication')
          );
          _i.connectButton.setCallback(function () {
            var urlLooksValid =
              auth_request_url.includes('app.smartsheet') &&
              auth_request_url.includes('/b/authorize');
            if (!PRODUCTION_BUILD || urlLooksValid)
              window.location = auth_request_url;
            window.clientEventLogger &&
              window.clientEventLogger.push({
                eventTimestamp: Date.now(),
                eventData: {
                  featureArea: 'AccountSettings',
                  eventName: 'ButtonClick',
                  elementLocation: 'SmartsheetIntegration',
                  elementName: 'ConnectRMSmartsheet',
                },
              });
          });
          _i.connectButton.container.style.margin = '0px';
          _i.connectButton.container.tabIndex = 0;
          _i.smarButtonContainer.appendChild(_i.connectButton.container);
        }
      });
    };
    renderButton();
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);
    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer3';
    _i.container.appendChild(spacer);

    _i.label = document.createElement('H3');
    _i.label.className = 'blockFloatNot fnt-b-14 settingsMyPrefLoginLabel';
    _i.label.innerHTML = I18n.t('lbl_general');
    _i.container.appendChild(_i.label);

    _i.companynamevalue = _.unescape(gData.accountSettings.company_name);

    _i.companyname = new TextInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_organization_name'),
      _i.companynamevalue,
      'blockFloatNot settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall'
    );

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    _i.accountownerData = _i.popupDataFromAdminUsers();
    var height =
      32 *
      (_i.accountownerData.length > _i.max_shown
        ? _i.max_shown
        : _i.accountownerData.length);
    _i.accountownerPopup = new selectionPopup(
      190,
      height,
      'mainCon',
      _i.accountownerData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onaccountownerChanged, // callback
      false,
      null /*blurCallback*/,
      'nw',
      null /*prefferedDirections*/,
      true /*noautorotate*/,
      null,
      null,
      null,
      null,
      null,
      _i.accountDropdownID
    );
    _i.accountownerPopup.isShown = false;
    _i.accountowner = new PopUpInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_account_owner'),
      _i.accountownerDataInitialValue,
      'blockFloat settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-14 settingsInputContainer',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsTextInput',
      _i.accountownerData,
      _i.accountownerPopup,
      null,
      _i.accountDropdownID,
      _i.accountBtnID
    );

    _i.labelBillingEmailHeader = document.createElement('DIV');
    _i.labelBillingEmailHeader.className =
      'blockFloatNot fnt-r-12 settingsBillingEmailDescriptionLabel';
    _i.labelBillingEmailHeader.id = 'settingsBillingEmailDescriptionExtended';
    _i.labelBillingEmailHeader.innerHTML = I18n.t('msg_billing_contact');
    _i.container.appendChild(_i.labelBillingEmailHeader);

    var currentBillingEmail = gData.accountSettings.billing_email
      ? gData.accountSettings.billing_email
      : '';
    _i.billingemail = new TextInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_invoice_email_optional'),
      currentBillingEmail,
      'blockFloatNot settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      { ariaDescribedby: 'settingsBillingEmailDescriptionExtended' }
    );

    var currentBillingAddress = gData.accountSettings.billing_address
      ? gData.accountSettings.billing_address
      : '';
    _i.billingAddress = new TextArea(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_invoice_address_optional'),
      currentBillingAddress,
      'blockFloatNot billingAddressContainer',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 billingAddressInput'
    );

    if (
      gData.accountSettings.moduleEnabled('smartsheet integration') &&
      !gData.accountSettings.moduleEnabled('is_core_rm_org')
    ) {
      _i.renderSmartsheetIntegration(_i.container);
    }

    _i.label2 = document.createElement('H3');
    _i.label2.className = 'blockFloatNot fnt-b-14 settingsTimeTrackingLabel';
    _i.label2.innerHTML = I18n.t('lbl_time_tracking');
    _i.container.appendChild(_i.label2);

    _i.label3 = document.createElement('DIV');
    _i.label3.className = 'blockFloatNot fnt-r-12 settingsIncrementLabel';
    _i.label3.innerHTML = I18n.t('lbl_increments_time_tracking');
    _i.container.appendChild(_i.label3);

    _i.settingshoursvalue = 'hours';
    if (gData.accountSettings.time_entry_min_step_percent == 0.5)
      _i.settingshoursvalue = 'halfdays';
    else if (gData.accountSettings.time_entry_min_step_percent == -1)
      _i.settingshoursvalue = 'details';

    _i.settingsHours = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_hours_and_minutes') + ' '),
      _i.settingshoursvalue == 'hours',
      'blockFloatNot settingsSelectorItem',
      'fnt-r-15 settingsSelectorText ',
      'settingsSelectorIcon'
    );
    _i.settingsHours.setCallback(_i.toggleTimeTrackHours);
    _i.settingsHalfDays = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_half_days') + ' '),
      _i.settingshoursvalue == 'halfdays',
      'blockFloatNot settingsSelectorItem',
      'fnt-r-15 settingsSelectorText ',
      'settingsSelectorIcon'
    );
    _i.settingsHalfDays.setCallback(_i.toggleTimeTrackDays);
    _i.settingsDetailed = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('msg_itemized_hours_minutes') + ' '),
      _i.settingshoursvalue == 'details',
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide ',
      'settingsSelectorIcon'
    );
    _i.settingsDetailed.setCallback(_i.toggleTimeTrackDetails);

    labelSpan_mandatoryTimeCategories = document.createElement('span');
    labelSpan_mandatoryTimeCategories.innerHTML = I18n.t(
      'msg_require_catgeory_html'
    );
    _i.mandatoryTimeCategoriesCheckboxChecked =
      gData.accountSettings.allow_bulk_confirm_time_entries === '0';
    _i.mandatoryTimeCategoriesCheckboxEnabled =
      _i.settingshoursvalue == 'details';
    _i.mandatoryTimeCategoriesCheckbox = new SelectionCheckbox(
      _i.container,
      labelSpan_mandatoryTimeCategories,
      _i.mandatoryTimeCategoriesCheckboxChecked,
      'blockFloatNot settingsPageCheckboxContainer',
      'fnt-r-15 settingsPrefName',
      'fnt-r-15 settingsPrefCheckbox settingsPrefTabbedCheckbox',
      _i.mandatoryTimeCategoriesCheckboxEnabled
    );
    _i.mandatoryTimeCategoriesCheckbox.setCallback(function () {
      if (_i.settingshoursvalue != 'details') return;
      _i.mandatoryTimeCategoriesCheckboxChecked =
        !_i.mandatoryTimeCategoriesCheckboxChecked;
      _i.mandatoryTimeCategoriesCheckbox.setEnabledWithOpacity(
        _i.mandatoryTimeCategoriesCheckboxChecked,
        _i.settingshoursvalue != 'details'
      );
    });

    _i.minhours = new TextInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_minimum_allowable_hours_day'),
      gData.accountSettings.min_hours_report,
      'blockFloatNot settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageInputSmall'
    );
    spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    _i.maxhours = new TextInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_maximum_allowable_hours_day'),
      gData.accountSettings.time_entry_max_percent *
        gData.accountSettings.hours_in_workday,
      'blockFloat settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageInputSmall'
    );

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacer2';
    _i.container.appendChild(spacer);

    _i.numhours = new TextInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_work_hours_day'),
      gData.accountSettings.hours_in_workday,
      'blockFloatNot settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageInputSmall'
    );
    var numdays = 0;
    var mask = gData.accountSettings.working_days;
    var labelSpan;

    while (mask != 0) {
      if (mask & 1) {
        numdays++;
      }
      mask = mask >> 1;
    }

    _i.workdays = new TextInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_work_days_week'),
      numdays,
      'blockFloatNot settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall'
    );

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    _i.firstWorkdayData = _i.popupDataFromDays();
    var height = 32 * _i.firstWorkdayData.length;
    _i.firstWorkdayPopup = new selectionPopup(
      190,
      height,
      'mainCon',
      _i.firstWorkdayData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onfirstworkdayChanged,
      false,
      null /*blurCallback*/,
      'nw',
      null /*prefferedDirections*/,
      true /*noautorotate*/,
      null,
      null,
      null,
      null,
      null,
      _i.workdayDropdownID
    );
    _i.firstWorkdayPopup.isShown = false;
    _i.firstworkday = new PopUpInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('lbl_start_of_week'),
      _i.workingDayFirstInitialValue,
      'blockFloat settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-14 settingsInputContainer',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsTextInput',
      _i.firstWorkdayData,
      _i.firstWorkdayPopup,
      null,
      _i.workdayDropdownID,
      _i.workdayBtnID
    );

    if (
      gData.accountSettings.moduleEnabled('time approvals') ||
      gData.accountSettings.moduleEnabled('expense approvals')
    ) {
      _i.label21 = document.createElement('H3');
      _i.label21.className = 'blockFloatNot fnt-b-14 settingsTimeTrackingLabel';
      _i.label21.innerHTML = I18n.t('lbl_approval_workflow');
      _i.container.appendChild(_i.label21);

      _i.label22 = document.createElement('DIV');
      _i.label22.className = 'blockFloatNot fnt-r-12 settingsIncrementLabel';
      _i.label22.innerHTML = I18n.t('lbl_enable_submit_approval_workflow');
      _i.container.appendChild(_i.label22);

      if (gData.accountSettings.moduleEnabled('time approvals')) {
        labelSpan = document.createElement('span');
        labelSpan.innerHTML = I18n.t('lbl_for_time_entries');
        _i.timeEntryApprovalsCheckboxChecked =
          gData.accountSettings.time_entry_approvals == '1';
        _i.timeEntryApprovalsCheckbox = new SelectionCheckbox(
          _i.container,
          labelSpan,
          _i.timeEntryApprovalsCheckboxChecked,
          'blockFloatNot settingsPageCheckboxContainer',
          'fnt-r-14 settingsPrefTag',
          'fnt-r-14 settingsPrefCheckbox'
        );
        _i.timeEntryApprovalsCheckbox.setCallback(function () {
          _i.timeEntryApprovalsCheckboxChecked =
            !_i.timeEntryApprovalsCheckboxChecked;
          _i.timeEntryApprovalsCheckbox.setEnabled(
            _i.timeEntryApprovalsCheckboxChecked
          );
          // if time+expense entries is enabled, enable lock timesheets checkbox
          if (
            _i.timeEntryApprovalsCheckboxChecked &&
            _i.expenseApprovalsCheckboxChecked &&
            !_i.lockTimesheetsCheckboxChecked
          ) {
            _i.lockTimesheetsCheckboxChecked =
              !_i.lockTimesheetsCheckboxChecked;
            _i.lockTimesheetsCheckbox.setEnabled(
              _i.lockTimesheetsCheckboxChecked
            );
          }
          _i.showTimeEntryApprovalOptions(_i.timeEntryApprovalsCheckboxChecked);
          _i.setApprovalWorkflowState();
        });
      }

      if (gData.accountSettings.moduleEnabled('expense approvals')) {
        labelSpan = document.createElement('span');
        labelSpan.innerHTML = I18n.t('lbl_for_expenses');
        _i.expenseApprovalsCheckboxChecked =
          gData.accountSettings.expense_approvals == '1';
        _i.expenseApprovalsCheckbox = new SelectionCheckbox(
          _i.container,
          labelSpan,
          _i.expenseApprovalsCheckboxChecked,
          'blockFloatNot settingsPageCheckboxContainer',
          'fnt-r-14 settingsPrefTag',
          'fnt-r-14 settingsPrefCheckbox'
        );
        _i.expenseApprovalsCheckbox.setCallback(function () {
          _i.expenseApprovalsCheckboxChecked =
            !_i.expenseApprovalsCheckboxChecked;
          _i.expenseApprovalsCheckbox.setEnabled(
            _i.expenseApprovalsCheckboxChecked
          );
          // if time+expense entries is enabled, enable lock timesheets checkbox
          if (
            _i.timeEntryApprovalsCheckboxChecked &&
            _i.expenseApprovalsCheckboxChecked &&
            !_i.lockTimesheetsCheckboxChecked
          ) {
            _i.lockTimesheetsCheckboxChecked =
              !_i.lockTimesheetsCheckboxChecked;
            _i.lockTimesheetsCheckbox.setEnabled(
              _i.lockTimesheetsCheckboxChecked
            );
          }
          _i.showExpenseApprovalOptions(_i.expenseApprovalsCheckboxChecked);
          _i.setApprovalWorkflowState();
        });
      }

      _i.spacer = document.createElement('DIV');
      _i.spacer.style.height = '15px';
      _i.spacer.className = 'blockFloatNot';
      _i.container.appendChild(_i.spacer);

      expense_or_time_enabled =
        gData.accountSettings.time_entry_approvals == '1' ||
        gData.accountSettings.expense_approvals == '1';

      if (gData.accountSettings.moduleEnabled('lock entries upon approval')) {
        const labelContainer = document.createElement('div');
        labelContainer.style.display = 'flex';
        labelContainer.style.alignItems = 'center';
        const labelSpan = document.createElement('span');
        labelSpan.innerHTML = I18n.t('lbl_lock_timesheets');
        // Create the info icon
        const infoIcon = createInfoIcon();
        // Append the label and the icon to the container
        labelContainer.appendChild(labelSpan);
        labelContainer.appendChild(infoIcon);
        _i.lockTimesheetsCheckboxChecked =
          gData.accountSettings.lock_entries_upon_approval == '1';
        _i.lockTimesheetsCheckbox = new SelectionCheckbox(
          _i.container,
          labelContainer,
          _i.lockTimesheetsCheckboxChecked,
          'blockFloatNot settingsPageCheckboxContainer',
          'fnt-r-14 settingsPrefTag',
          'fnt-r-14 settingsPrefCheckbox',
          expense_or_time_enabled
        );
        _i.newButton = new newFeatureButton('settingsNewFeatureButton');
        _i.newButton.setButtonText(I18n.t('lbl_new'));
        _i.container.appendChild(_i.newButton.container);
        _i.lockTimesheetsCheckbox.setCallback(function () {
          if (
            _i.timeEntryApprovalsCheckboxChecked ||
            _i.expenseApprovalsCheckboxChecked
          ) {
            _i.lockTimesheetsCheckboxChecked =
              !_i.lockTimesheetsCheckboxChecked;
            _i.lockTimesheetsCheckbox.setEnabled(
              _i.lockTimesheetsCheckboxChecked
            );
            if (_i.lockTimesheetsCheckboxChecked) {
              window.clientEventLogger &&
                window.clientEventLogger.push({
                  eventTimestamp: Date.now(),
                  eventData: {
                    featureArea: 'AccountSettings',
                    elementLocation: 'ApprovalWorkflow',
                    elementName: 'LockSheetsOnApproval',
                    eventName: 'CheckboxSet',
                  },
                });
            }
            if (!_i.lockTimesheetsCheckboxChecked) {
              deletionModal({
                onCancel: function () {
                  // Revert the checkbox state if cancel is selected
                  _i.lockTimesheetsCheckboxChecked = true;
                  _i.lockTimesheetsCheckbox.setEnabled(true);
                  window.clientEventLogger &&
                    window.clientEventLogger.push({
                      eventTimestamp: Date.now(),
                      eventData: {
                        featureArea: 'AccountSettings',
                        elementLocation: 'ApprovalWorkflow',
                        elementName: 'DisableModalConfirm',
                        eventName: 'ButtonClick',
                      },
                    });
                },
              });
              window.clientEventLogger &&
                window.clientEventLogger.push({
                  eventTimestamp: Date.now(),
                  eventData: {
                    featureArea: 'AccountSettings',
                    elementLocation: 'ApprovalWorkflow',
                    elementName: 'LockSheetsOnApproval',
                    eventName: 'CheckboxUnset',
                  },
                });
            }
          }
        });
      }

      if (gData.accountSettings.moduleEnabled('pb approvals')) {
        labelSpan = document.createElement('span');
        labelSpan.innerHTML = I18n.t('lbl_project_based_approvals');
        _i.projectBasedApprovalsCheckboxChecked =
          gData.accountSettings.project_based_approvals == '1';
        _i.projectBasedApprovalsCheckbox = new SelectionCheckbox(
          _i.container,
          labelSpan,
          _i.projectBasedApprovalsCheckboxChecked,
          'blockFloatNot settingsPageCheckboxContainer',
          'fnt-r-14 settingsPrefTag',
          'fnt-r-14 settingsPrefCheckbox',
          expense_or_time_enabled
        );
        _i.projectBasedApprovalsCheckbox.setCallback(function () {
          if (
            _i.timeEntryApprovalsCheckboxChecked ||
            _i.expenseApprovalsCheckboxChecked
          ) {
            _i.projectBasedApprovalsCheckboxChecked =
              !_i.projectBasedApprovalsCheckboxChecked;
            _i.projectBasedApprovalsCheckbox.setEnabled(
              _i.projectBasedApprovalsCheckboxChecked
            );
          }
        });
      }

      if (gData.accountSettings.moduleEnabled('relationship-based approvals')) {
        // Approval notification email
        labelSpan = document.createElement('span');
        labelSpan.innerHTML = I18n.t('lbl_approval_notification');
        _i.approvalNotificationsCheckBoxChecked =
          gData.accountSettings.approval_notifications_enabled == '1';
        _i.approvalNotificationsCheckBox = new SelectionCheckbox(
          _i.container,
          labelSpan,
          _i.approvalNotificationsCheckBoxChecked,
          'blockFloatNot settingsPageCheckboxContainer',
          'fnt-r-14 settingsPrefTag',
          'fnt-r-14 settingsPrefCheckbox',
          expense_or_time_enabled
        );
        _i.approvalNotificationsCheckBox.setCallback(function () {
          if (
            _i.timeEntryApprovalsCheckboxChecked ||
            _i.expenseApprovalsCheckboxChecked
          ) {
            _i.approvalNotificationsCheckBoxChecked =
              !_i.approvalNotificationsCheckBoxChecked;
            _i.approvalNotificationsCheckBox.setEnabled(
              _i.approvalNotificationsCheckBoxChecked
            );
          }
        });

        // Approver unlock
        unlockLabelSpan = document.createElement('span');
        unlockLabelSpan.innerHTML = I18n.t('lbl_enable_approval_unlock');
        _i.approvalUnlockCheckBoxChecked =
          gData.accountSettings.approver_unlock_approval_enabled == '1';
        _i.approvalUnlockCheckBox = new SelectionCheckbox(
          _i.container,
          unlockLabelSpan,
          _i.approvalUnlockCheckBoxChecked,
          'blockFloatNot settingsPageCheckboxContainer',
          'fnt-r-14 settingsPrefTag',
          'fnt-r-14 settingsPrefCheckbox',
          expense_or_time_enabled
        );
        _i.approvalUnlockCheckBox.setCallback(function () {
          if (
            _i.timeEntryApprovalsCheckboxChecked ||
            _i.expenseApprovalsCheckboxChecked
          ) {
            _i.approvalUnlockCheckBoxChecked =
              !_i.approvalUnlockCheckBoxChecked;
            _i.approvalUnlockCheckBox.setEnabled(
              _i.approvalUnlockCheckBoxChecked
            );
          }
        });
      }

      _i.bulkApproveButton = new secondaryButton('settingsSecondaryButton');
      _i.bulkApproveButton.setButtonText(I18n.t('lbl_bulk_approve'));
      _i.bulkApproveButton.setCallback(function () {
        var thereAreUnsavedChanges = true; // TODO
        if (thereAreUnsavedChanges) {
          var cp = new confirmationPopup(
            I18n.t('msg_save_changes_instruction'),
            I18n.t('lbl_save_and_continue'),
            function () {
              _i.settingsSavedCallback = function () {
                gPage.menuItemCallback('bulk approve');
                _i.settingsSavedCallback = null;
              };
              _i.saveSettings();
            },
            null,
            _i.container,
            null,
            null,
            240 * window.rm.l10n.getWidthMultiplier()
          );

          cp.show(_i.bulkApproveButton.container);
        } else {
          // This is hacky.
          // TODO: trigger an event instead that gPage responds to.
          gPage.menuItemCallback('bulk approve');
        }
      });
      var lineBreak = $('<div></div>').css({
        width: '100%',
        float: 'left',
      })[0];
      _i.container.appendChild(lineBreak);
      _i.container.appendChild(_i.bulkApproveButton.container);

      _i.auditLogsButton = new secondaryButton('settingsSecondaryButton');
      _i.auditLogsButton.setButtonText(I18n.t('lbl_access_audit_logs'));
      _i.auditLogsButton.setCallback(function () {
        if (window.isIframeView) {
          window.location.href = window.APP_ENDPOINT + '/approvalaudit';
        } else {
          window.open(window.APP_ENDPOINT + '/approvalaudit');
        }
      });
      _i.container.appendChild(_i.auditLogsButton.container);
    }

    _i.label4 = document.createElement('H3');
    _i.label4.className = 'blockFloatNot fnt-b-14 settingsProgressLabel';
    _i.label4.innerHTML = I18n.t('lbl_progress');
    _i.container.appendChild(_i.label4);

    _i.label5 = document.createElement('DIV');
    _i.label5.className = 'blockFloatNot fnt-r-12 settingsDesc1Label';
    _i.label5.innerHTML = I18n.t('msg_charts_on_reports_and_project');
    _i.container.appendChild(_i.label5);

    _i.progressdays = gData.accountSettings.showprogress == 'days';
    _i.settingsProgressDays = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_project_progress_days') + ' '),
      _i.progressdays,
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsProgressDays.setCallback(_i.toggleProgressDays);
    _i.settingsProgressAmounts = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_project_progress_currency') + ' '),
      !_i.progressdays,
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsProgressAmounts.setCallback(_i.toggleProgressAmounts);

    _i.label8 = document.createElement('H3');
    _i.label8.className = 'blockFloatNot fnt-b-14 settingsProgressLabel';
    _i.label8.innerHTML = I18n.t('lbl_availability_and_allocation');
    _i.container.appendChild(_i.label8);

    _i.label9 = document.createElement('DIV');
    _i.label9.className = 'blockFloatNot fnt-r-12 settingsDesc1Label';
    _i.label9.innerHTML = I18n.t('msg_availability_assignment_allocation');
    _i.container.appendChild(_i.label9);
    _i.allocatehours = false;

    _i.allocatehours = gData.accountSettings.allocateunit == 'hours';
    _i.settingsAllocatePercentage = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(
        I18n.t('lbl_show_percentages_with_symbol_lc') + ' '
      ),
      !_i.allocatehours,
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsAllocatePercentage.setCallback(_i.toggleAllocatePercentage);
    _i.settingsAllocateHours = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_show_hours_per_day') + ' '),
      _i.allocatehours,
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsAllocateHours.setCallback(_i.toggleAllocateHours);

    _i.label6 = document.createElement('H3');
    _i.label6.className = 'blockFloatNot fnt-b-14 settingsProgressLabel';
    _i.label6.innerHTML = I18n.t('lbl_incurred_hours_amounts');
    _i.container.appendChild(_i.label6);

    _i.label7 = document.createElement('DIV');
    _i.label7.className = 'blockFloatNot fnt-r-12 settingsDesc1Label';
    _i.label7.innerHTML = I18n.t('msg_calculate_incurred_hours_amounts');
    _i.container.appendChild(_i.label7);

    _i.incurredhours_using = gData.accountSettings.incurredhours_using;

    _i.settingsunconfirmed = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_confirmed_unconfirmed_hours') + ' '),
      _i.incurredhours_using == 'confirmed-unconfirmed',
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsunconfirmed.setCallback(_i.toggleConfirmedUnconfirmed);
    _i.settingsconfirmedonly = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_confirmed_hours_only') + ' '),
      _i.incurredhours_using == 'confirmed',
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsconfirmedonly.setCallback(_i.toggleConfirmedOnly);
    _i.settingsapprovedonly = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_approved_hours_only') + ' '),
      _i.incurredhours_using == 'approved',
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsapprovedonly.setCallback(_i.toggleApprovedOnly);

    _i.showTimeEntryApprovalOptions(
      gData.accountSettings.time_entry_approvals == '1'
    );

    _i.label_IncurredPeriod = document.createElement('H3');
    _i.label_IncurredPeriod.className =
      'blockFloatNot fnt-b-14 settingsProgressLabel';
    _i.label_IncurredPeriod.innerHTML = I18n.t('lbl_incurred_period');
    _i.container.appendChild(_i.label_IncurredPeriod);

    _i.label_IncurredPeriodDescription = document.createElement('DIV');
    _i.label_IncurredPeriodDescription.className =
      'blockFloatNot fnt-r-12 settingsDesc1Label';
    _i.label_IncurredPeriodDescription.innerHTML = I18n.t(
      'msg_incurred_period'
    );
    _i.container.appendChild(_i.label_IncurredPeriodDescription);

    _i.today_is_incurred = gData.accountSettings.today_is_incurred;

    _i.settingsPastOnlyToggle = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_past_days_only') + ' '),
      _i.today_is_incurred !== '1',
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsPastOnlyToggle.setCallback(_i.toggleTodayIsNotIncurred);
    _i.settingsPastAndTodayToggle = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_past_days_and_today') + ' '),
      _i.today_is_incurred === '1',
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsPastAndTodayToggle.setCallback(_i.toggleTodayIsIncurred);

    _i.label6point1 = document.createElement('H3');
    _i.label6point1.className = 'blockFloatNot fnt-b-14 settingsProgressLabel';
    _i.label6point1.innerHTML = I18n.t('lbl_incurred_expenses');
    _i.container.appendChild(_i.label6point1);

    _i.label6point2 = document.createElement('DIV');
    _i.label6point2.className = 'blockFloatNot fnt-r-12 settingsDesc1Label';
    _i.label6point2.innerHTML = I18n.t('msg_calculate_incurred_expenses');
    _i.container.appendChild(_i.label6point2);

    _i.incurredexpenses_using = gData.accountSettings.incurredexpenses_using;

    _i.settingsallexpenses = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_all_expenses') + ' '),
      _i.incurredexpenses_using != 'approved',
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsallexpenses.setCallback(_i.toggleAllExpenses);

    _i.settingsapprovedexpensesonly = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_approved_expenses_only') + ' '),
      _i.incurredexpenses_using == 'approved',
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.settingsapprovedexpensesonly.setCallback(_i.toggleApprovedExpensesOnly);

    _i.expenseApprovalOptionElements = [
      _i.label6point1,
      _i.label6point2,
      _i.settingsallexpenses.inputContainer,
      _i.settingsapprovedexpensesonly.inputContainer,
    ];

    _i.showExpenseApprovalOptions(
      gData.accountSettings.expense_approvals == '1'
    );

    _i.includeweekends = new checkbox(
      I18n.t('lbl_include_weekends_and_holidays'),
      'settingscheckboxText',
      'settingscheckboxBox'
    );
    if (
      gData.accountSettings.weekend_hours &&
      gData.accountSettings.weekend_hours == 1
    )
      _i.includeweekends.setSelection(true);
    else _i.includeweekends.setSelection(false);

    /* restricted tags mode UI disabled
      _i.label6point3 = document.createElement("H3");
      _i.label6point3.className = "blockFloatNot fnt-b-14 settingsProgressLabel";
      _i.label6point3.innerHTML = I18n.t('lbl_tags');
      _i.container.appendChild(_i.label6point3);

      _i.label6point4 = document.createElement("DIV");
      _i.label6point4.className = "blockFloatNot fnt-r-12 settingsDesc1Label";
      _i.label6point4.innerHTML = I18n.t('msg_tags_description');
      _i.container.appendChild(_i.label6point4);

      _i.restrictedTagsSelected = +gData.accountSettings.restricted_tag_mode;
      _i.unrestrictedTagsButton = new SelectionToggle( _i.container,
                                              _i.buildPlainTextSpan("Anyone ") ,
                                              !_i.restrictedTagsSelected,
                                              "blockFloatNot settingsSelectorItemWide",
                                              "fnt-r-15 settingsSelectorTextWide",
                                              "settingsSelectorIcon" );

      _i.restrictedTagsButton = new SelectionToggle( _i.container,
                                              _i.buildPlainTextSpan("Only administrators ") ,
                                              _i.restrictedTagsSelected,
                                              "blockFloatNot settingsSelectorItemWide",
                                              "fnt-r-15 settingsSelectorTextWide",
                                              "settingsSelectorIcon" );

      _i.restrictedTagsButton.setCallback(function() {
        if (!_i.restrictedTagsSelected)
        {
          _i.restrictedTagsSelected = true;
          _i.restrictedTagsButton.setEnabled(true);
          _i.unrestrictedTagsButton.setEnabled(false);
        }
      });

      _i.unrestrictedTagsButton.setCallback(function() {
        if (_i.restrictedTagsSelected)
        {
          _i.restrictedTagsSelected = false;
          _i.restrictedTagsButton.setEnabled(false);
          _i.unrestrictedTagsButton.setEnabled(true);
        }
      });
    */

    //_i.container.appendChild(_i.includeweekends.container);
    _i.label7 = document.createElement('H3');
    _i.label7.className = 'blockFloatNot fnt-b-14 settingsProgressLabel';
    _i.label7.innerHTML = I18n.t('lbl_currency_format');
    _i.container.appendChild(_i.label7);

    _i.locale = gData.accountSettings.locale;
    if (!_i.locale) _i.locale = 'en';
    _i.localeData = _i.buildLocaleData();
    var height =
      32 *
      (_i.localeData.length > _i.max_shown
        ? _i.max_shown
        : _i.localeData.length);
    _i.localesPopup = new selectionPopup(
      190,
      height,
      'mainCon',
      _i.localeData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onlocaleChanged,
      false,
      null /*blurCallback*/,
      'wc',
      null /*prefferedDirections*/,
      true /*noautorotate*/,
      null,
      null,
      null,
      null,
      null,
      _i.localesDropdownID
    );
    _i.localesPopup.isShown = false;
    _i.locales = new PopUpInput(
      'settingsMyPreferencesContentContainer',
      I18n.t('msg_use_format_for'),
      _i.localeInitialValue,
      'blockFloatNot settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-14 settingsInputContainer',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsTextInput',
      _i.localeData,
      _i.localesPopup,
      null,
      _i.localesDropdownID,
      _i.localesBtnID
    );

    spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.container.appendChild(spacer);

    _i.customCurrencyInput = new TextInput(
      'settingsMyPreferencesContentContainer',
      'Currency Unit',
      gData.accountSettings.locale_currency_symbol || '',
      'blockFloat settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputSmall',
      null,
      null,
      null,
      null,
      function () {
        _i.onlocaleChanged();
      }
    );

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage3';
    _i.container.appendChild(spacer);
    _i.currencylabel = document.createElement('DIV');
    _i.currencylabel.className =
      'blockFloatNot fnt-r-14 settingsPageTextInputLabel';
    _i.currencylabel.innerHTML =
      I18n.t('lbl_currency_colon') + ' ' + formatCurrency(25256.0);
    _i.container.appendChild(_i.currencylabel);

    _i.notificationsTitle = document.createElement('H3');
    _i.notificationsTitle.className =
      'blockFloatNot fnt-b-14 settingsProgressLabel';
    _i.notificationsTitle.innerHTML = I18n.t('lbl_notifications');
    _i.container.appendChild(_i.notificationsTitle);

    _i.notificationsInfo = document.createElement('DIV');
    _i.notificationsInfo.className =
      'blockFloatNot fnt-r-12 settingsDesc1Label';
    _i.notificationsInfo.innerHTML = I18n.t('msg_enable_disable_notifications');
    _i.container.appendChild(_i.notificationsInfo);

    _i.notificationsEnabled =
      gData.accountSettings.notifications_enabled == '1';
    _i.notificationsEnabledSelection = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_enable_notifications') + ' '),
      _i.notificationsEnabled,
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.notificationsEnabledSelection.setCallback(
      _i.toggleNotifications.bind(null, true)
    );

    _i.notificationsDisabledSelection = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_disable_notifications') + ' '),
      !_i.notificationsEnabled,
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.notificationsDisabledSelection.setCallback(
      _i.toggleNotifications.bind(null, false)
    );

    _i.calendarsTitle = document.createElement('H3');
    _i.calendarsTitle.className =
      'blockFloatNot fnt-b-14 settingsProgressLabel';
    _i.calendarsTitle.innerHTML = I18n.t('lbl_calendar_subscriptions');
    _i.container.appendChild(_i.calendarsTitle);

    _i.calendarsInfo = document.createElement('DIV');
    _i.calendarsInfo.className = 'blockFloatNot fnt-r-12 settingsDesc1Label';
    _i.calendarsInfo.innerHTML = I18n.t(
      'msg_enable_disable_calendar_subscription'
    );
    _i.container.appendChild(_i.calendarsInfo);

    _i.calendarsEnabled = gData.accountSettings.calendars_enabled == '1';
    _i.calendarsEnabledSelection = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_calendar_subscription_enable') + ' '),
      _i.calendarsEnabled,
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.calendarsEnabledSelection.setCallback(
      _i.toggleCalendars.bind(null, true)
    );

    _i.calendarsDisabledSelection = new SelectionToggle(
      _i.container,
      _i.buildPlainTextSpan(I18n.t('lbl_calendar_subscription_disable') + ' '),
      !_i.calendarsEnabled,
      'blockFloatNot settingsSelectorItemWide',
      'fnt-r-15 settingsSelectorTextWide',
      'settingsSelectorIcon'
    );
    _i.calendarsDisabledSelection.setCallback(
      _i.toggleCalendars.bind(null, false)
    );

    var spacer = document.createElement('DIV');
    spacer.id = 'settings-spacer1';
    spacer.className = 'blockFloatNot settingsSpacerImage1';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.saveSelector = spacer;
    _i.container.appendChild(spacer);

    _i.nestedDiv = document.createElement('DIV');
    _i.nestedDiv.className = 'floatRightDiv';
    _i.container.appendChild(_i.nestedDiv);

    _i.saveButton = new primaryButton('settingsSecondaryButtonRight');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.saveSettings);
    _i.nestedDiv.appendChild(_i.saveButton.container);
    _i.saveButton.container.tabIndex = 0;
    _i.firstWorkingDaySetting = Number(gData.accountSettings.first_working_day);
  };

  _i.constructor();
}
