// Function to create an SVG info icon and append it to a given element
function createInfoIcon() {
  const infoIconSVG = `
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.33318 7.33056C9.33462 6.95383 9.62955 6.66578 9.99918 6.66675C10.3949 6.66778 10.6669 6.9857 10.6665 7.33377C10.6661 7.70155 10.3662 8.00062 9.99799 8.00007C9.64678 7.99936 9.33171 7.72172 9.33318 7.33056Z" fill="#727272"/>
        <path d="M10.6242 13.5877C10.6237 13.8589 10.4262 14.0868 10.1415 14.1506C9.76001 14.2361 9.4922 13.9699 9.47844 13.6059C9.47767 13.5857 9.47771 13.5655 9.47776 13.5454L9.47778 13.5314L9.47774 10.4026C9.47774 10.3308 9.47768 10.3305 9.40009 10.3309C9.31959 10.3312 9.2392 10.3302 9.15956 10.3174C8.88645 10.2733 8.63195 10.0364 8.65098 9.70452C8.66568 9.44787 8.90199 9.20821 9.19165 9.17352C9.23846 9.16792 9.28606 9.16608 9.33333 9.16592C9.48282 9.16548 9.59023 9.16588 9.70799 9.16633C9.77927 9.1666 9.85434 9.16689 9.94483 9.16701C10.0929 9.1672 10.2342 9.19141 10.3567 9.27461C10.5327 9.3942 10.6242 9.55525 10.6244 9.75786C10.6249 10.2293 10.6248 10.7007 10.6247 11.1722L10.6247 12.2324C10.6249 12.6842 10.625 13.136 10.6242 13.5877Z" fill="#727272"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3332 10.0001C18.3332 14.6025 14.6022 18.3334 9.99984 18.3334C5.39746 18.3334 1.6665 14.6025 1.6665 10.0001C1.6665 5.39771 5.39746 1.66675 9.99984 1.66675C14.6022 1.66675 18.3332 5.39771 18.3332 10.0001ZM17.0832 10.0001C17.0832 13.9121 13.9119 17.0834 9.99984 17.0834C6.08782 17.0834 2.9165 13.9121 2.9165 10.0001C2.9165 6.08806 6.08782 2.91675 9.99984 2.91675C13.9119 2.91675 17.0832 6.08806 17.0832 10.0001Z" fill="#727272"/>
        </svg>
    `;

  // Create info icon element
  const infoIcon = document.createElement('span');
  infoIcon.innerHTML = infoIconSVG;
  infoIcon.className = 'info-icon';
  infoIcon.style.marginLeft = '5px';
  infoIcon.style.cursor = 'pointer';

  // Create tooltip element
  const tooltip = document.createElement('div');
  tooltip.innerHTML = I18n.t('msg_locked_timesheets_tooltip');
  tooltip.className = 'tooltip';
  tooltip.style.width = '330px';
  tooltip.style.position = 'absolute';
  tooltip.style.backgroundColor = '#fff';
  tooltip.style.color = '#000';
  tooltip.style.padding = '5px';
  tooltip.style.borderRadius = '3px';
  tooltip.style.visibility = 'hidden';
  tooltip.style.zIndex = '1000';
  tooltip.style.boxShadow = '0px 2px 8px rgba(0, 0, 0, 0.2)';
  tooltip.style.textAlign = 'center';

  // Append tooltip to the document
  document.body.appendChild(tooltip);

  // Position the tooltip
  infoIcon.addEventListener('mouseenter', () => {
    const rect = infoIcon.getBoundingClientRect();
    tooltip.style.top = `${
      rect.top + window.scrollY - tooltip.offsetHeight - 5
    }px`;
    tooltip.style.left = `${
      rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2
    }px`;
    tooltip.style.visibility = 'visible';
  });

  infoIcon.addEventListener('mouseleave', () => {
    tooltip.style.visibility = 'hidden';
  });

  // Append info icon to the document
  document.body.appendChild(infoIcon);

  return infoIcon;
}
