// For use (only?) in selection lists with text/search boxes.
function genericTextInput(
  width,
  defaultText,
  callback,
  focusCallback,
  enterCallback
) {
  var _i = this;

  // params
  _i.width = width;
  _i.defaultText = defaultText;
  _i.callback = callback;
  _i.focusCallback = focusCallback;
  _i.enterCallback = enterCallback;

  // locals
  _i.container = null;
  _i.input = null;
  _i.lastValue = null;

  _i.focusInput = function () {
    _i.input.focus();
    _i.input.select();
  };

  _i.clear = function () {
    // skip the first clear on IE
    if (navigator.appName == 'Microsoft Internet Explorer') {
      if (!_i.cleared) {
        _i.cleared = true;
        return;
      }
    }
    if (navigator.appName == 'Microsoft Internet Explorer') {
      _i.input.value = _i.defaultText;
      _i.lastValue = _i.defaultText;
    } else {
      _i.input.value = '';
      _i.lastValue = '';
    }
    _i.input.blur();
  };

  _i.inputKeydown = function (evt) {
    if (evt.keyCode == 13) {
      cancelEvent(evt);
      if (_i.enterCallback) _i.enterCallback(_i.input.value);
    }
  };

  _i.inputKeyup = function (evt) {
    if (_i.input.value == _i.lastValue) {
      return;
    }

    if (_i.callback) {
      _i.callback(_i.input.value);
      _i.lastValue = _i.input.value;
    }
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.style.position = 'absolute';
    _i.container.style.left = '0px';
    _i.container.style.top = '0px';
    _i.container.style.width = _i.width + 'px';
    _i.container.style.height = '30px';

    _i.input = document.createElement('INPUT');
    _i.input.type = 'text';
    _i.input.className = 'fnt-r-15 textInput filterPopupTextInput';
    _i.input.style.position = 'absolute';
    _i.input.style.width = _i.width + 'px';
    _i.input.placeholder = _i.defaultText;
    if (navigator.appName == 'Microsoft Internet Explorer') {
      _i.input.value = _i.defaultText;
      _i.lastValue = _i.defaultText;
    }
    xAddEventListener(_i.input, 'keydown', _i.inputKeydown, true);
    xAddEventListener(_i.input, 'keyup', _i.inputKeyup, true);
    xAddEventListener(_i.input, 'focus', _i.focusCallback, true);

    _i.container.appendChild(_i.input);
  };

  _i.constructor();
}

function filterPopup(
  width,
  height,
  dialogViewport,
  listJson,
  dialogSpecs,
  callback,
  multiselect,
  blurCallback,
  direction,
  preferredDirections,
  noautorotate,
  doNotDismissOnSelection,
  parentPopupContainer,
  enterCallback
) {
  var _i = this;

  // params
  _i.width = width;
  _i.height = height;
  _i.dialogViewport = dialogViewport;
  _i.listJson = listJson;
  _i.dialogSpecs = dialogSpecs;
  _i.callback = callback;
  _i.multiselect = multiselect;
  _i.blurCallback = blurCallback;
  _i.direction = direction;
  _i.preferredDirections = preferredDirections;
  _i.noautorotate = noautorotate;
  _i.doNotDismissOnSelection = doNotDismissOnSelection;
  _i.enterCallback = enterCallback;

  // locals
  _i.container = null;
  _i.popupContainer = null;
  _i.selectionList = null;
  _i.scrollCallback = null;
  _i.textInput = null;
  _i.newProjectButton = null;

  // const
  _i.rowHeight = 30;

  _i.isVisible = function () {
    return _i.popupContainer.isVisible();
  };

  _i.remove = function () {
    _i.popupContainer.remove();
  };

  _i.hide = function () {
    _i.selectionList.hide();
    _i.popupContainer.hide();
    _i.textInput.clear();
  };

  _i.showInternal = function () {
    _i.textInput.clear();
    _i.selectionList.show(_i.container);
    setTimeout(_i.textInput.focusInput);
  };

  _i.show = function (elementToPointAt, xoffset, yoffset) {
    var wasVisible = _i.isVisible();

    _i.popupContainer.pointAtElement(elementToPointAt, xoffset, yoffset);

    if (!wasVisible) {
      _i.showInternal();
    }
  };

  // Refresh selection from existing data, e.g. on being shown after being hidden.
  _i.refreshSelection = function () {
    _i.selectionList.refreshSelection();
  };

  _i.showAtPoint = function (x, y, xoffset, yoffset) {
    var wasVisible = _i.isVisible();

    _i.popupContainer.pointAt(x, y, xoffset, yoffset);

    if (!wasVisible) {
      _i.showInternal();
    }
  };

  _i.textInputEnterCallback = function (str) {
    if (_i.enterCallback) {
      _i.enterCallback(str);
      _i.selectionList.hide();
      _i.popupContainer.hide();
      _i.textInput.clear();
    }
  };

  _i.inputCallback = function (str) {
    if (str && str.length > 0) {
      _i.selectionList.filter(str);
    } else {
      _i.selectionList.removeFilter();
    }
  };

  _i.multiselectClick = function () {
    if (_i.callback) {
      _i.callback(_i.selectionList.getSelection());
    }
  };

  _i.itemClick = function (index, selectedElement) {
    var selection = _i.selectionList.getSelection();
    if (_i.callback) {
      _i.callback(selection, true /* hide */, selectedElement);
    }
    if (!_i.doNotDismissOnSelection) {
      _i.hide();
    }
  };

  _i.setSelectionCallback = function (callback) {
    _i.callback = callback;
  };

  _i.setBlurCallback = function (callback) {
    _i.blurCallback = callback;
  };

  _i.blurCallbackInternal = function () {
    _i.selectionList.hide();
    _i.textInput.clear();
    if (_i.blurCallback) _i.blurCallback();
  };

  _i.showSpinner = function () {
    _i.recalculating = document.createElement('DIV');
    _i.recalculating.className = 'projectStatusSpinner';

    _i.spinner = document.createElement('IMG');
    _i.spinner.src = 'images/progress-indicator_15.gif';
    _i.loading = document.createElement('SPAN');
    _i.loading.innerText = I18n.t('lbl_loading');
    _i.loading.className = 'loadingProjectsSpinner fnt-r-14';
    _i.recalculating.appendChild(_i.spinner);
    _i.recalculating.appendChild(_i.loading);
    _i.container.appendChild(_i.recalculating);
    _i.spinnerShowing = true;
  };

  _i.removeSpinner = function () {
    _i.container.removeChild(_i.recalculating);
    _i.spinnerShowing = false;
  };

  _i.spinnerIsShowing = function () {
    return _i.spinnerShowing;
  };

  _i.setList = function (data) {
    _i.selectionList.setList(data);
  };

  _i.refreshList = function (data) {
    _i.selectionList.hide();
    if (_i.isVisible()) _i.selectionList.show(_i.container);
  };

  _i.constructor = function () {
    var HEADER_HEIGHT = 47;
    var HEADER_MARGIN = 5;

    _i.container = document.createElement('DIV');
    _i.container.style.width = _i.width + 'px';
    _i.container.style.height = _i.height + 'px';
    _i.container.style.overflow = 'hidden';

    _i.headerContainer = document.createElement('DIV');
    _i.headerContainer.style.width = _i.width + 'px';
    _i.headerContainer.style.height = HEADER_HEIGHT + 'px';
    _i.headerContainer.style.overflow = 'hidden';
    _i.headerContainer.style.backgroundColor =
      dialogSpecs.headerBackgroundColor;

    _i.container.appendChild(_i.headerContainer);

    if (_i.newProjectCallback) {
      throw 'deperacated';
    }

    _i.textInput = new genericTextInput(
      _i.width - HEADER_MARGIN * 4,
      _i.enterCallback ? I18n.t('lbl_search_or_add') : I18n.t('lbl_search'),
      _i.inputCallback,
      null,
      _i.textInputEnterCallback
    );
    _i.textInput.container.style.margin = HEADER_MARGIN + 'px';
    _i.headerContainer.appendChild(_i.textInput.container);

    _i.popupContainer = new popupContainer(
      _i.dialogSpecs,
      _i.width,
      _i.height,
      3,
      _i.noautorotate ? false : true,
      _i.direction ? _i.direction : 'wc',
      _i.dialogViewport,
      _i.preferredDirections ? _i.preferredDirections : null,
      _i.blurCallbackInternal,
      parentPopupContainer
    );

    _i.selectionList = new selectionListEx(
      _i.width,
      _i.height - HEADER_HEIGHT,
      _i.multiselect,
      _i.itemClick,
      _i.multiselectClick
    );

    _i.selectionList.setList(_i.listJson);

    _i.popupContainer.attachContent(_i.container);
  };

  _i.constructor();
}

function selectionPopup(
  width,
  height,
  dialogViewport,
  listJson,
  dialogSpecs,
  callback,
  multiselect,
  blurCallback,
  direction,
  preferredDirections,
  noautorotate,
  doNotDismissOnSelection,
  parentPopupContainer,
  selectMultipleButtonClickCallback /* if provided, show select multiple button */,
  selectMultipleButtonLabel /* if provided, override default button label */,
  hideHighlight /* Indicates whether highlight should be shown on hover/select. By default highlight is shown. */,
  dropdownID
) {
  var _i = this;

  // params
  _i.width = width;
  _i.height = height;
  _i.dialogViewport = dialogViewport;
  _i.listJson = listJson;
  _i.dialogSpecs = dialogSpecs;
  _i.callback = callback;
  _i.multiselect = multiselect;
  _i.blurCallback = blurCallback;
  _i.direction = direction;
  _i.preferredDirections = preferredDirections;
  _i.noautorotate = noautorotate;
  _i.doNotDismissOnSelection = doNotDismissOnSelection;
  _i.dropdownID = dropdownID;

  _i.showSelectMultipleButton = Boolean(selectMultipleButtonClickCallback);
  _i.hasPhaseMessage = false;
  _i.customPopupHeight = false;
  _i.customPopupWidth = false;

  for (let i = 0; i < listJson.length; i++) {
    if (listJson[i].isPhaseInfoMsg) {
      _i.hasPhaseMessage = true;
    }
    if (listJson[i].customPopupHeightAndWidth != null) {
      _i.customPopupHeight =
        listJson[i].customPopupHeightAndWidth.height ?? false;
      _i.customPopupWidth =
        listJson[i].customPopupHeightAndWidth.width ?? false;
    }
    if (_i.hasPhaseMessage && _i.customPopupHeight && _i.customPopupWidth) {
      break;
    }
  }

  __A(
    !_i.showSelectMultipleButton || _i.multiselect,
    'showSelectMultipleButton should only be used when multiselect is true'
  );

  _i.selectMultipleButtonClickCallback = selectMultipleButtonClickCallback;
  _i.selectMultipleButtonLabel = selectMultipleButtonLabel
    ? selectMultipleButtonLabel
    : I18n.t('lbl_use_selected_items');

  // locals
  _i.popupContainer = null;
  _i.selectionList = null;
  _i.scrollCallback = null;

  // const
  _i.rowHeight = 30;

  // Opt out of showing hover state if a highlighted state is not desired on hover.
  // This is not the same as ignoreHover, we may track the hovered item, just not
  // visually indicate it.
  _i.hideHighlight = hideHighlight;

  _i.isVisible = function () {
    return _i.popupContainer.isVisible();
  };

  _i.setSelectionCallback = function (callback) {
    _i.callback = callback;
  };

  _i.remove = function () {
    _i.popupContainer.remove();
    _i.selectionList.hide();
    _i.selectionList.remove();
  };

  _i.hide = function () {
    _i.selectionList.hide();
    if (_i.popupContainer.isVisible()) _i.popupContainer.hide();
  };

  _i.showInternal = function () {
    _i.simpleContainerScroll.show();
    _i.selectionList.show();
  };

  _i.show = function (elementToPointAt, xoffset, yoffset) {
    var wasVisible = _i.isVisible();

    _i.popupContainer.pointAtElement(elementToPointAt, xoffset, yoffset);

    if (!wasVisible) {
      _i.showInternal();
    }
  };

  _i.showAtPoint = function (x, y, xoffset, yoffset) {
    var wasVisible = _i.isVisible();

    _i.popupContainer.pointAt(x, y, xoffset, yoffset);

    if (!wasVisible) {
      _i.showInternal();
    }
  };

  _i.itemClick = function (index, hide) {
    if (index && index[0] && index[0].container) {
      var selected = document.querySelector('.selectionList__selected');
      if (selected) {
        selected.classList.remove('selectionList__selected');
      }
      index[0].container.classList.add('selectionList__selected');
    }
    if (!(_i.multiselect || _i.doNotDismissOnSelection)) {
      _i.selectionList.hide();
      _i.popupContainer.hide();
    }
    if (_i.multiselect && hide) {
      _i.selectionList.hide();
      _i.popupContainer.hide();
    }
    if (_i.callback) {
      _i.callback(_i.selectionList.getSelection(), hide);
    }
  };

  _i.multiSelectCheckboxClick = function (index, hide) {
    if (_i.showSelectMultipleButton) {
      return;
    }
    _i.itemClick(index, hide);
  };

  _i.selectMultipleButtonClick = function () {
    _i.selectionList.hide();
    _i.popupContainer.hide();
    if (_i.callback) {
      _i.callback(_i.selectionList.getSelection());
    }
  };

  _i.blurCallbackInternal = function () {
    _i.selectionList.hide();
    if (_i.blurCallback) _i.blurCallback();
  };

  _i.updateList = function (listJson) {
    _i.selectionList.updateList(listJson);
  };

  function getPopupHeight() {
    let height = _i.height;
    if (_i.customPopupHeight) {
      height = _i.height + _i.customPopupHeight;
    } else if (_i.hasPhaseMessage) {
      height = _i.height + 12;
    } else if (_i.showSelectMultipleButton) {
      height = _i.height + 40;
    }
    return height;
  }

  function getPopupWidth() {
    let width = _i.width;
    if (_i.customPopupWidth) {
      width = _i.width + _i.customPopupWidth;
    }
    return width;
  }

  _i.constructor = function () {
    _i.popupContainer = new popupContainer(
      _i.dialogSpecs,
      getPopupWidth(),
      getPopupHeight(),
      3,
      _i.noautorotate ? false : true,
      _i.direction ? _i.direction : 'wc',
      _i.dialogViewport,
      _i.preferredDirections ? _i.preferredDirections : null,
      _i.blurCallbackInternal,
      parentPopupContainer
    );

    var popupContent = document.createElement('div');
    popupContent.style.height = getPopupHeight() + 'px';
    popupContent.style.width = _i.width + 'px';
    const adjustedHeight = _i.customPopupHeight
      ? _i.height + _i.customPopupHeight
      : _i.height;
    _i.selectionList = new selectionList(
      getPopupWidth(),
      adjustedHeight,
      _i.multiselect,
      null,
      _i.itemClick,
      false /*ignoreHover*/,
      _i.multiSelectCheckboxClick,
      _i.hideHighlight,
      _i.dropdownID,
      _i.popupContainer
    );
    _i.selectionList.setList(_i.listJson);
    _i.selectionList.container.style.position = 'relative';
    _i.selectionList.container.style.width = _i.width + 'px';
    _i.selectionList.inner.style.width = _i.width - 6 + 'px';

    popupContent.appendChild(_i.selectionList.container);

    if (_i.showSelectMultipleButton) {
      var d = document.createElement('div');
      d.className = 'blockFloat';
      d.style.height = '40px';
      d.style.width = _i.width + 'px';

      var selectMultipleButton = new primaryButtonNarrow();
      selectMultipleButton.setButtonText(selectMultipleButtonLabel);
      selectMultipleButton.setCallback(_i.selectMultipleButtonClick);
      selectMultipleButton.container.style.marginLeft = '12px';
      selectMultipleButton.container.style.marginTop = '5px';

      popupContent.appendChild(d);
      d.appendChild(selectMultipleButton.container);
    }

    _i.popupContainer.attachContent(popupContent);
    _i.simpleContainerScroll = new simpleContainerScroll(
      _i.selectionList.container,
      _i.selectionList.inner,
      _i.rowHeight
    );
    _i.selectionList.scrollCallback = _i.simpleContainerScroll.scrollBy;
  };

  _i.constructor();
}

function simpleContainerScroll(outer, inner, rowHeight) {
  var _i = this;

  // params
  _i.outer = outer;
  _i.inner = inner;
  _i.rowHeight = rowHeight;

  // locals
  _i.minY = null;
  _i.maxY = null;
  _i.v = null;
  _i.indicator = null;

  _i.setTop = function (y) {
    _i.inner.floatY = y;
    xTop(_i.inner, _i.inner.floatY);
    _i.setIndicator();
    _i.setUpDownButtonState();
  };

  _i.setUpDownButtonState = function () {
    if (xHeight(_i.inner) < xHeight(_i.outer)) {
      _i.upButton.ani.setAnimationTarget(0);
      _i.downButton.ani.setAnimationTarget(0);
      return;
    }

    if (xTop(_i.inner) >= -15) {
      _i.upButton.ani.setAnimationTarget(0);
    } else {
      _i.upButton.ani.setAnimationTarget(1);
    }

    if (xTop(_i.inner) < _i.minY + 15) {
      _i.downButton.ani.setAnimationTarget(0);
    } else {
      _i.downButton.ani.setAnimationTarget(1);
    }
  };

  _i.setIndicator = function () {
    if (xHeight(_i.inner) <= xHeight(_i.outer)) {
      _i.indicator.style.display = 'none';
      return;
    }

    _i.indicator.style.display = 'block';
    _i.indicator.style.height =
      (xHeight(_i.outer) / xHeight(_i.inner)) * xHeight(_i.outer) + 'px';
    _i.indicatorMargin =
      xHeight(_i.outer) - parseInt(_i.indicator.style.height);
    xTop(_i.indicator, (xTop(_i.inner) / _i.minY) * _i.indicatorMargin);
  };

  _i.ease = function () {
    if (
      Awe.clamp(Math.round(_i.inner.floatY), _i.minY, _i.maxY) ==
      Math.round(_i.inner.floatY)
    ) {
      return true;
    }

    if (_i.inner.floatY > _i.maxY) {
      var target = _i.maxY;
    } else {
      var target = _i.minY;
    }

    var incAmt = (target - _i.inner.floatY) / 5;

    _i.setTop(_i.inner.floatY + incAmt);

    return false;
  };

  _i.glide = function (delta, elapsed) {
    if (
      Awe.clamp(_i.inner.floatY, _i.minY, _i.maxY) != _i.inner.floatY ||
      Math.abs(_i.v) < 0.005
    ) {
      Awe.addAnimationCallback(_i.ease);
      return true;
    }

    _i.inner.floatY += delta * _i.v;
    _i.setTop(_i.inner.floatY);
    _i.v *= 0.85;

    return false;
  };

  _i.dragStart = function (el, x, y, evt) {
    cancelEvent(evt);
  };

  _i.drag = function (el, dx, dy, evt) {
    _i.setTop(xTop(_i.inner) + dy);
  };

  _i.dragEnd = function (el, x, y, evt) {
    if (Math.abs(evt.totalVectorY) > 3) {
      cancelEvent(evt);
    }

    _i.v = evt.velocityY;

    Awe.addAnimationCallback(_i.glide);
  };

  _i.deltaWheel = function (delta) {
    if (xHeight(_i.inner) < xHeight(_i.outer)) {
      return;
    }

    var newY = xTop(_i.inner) + delta * _i.rowHeight;
    if (newY > _i.maxY) {
      newY = _i.maxY;
    }
    if (newY < _i.minY) {
      newY = _i.minY;
    }

    _i.setTop(newY);
  };

  _i.downButtonMouseover = function (evt) {
    cancelEvent(evt);
    _i.downButton.backgroundHover.container.style.display = 'block';
  };

  _i.downButtonMouseout = function (evt) {
    cancelEvent(evt);
    _i.downButton.backgroundHover.container.style.display = 'none';
  };

  _i.scrollList = function (delta, elapsed) {
    var newY = Awe.clamp(
      _i.inner.floatY + delta * _i.scrollIncrement * _i.scrollDirection,
      _i.minY,
      _i.maxY
    );
    _i.scrollIncrement += 0.01;
    _i.setTop(newY);
  };

  _i.downButtonMousedown = function (evt) {
    cancelEvent(window.event || evt);
    _i.downButton.backgroundDepress.container.style.display = 'block';
    xAddEventListener(document, platform.mouseUp, _i.downButtonMouseup, true);
    _i.scrollDirection = -1;
    _i.scrollIncrement = 0.4;
    _i.listScrollAni = Awe.addAnimationCallback(_i.scrollList);
  };

  _i.downButtonMouseup = function (evt) {
    cancelEvent(window.event || evt);
    _i.downButton.backgroundDepress.container.style.display = 'none';
    xRemoveEventListener(
      document,
      platform.mouseUp,
      _i.downButtonMouseup,
      true
    );
    Awe.cancelAnimationCallback(_i.listScrollAni);
  };

  _i.upButtonMouseover = function (evt) {
    cancelEvent(evt);
    _i.upButton.backgroundHover.container.style.display = 'block';
  };

  _i.upButtonMouseout = function (evt) {
    cancelEvent(evt);
    _i.upButton.backgroundHover.container.style.display = 'none';
  };

  _i.upButtonMousedown = function (evt) {
    cancelEvent(window.event || evt);
    _i.upButton.backgroundDepress.container.style.display = 'block';
    xAddEventListener(document, platform.mouseUp, _i.upButtonMouseup, true);
    _i.scrollDirection = 1;
    _i.scrollIncrement = 0.4;
    _i.listScrollAni = Awe.addAnimationCallback(_i.scrollList);
  };

  _i.upButtonMouseup = function (evt) {
    cancelEvent(window.event || evt);
    _i.upButton.backgroundDepress.container.style.display = 'none';
    xRemoveEventListener(document, platform.mouseUp, _i.upButtonMouseup, true);
    Awe.cancelAnimationCallback(_i.listScrollAni);
  };

  _i.scrollBy = function (dy) {
    var newY = Awe.clamp(_i.inner.floatY + dy, _i.minY, _i.maxY);
    _i.setTop(newY);
  };

  _i.setMinMax = function () {
    _i.minY = xHeight(_i.outer) - xHeight(_i.inner);
    _i.maxY = 0;
  };

  _i.show = function () {
    _i.setMinMax();
    _i.setUpDownButtonState();
    _i.setIndicator();
  };

  _i.constructor = function () {
    _i.indicator = document.createElement('DIV');
    _i.indicator.style.position = 'absolute';
    _i.indicator.style.right = '0px';
    _i.indicator.style.top = '0px';
    _i.indicator.style.width =
      compSpritesButtonsIcons.verticalScrollIndicator.width + 'px';
    // this happens dynamically on show
    //_i.indicator.style.height = xHeight(_i.outer) / xHeight(_i.inner) * xHeight(_i.outer) + "px";
    //_i.indicatorMargin = xHeight(_i.outer) - parseInt(_i.indicator.style.height);
    _i.indicator.appendChild(
      new backgroundBar(compSpritesButtonsIcons.verticalScrollIndicator)
        .container
    );

    _i.buttonTemplate = document.createElement('DIV');
    _i.buttonTemplate.style.position = 'absolute';
    _i.buttonTemplate.style.display = 'block';
    _i.buttonTemplate.style.width = '100%';
    _i.buttonTemplate.style.height =
      compSpritesButtonsIcons.listUpDownBackground.height + 'px';
    _i.buttonTemplate.style.left = '0px';
    _i.buttonTemplate.style.cursor = 'pointer';
    xOpacity(_i.buttonTemplate, 0.01);

    _i.upButton = _i.buttonTemplate.cloneNode(true);
    _i.upButton.style.top = '0px';
    _i.upButton.ani = new animateOpacity(_i.upButton);

    _i.upButton.backgroundRest = new backgroundBar(
      compSpritesButtonsIcons.listUpDownBackground
    );
    _i.upButton.backgroundRest.container.appendChild(
      centeredSprite(compSpritesButtonsIcons.listUpArrow)
    );
    _i.upButton.appendChild(_i.upButton.backgroundRest.container);

    _i.upButton.backgroundHover = new backgroundBar(
      compSpritesButtonsIcons.listUpDownBackgroundHover
    );
    _i.upButton.backgroundHover.container.appendChild(
      centeredSprite(compSpritesButtonsIcons.listUpArrow)
    );
    _i.upButton.appendChild(_i.upButton.backgroundHover.container);
    _i.upButton.backgroundHover.container.style.display = 'none';

    _i.upButton.backgroundDepress = new backgroundBar(
      compSpritesButtonsIcons.listUpDownBackgroundDepress
    );
    _i.upButton.backgroundDepress.container.appendChild(
      centeredSprite(compSpritesButtonsIcons.listUpArrow)
    );
    _i.upButton.appendChild(_i.upButton.backgroundDepress.container);
    _i.upButton.backgroundDepress.container.style.display = 'none';

    xAddEventListener(_i.upButton, 'mouseover', _i.upButtonMouseover, true);
    xAddEventListener(_i.upButton, 'mouseout', _i.upButtonMouseout, true);
    xAddEventListener(
      _i.upButton,
      platform.mouseDown,
      _i.upButtonMousedown,
      true
    );

    _i.downButton = _i.buttonTemplate.cloneNode(true);
    _i.downButton.style.bottom = '0px';
    _i.downButton.ani = new animateOpacity(_i.downButton);
    _i.downButton.backgroundRest = new backgroundBar(
      compSpritesButtonsIcons.listUpDownBackground
    );
    _i.downButton.backgroundRest.container.appendChild(
      centeredSprite(compSpritesButtonsIcons.listDownArrow)
    );
    _i.downButton.appendChild(_i.downButton.backgroundRest.container);

    _i.downButton.backgroundHover = new backgroundBar(
      compSpritesButtonsIcons.listUpDownBackgroundHover
    );
    _i.downButton.backgroundHover.container.appendChild(
      centeredSprite(compSpritesButtonsIcons.listDownArrow)
    );
    _i.downButton.appendChild(_i.downButton.backgroundHover.container);
    _i.downButton.backgroundHover.container.style.display = 'none';

    _i.downButton.backgroundDepress = new backgroundBar(
      compSpritesButtonsIcons.listUpDownBackgroundDepress
    );
    _i.downButton.backgroundDepress.container.appendChild(
      centeredSprite(compSpritesButtonsIcons.listDownArrow)
    );
    _i.downButton.appendChild(_i.downButton.backgroundDepress.container);
    _i.downButton.backgroundDepress.container.style.display = 'none';

    xOpacity(_i.downButton, 1);

    xAddEventListener(_i.downButton, 'mouseover', _i.downButtonMouseover, true);
    xAddEventListener(_i.downButton, 'mouseout', _i.downButtonMouseout, true);
    xAddEventListener(
      _i.downButton,
      platform.mouseDown,
      _i.downButtonMousedown,
      true
    );

    _i.outer.appendChild(_i.indicator);
    _i.outer.appendChild(_i.upButton);
    _i.outer.appendChild(_i.downButton);

    new mouseWheelHandler(_i.outer, _i.deltaWheel);
    new vEnableDrag(_i.inner, _i.dragStart, _i.drag, _i.dragEnd);

    _i.inner.floatY = 0;
  };

  _i.constructor();
}

function selectionList(
  width,
  height,
  multiselect,
  attachTo,
  callback,
  ignoreHover,
  multiSelectCheckboxClickCallback,
  hideHighlight,
  dropdownID,
  popupContainer
) {
  var _i = this;

  // params
  _i.width = width;
  _i.height = height;
  _i.multiselect = multiselect;
  _i.attachTo = xGetElementById(attachTo);
  _i.callback = callback;
  _i.multiSelectCheckboxClickCallback = multiSelectCheckboxClickCallback
    ? multiSelectCheckboxClickCallback
    : callback;
  _i.ignoreHover = ignoreHover;
  _i.dropdownID = dropdownID;

  // By default, we will show a hover/select highlight.
  _i.hideHighlight = hideHighlight;

  // locals
  _i.container = null;
  _i.inner = null;
  _i.dat = null;
  _i.items = [];
  _i.guid = Awe.getGuid();

  // const
  _i.rowHeight = 30;

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);

    if (_i.container.parentNode) {
      _i.container.parentNode.removeChild(_i.container);
    }

    _i.attachTo.appendChild(_i.container);
  };

  _i.remove = function () {
    if (_i.container.parentNode) {
      _i.container.parentNode.removeChild(_i.container);
    }
  };

  _i.setList = function (jsonDat) {
    _i.dat = jsonDat;

    for (var z = 0; z < _i.dat.length; z++) {
      _i.items[z] = new selectionlistItem(
        _i.rowHeight,
        z,
        _i.dat[z],
        _i.multiselect,
        _i.inner,
        _i.itemClick,
        _i.itemMouseover,
        _i.itemMouseout,
        _i.itemLabelClick,
        _i.itemCheckboxClick,
        _i.hideHighlight,
        popupContainer
      );
    }
  };

  _i.updateList = function (newDat) {
    if (!newDat || newDat.length != _i.dat.length) {
      throw 'new list data must have the same number of elements as current data';
    }

    _i.dat = newDat;

    for (var z = 0; z < _i.dat.length; z++) {
      _i.items[z].updateItem(newDat[z]);
    }
  };

  _i.unfilter = function () {
    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].show();
    }
  };

  _i.filter = function (str) {
    if (str == '') {
      _i.unfilter();
      return;
    }

    var patt = new RegExp(str, 'gi');

    for (var z = 0; z < _i.items.length; z++) {
      if (_i.items[z].dat.type == 'item') {
        if (_i.items[z].dat.label.match(patt)) {
          _i.items[z].show();
        } else {
          _i.items[z].hide();
        }
      }
    }
  };

  _i.unselectAll = function () {
    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].unselect();
    }
  };

  _i.getSelection = function () {
    var retVal = [];

    for (var z = 0; z < _i.items.length; z++) {
      if (_i.items[z].dat.selected) {
        // set and get index at the last moment in case re-indexing happened
        _i.items[z].dat.index = z;
        retVal[retVal.length] = _i.items[z].dat;
      }
    }

    return retVal;
  };

  _i.callbackSelectClosure = function () {
    _i.callback(_i.getSelection(), true);
  };

  _i.callbackClosure = function () {
    _i.callback(_i.getSelection(), false);
  };

  _i.itemMouseover = function (index) {
    if (index < 0) {
      return;
    }

    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].hideHoverState();
    }

    if (!_i.hideHighlight) {
      _i.items[index].showHoverState();
    }

    if (!_i.ignoreHover) _i.curHighlightedItem = index;
  };

  _i.calculateScrollOffset = function (index) {
    var yOffset = xOffset(_i.items[index].container, _i.container).y;

    // is it below view
    var diff =
      yOffset +
      xHeight(_i.items[index].container) -
      xHeight(_i.container) +
      _i.rowHeight;

    if (diff > 0 && _i.scrollCallback) {
      _i.scrollCallback(-diff);
      return;
    }

    // is it above view
    var diff = yOffset - _i.rowHeight;

    if (diff < 0 && _i.scrollCallback) {
      _i.scrollCallback(-diff);
    }
  };

  _i.itemMouseout = function (index) {};

  _i.itemClick = function (index) {
    if (!_i.multiselect) {
      _i.unselectAll();
      _i.items[index].select();
    } else {
      _i.items[index].toggle();
    }

    if (_i.callback) {
      setTimeout(_i.callbackClosure, 125);
    }
  };

  _i.itemCheckboxClick = function (index) {
    if (!_i.multiselect) {
      _i.unselectAll();
      _i.items[index].select();
    } else {
      _i.items[index].toggle();
    }

    if (_i.callback) {
      setTimeout(_i.multiSelectCheckboxClickCallback, 125);
    }
  };

  _i.itemLabelClick = function (index) {
    _i.unselectAll();
    _i.items[index].select();

    if (_i.callback) {
      setTimeout(_i.callbackSelectClosure, 125);
    }
  };

  _i.isInputElement = function (evt) {
    // Input elements have their own use for keypress events.
    // If the keypress occurs in an input element, allow the element
    // (or the scropt that controls the element) handle it.
    return evt.srcElement.tagName.toLowerCase() === 'input';
  };

  _i.containerKeypress = function (evt) {
    // enter
    if (
      evt.keyCode == 13 &&
      _i.curHighlightedItem > -1 &&
      !_i.ignoreEnter &&
      !_i.isInputElement(evt)
    ) {
      _i.itemClick(_i.curHighlightedItem);
      cancelEvent(evt);
      return;
    }

    // space
    if (evt.keyCode == 32 && _i.multiselect && _i.curHighlightedItem > -1) {
      _i.itemClick(_i.curHighlightedItem);
      cancelEvent(evt);
      return;
    }

    // skip anything but up and down arrow
    if (_i.multiselect || (evt.keyCode != 38 && evt.keyCode != 40)) {
      return;
    } else {
      cancelEvent(evt);
    }

    // up arrow
    if (evt.keyCode == 38) {
      _i.curHighlightedItem = _i.previousItemIndex();
    }

    // down arrow
    if (evt.keyCode == 40) {
      _i.curHighlightedItem = _i.nextItemIndex();
    }

    // act upon up down
    _i.itemMouseover(_i.curHighlightedItem);

    // selected item may be clipped by the list outer container
    _i.calculateScrollOffset(_i.curHighlightedItem);
  };

  _i.nextItemIndex = function () {
    if (_i.noSelectionAvailable()) {
      return -1;
    }

    var startIndex = _i.curHighlightedItem;
    _i.curHighlightedItem = _i.incIndex();

    while (!_i.items[_i.curHighlightedItem].isSelectable()) {
      _i.curHighlightedItem = _i.incIndex();
    }

    if (_i.curHighlightedItem < startIndex) {
      _i.curHighlightedItem = startIndex;
    }

    return _i.curHighlightedItem;
  };

  _i.previousItemIndex = function () {
    if (_i.noSelectionAvailable()) {
      return -1;
    }
    if (_i.curHighlightedItem == -1) {
      return _i.nextItemIndex();
    }

    var startIndex = _i.curHighlightedItem;
    _i.curHighlightedItem = _i.decIndex();

    while (!_i.items[_i.curHighlightedItem].isSelectable()) {
      _i.curHighlightedItem = _i.decIndex();
    }

    if (_i.curHighlightedItem > startIndex) {
      _i.curHighlightedItem = startIndex;
    }

    return _i.curHighlightedItem;
  };

  _i.incIndex = function () {
    return _i.curHighlightedItem < _i.items.length - 1
      ? _i.curHighlightedItem + 1
      : 0;
  };

  _i.decIndex = function () {
    return _i.curHighlightedItem > 0
      ? _i.curHighlightedItem - 1
      : _i.items.length - 1;
  };

  _i.noSelectionAvailable = function () {
    for (var z = 0; z < _i.items.length; z++) {
      if (_i.items[z].isSelectable()) {
        return false;
      }
    }

    return true;
  };

  _i.sizeLabels = function () {
    _i.inner.pageX = xPageX(_i.inner);

    for (var z = 0; z < _i.items.length; z++) {
      _i.items[z].sizeLabel();
    }
  };

  _i.show = function () {
    _i.curHighlightedItem = -1;
    xAddEventListener(document, 'keydown', _i.containerKeypress, false);

    // set label size on first show
    if (_i.sizeLabels) {
      _i.sizeLabels();
      _i.sizeLabels = null;
    }
  };

  _i.hide = function () {
    xRemoveEventListener(document, 'keydown', _i.containerKeypress, false);
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot';
    _i.container.id = _i.dropdownID;
    _i.container.style.position = 'absolute';
    _i.container.style.left = '0px';
    _i.container.style.top = '0px';
    _i.container.style.width = _i.width + 'px';
    _i.container.style.height = _i.height + 'px';
    _i.container.style.backgroundColor = '#FFFFFF';
    _i.container.style.overflow = 'hidden';

    _i.inner = document.createElement('DIV');
    _i.inner.style.position = 'absolute';
    _i.inner.style.left = '0px';
    _i.inner.style.top = '0px';
    _i.inner.style.width = '100%';
    _i.inner.style.height = 'auto';

    if (_i.attachTo) {
      _i.attachTo.appendChild(_i.container);
    }

    _i.container.appendChild(_i.inner);
  };

  _i.constructor();
}

function selectionlistItem(
  rowHeight,
  index,
  dat,
  multiselect,
  attachTo,
  callback,
  mouseoverCallback,
  mouseoutCallback,
  labelCallback,
  checkboxCallback,
  hideHighlight,
  parentPopupContainer
) {
  var _i = this;

  __A(
    !dat.isexclusive || (dat.isexclusive && multiselect),
    'exclusive should only be used in multiselect lists.'
  );

  // params
  _i.rowHeight = rowHeight;
  _i.index = index;
  _i.dat = dat;
  _i.multiselect = multiselect;
  _i.attachTo = xGetElementById(attachTo);
  _i.callback = callback;
  _i.mouseoverCallback = mouseoverCallback;
  _i.mouseoutCallback = mouseoutCallback;
  _i.labelCallback = labelCallback;
  _i.checkboxCallback = checkboxCallback;

  // locals
  _i.container = null;
  _i.inner = null;
  _i.bground = null;
  _i.checkbox = null;

  // Show highlight unless indicated otherwise.
  _i.hideHighlight = hideHighlight;

  _i.isSelectable = function () {
    return _i.dat.type == 'item' && _i.container.style.display != 'none';
  };

  _i.hide = function () {
    _i.container.style.display = 'none';
  };

  _i.show = function () {
    _i.container.style.display = 'block';
  };

  _i.toggle = function () {
    if (_i.dat.selected) {
      _i.unselect();
    } else {
      _i.select();
    }
  };

  _i.select = function () {
    if (_i.dat.type != 'item') {
      return;
    }

    _i.dat.selected = true;

    if (_i.checkbox) {
      getSprite(compSpritesButtonsIcons.selectedCheckbox, _i.checkbox);
    }

    if (!_i.multiselect) {
      _i.hover.hide();

      if (!_i.hideHighlight) {
        _i.bground.show();
      }
      _i.text.style.color = '#FFFFFF';
    }
  };

  _i.unselect = function () {
    if (_i.dat.type != 'item') {
      return;
    }

    _i.dat.selected = null;

    if (_i.checkbox) {
      getSprite(compSpritesButtonsIcons.unselectedCheckbox, _i.checkbox);
    }

    _i.bground.hide();
    _i.text.style.color = '#1A1A1A';
  };

  _i.setAttachment = function (attachTo) {
    _i.attachTo = xGetElementById(attachTo);

    if (_i.container.parentNode) {
      _i.container.parentNode.removeChild(_i.container);
    }

    _i.attachTo.appendChild(_i.container);
  };

  _i.setCallback = function (callback) {
    _i.callback = callback;
  };

  _i.contClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.dat.type != 'item') {
      return;
    }

    if (_i.callback) {
      _i.callback(_i.index);
    }
  };

  _i.contKeypress = function (evt) {
    if (typeof e === 'undefined' && window.event) {
      e = window.event;
    }
    if (e.key === 'Enter' || e.key === ' ') {
      _i.contClick();
    }
    return true;
  };

  _i.contLabelClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.dat.type != 'item') {
      return;
    }

    if (_i.labelCallback) {
      _i.labelCallback(_i.index);
    }
  };

  _i.contCheckboxClick = function (evt) {
    evt = evt || window.event;
    cancelEvent(evt);

    if (_i.dat.type != 'item') {
      return;
    }

    if (_i.labelCallback) {
      _i.checkboxCallback(_i.index);
    }
  };

  _i.formatLabel = function () {
    _i.container.style.cursor = 'default';
    _i.container.style.color = '#666666';

    if (_i.dat.label && _i.dat.label.nodeName) {
      _i.text.appendChild(_i.dat.label);
    } else {
      _i.text.innerText = _i.dat.label;
    }

    xAddEventListener(_i.container, platform.mouseUp, cancelEvent, false);
  };

  _i.formatDivider = function () {
    _i.container.style.cursor = 'default';
    _i.container.style.height = '8px';
    _i.container.style.borderTop = '1px solid #E0E0E0';
    _i.container.style.margin = '4px 0px 1px 2px';
    xAddEventListener(_i.container, platform.mouseUp, cancelEvent, false);
  };

  _i.updateItem = function (dat) {
    if (_i.dat.label.nodeName) {
      removeAllChildren(_i.text);
      _i.text.appendChild(dat.label);
    } else {
      _i.text.innerHTML = _i.dat.escaped
        ? _i.dat.label
        : _.escape(_i.dat.label);
    }

    if (_i.icon && dat.icon) {
      newIcon = getSprite(compSpritesButtonsIcons[dat.icon]);
      newIcon.className = 'blockFloat';
      newIcon.style.marginTop =
        (parseInt(_i.container.style.height) -
          compSpritesButtonsIcons[dat.icon].height) /
          2 +
        'px';
      _i.inner.replaceChild(newIcon, _i.icon);

      _i.icon = newIcon;
    } else if (dat.iconURL) {
      newIcon = document.createElement('IMG');
      newIcon.src = _i.dat.iconURL;
      newIcon.style.width = '23px';
      newIcon.style.maxWidth = '23px';
      _i.iconCont.replaceChild(newIcon, _i.icon);

      _i.icon = newIcon;
    } else if (dat.iconColor) {
      newIcon = $('<div></div>').addClass('blockFloat').css({
        width: '23px',
        height: '23px',
        borderRadius: '3px',
        backgroundColor: dat.iconColor,
      })[0];
      _i.iconCont.replaceChild(newIcon, _i.icon);

      _i.icon = newIcon;
    }
  };
  _i.formatItem = function () {
    _i.container.style.cursor = _i.dat.isDisabledItem ? 'default' : 'pointer';
    if (_i.dat.label && _i.dat.label.nodeName) {
      _i.text.appendChild(_i.dat.label);
    } else if (_i.dat.label) {
      _i.text.innerHTML = _i.dat.escaped
        ? _i.dat.label
        : _.escape(_i.dat.label);
    } else {
      _i.container.style.float = 'center';
      _i.text.style.display = 'none';
    }

    _i.text.style.marginLeft = '6px'; // for IE9
    _i.text.style.marginTop = '1px';
    if (_i.dat.isDisabledItem) {
      _i.text.style.color = '#979797';
      _i.container.style.backgroundColor = 'inherit';
    }

    if (_i.multiselect) {
      if (_i.dat.isexclusive) {
        if (_i.dat.icon) {
          _i.icon = getSprite(compSpritesButtonsIcons[_i.dat.icon]);
          _i.icon.className = 'blockFloat multiSelectPopupIcon';
          _i.icon.style.marginTop =
            (_i.dat.iconMarginTop ||
              (parseInt(_i.container.style.height) -
                compSpritesButtonsIcons[_i.dat.icon].height) /
                2) + 'px';
          _i.inner.appendChild(_i.icon);
          xAddEventListener(
            _i.icon,
            platform.mouseUp,
            _i.contLabelClick,
            false
          );
        }
      } else {
        _i.text.style.marginTop = null;
        _i.text.style.marginLeft = null;
        _i.checkboxContainer = document.createElement('DIV');
        _i.checkboxContainer.className =
          'blockFloat multiSelectPopupIconContainer';
        _i.inner.appendChild(_i.checkboxContainer);
        _i.checkbox = document.createElement('DIV');
        _i.checkbox.className = 'blockFloat multiSelectPopupIcon';
        _i.checkboxContainer.appendChild(_i.checkbox);
        getSprite(compSpritesButtonsIcons.unselectedCheckbox, _i.checkbox);
        _i.separator = document.createElement('DIV');
        _i.separator.className = 'blockFloat multiSelectPopupSeperator';
        _i.inner.appendChild(_i.separator);
        if (_i.dat.icon) {
          _i.icon = getSprite(compSpritesButtonsIcons[_i.dat.icon]);
          _i.icon.className = 'blockFloat multiSelectPopupIcon';
          _i.icon.style.marginTop =
            (_i.dat.iconMarginTop ||
              (parseInt(_i.container.style.height) -
                compSpritesButtonsIcons[_i.dat.icon].height) /
                2) + 'px';
          _i.inner.appendChild(_i.icon);
          xAddEventListener(
            _i.icon,
            platform.mouseUp,
            _i.contLabelClick,
            false
          );
        }
      }
    } else if (_i.dat.icon) {
      _i.icon = getSprite(compSpritesButtonsIcons[_i.dat.icon]);
      _i.icon.className = 'blockFloat';
      _i.icon.style.marginTop =
        (_i.dat.iconMarginTop ||
          (parseInt(_i.container.style.height) -
            compSpritesButtonsIcons[_i.dat.icon].height) /
            2) + 'px';
      _i.inner.appendChild(_i.icon);
    } else if (_i.dat.iconURL || _i.dat.iconColor) {
      _i.iconCont = document.createElement('DIV');
      _i.iconCont.className = 'blockFloat';
      _i.iconCont.style.marginTop = '4px';
      _i.iconCont.style.width = '23px';
      _i.iconCont.style.height = '23px';
      _i.iconCont.style.overflow = 'hidden';
      _i.iconCont.style.borderRadius = '2px';
      _i.iconCont.style.webkitBorderRadius = '2px';
      _i.iconCont.style.mozBorderRadius = '2px';
      if (_i.dat.iconURL) {
        _i.icon = document.createElement('IMG');
        _i.icon.src = _i.dat.iconURL;
        _i.icon.style.width = '23px';
        _i.icon.style.maxWidth = '23px';
      } else if (_i.dat.iconColor) {
        _i.icon = $('<div></div>').addClass('blockFloat').css({
          width: '23px',
          height: '23px',
          backgroundColor: dat.iconColor,
        })[0];
      }
      _i.iconCont.appendChild(_i.icon);
      _i.inner.appendChild(_i.iconCont);
    }

    _i.dat.container = _i.container;

    if (!_i.dat.unselectable && !_i.dat.isDisabledItem) {
      if (_i.multiselect) {
        xAddEventListener(
          _i.checkboxContainer,
          platform.mouseUp,
          _i.contCheckboxClick,
          false
        );
        xAddEventListener(_i.text, platform.mouseUp, _i.contLabelClick, false);
      } else {
        xAddEventListener(_i.container, platform.mouseUp, _i.contClick, false);
        xAddEventListener(_i.container, 'keypress', _i.contKeypress, false);
      }
    } else {
      // eat the event
      xAddEventListener(_i.container, platform.mouseUp, cancelEvent, false);
    }
  };

  _i.formatMessage = function () {
    _i.container.style.cursor = 'default';
    if (_i.dat.icon && _i.dat.icon.nodeName) {
      _i.inner.style.display = 'flex';
      _i.inner.appendChild(_i.dat.icon);
      _i.text.style.marginLeft = '6px';
    }
    if (_i.dat.label && _i.dat.label.nodeName) {
      _i.text.appendChild(_i.dat.label);
    } else if (_i.dat.label) {
      _i.text.innerHTML = _i.dat.escaped
        ? _i.dat.label
        : _.escape(_i.dat.label);
    } else {
      _i.container.style.float = 'center';
      _i.text.style.display = 'none';
    }

    _i.container.style.whiteSpace = 'normal';
    _i.container.style.width = 'auto';
    _i.container.style.height = _i.dat.customPopupHeight ? '75px' : '55px';
    _i.container.style.lineHeight = '20px';
    _i.container.style.overflow = 'visible';
    _i.container.style.marginRight = '5px';
    if (_i.dat.customPopupHeightAndWidth) {
      _i.container.style.padding = '3px';
    }
    _i.text.style.whiteSpace = 'initial';
    _i.text.style.width = 'auto';
    _i.text.style.height = 'fit-content';
    _i.text.style.overflow = 'visible';

    _i.dat.container = _i.container;
  };

  _i.sizeLabel = function () {
    // size item labels only
    if (_i.dat.type != 'item') {
      return;
    }

    var w = xWidth(_i.inner) - xOffset(_i.text, _i.inner).x - 32;
    if (_i.multiselect) w += 25;
    xWidth(_i.text, w);
    var total = $(_i.text).outerWidth(true);
    if (_i.iconCont) total += $(_i.iconCont).outerWidth(true);
    else if (_i.icon) total += $(_i.icon).outerWidth(true);
    if (_i.checkboxContainer) total += $(_i.checkboxContainer).outerWidth(true);
    if (_i.separator) total += $(_i.separator).outerWidth(true);
    _i.inner.style.minWidth = total + 'px';
  };

  _i.showHoverState = function () {
    if (
      (_i.hover && !_i.dat.unselectable && !_i.dat.selected) ||
      _i.multiselect
    ) {
      _i.hover.show();
    }
  };

  _i.hideHoverState = function () {
    if (_i.hover) {
      _i.hover.hide();
    }
  };

  _i.containerMouseover = function (evt) {
    cancelEvent(evt);

    if (_i.dat.type != 'item') {
      return;
    }

    _i.mouseoverCallback(_i.index);
  };

  _i.containerMouseout = function (evt) {
    cancelEvent(evt);

    if (_i.dat.type != 'item') {
      return;
    }

    _i.mouseoutCallback(_i.index);
  };

  _i.mouseInOrFocus = function (evt) {
    cancelEvent(evt);
    _i.phasesAlertPopup.show(_i.container);
  };

  _i.mouseOutOrBlur = function (evt) {
    cancelEvent(evt);
    _i.phasesAlertPopup.hide();
  };

  _i.buildLabelDiv = function () {
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot unselectable';
    _i.container.style.margin = '0px';
    _i.container.style.marginBottom = '1px';
    _i.container.style.width = '100%';

    _i.inner = document.createElement('DIV');
    _i.inner.className = 'blockFloat';
    _i.inner.style.width = '100%';
    _i.inner.style.height = '100%';
    _i.inner.style.overflow = 'hidden';
    _i.container.style.whiteSpace = 'nowrap';

    _i.text = document.createElement('DIV');

    _i.formatLabel();
    if (_i.attachTo) {
      _i.attachTo.appendChild(_i.container);
    }

    _i.container.appendChild(_i.inner);
    _i.inner.appendChild(_i.text);
  };

  _i.constructor = function () {
    if (_i.dat.type == 'labeldiv') {
      _i.buildLabelDiv();
      return;
    }

    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot unselectable';
    _i.container.tabIndex = 0;
    _i.container.style.margin = '0px';
    _i.container.style.marginBottom = _i.multiselect ? '' : '1px';
    _i.container.style.color = '#444444';
    _i.container.style.whiteSpace = 'nowrap';
    _i.container.style.width = '100%';
    _i.container.style.height = _i.rowHeight + 'px';
    _i.container.style.lineHeight = _i.container.style.height;
    _i.container.style.overflow = 'hidden';
    if (_i.dat.title) {
      _i.container.title = _i.dat.title;
    } else {
      _i.container.title = _i.dat.label;
    }

    _i.bground = new backgroundBar(compSpritesButtonsIcons.menuSelect);
    _i.bground.hide();

    _i.hover = new backgroundBar(compSpritesButtonsIcons.menuHover);
    _i.hover.hide();

    _i.inner = document.createElement('DIV');
    _i.inner.className = 'blockFloat';
    _i.inner.style.width = '100%';
    _i.inner.style.height = '100%';
    _i.inner.style.overflow = _i.dat.isPhaseInfoMsg ? 'visible' : 'hidden';

    _i.text = document.createElement('DIV');
    if (_i.multiselect && _i.dat.type == 'item') {
      _i.text.className = 'blockFloat fnt-r-14 multiSelectPopupLabel ellipsis';
    } else {
      _i.inner.style.marginLeft = '10px';
      _i.text.style.width = '350px';
      var className = 'blockFloat fnt-r-14';
      if (!_i.dat.overrideParentEllipsis) className += ' ellipsis';
      _i.text.className = className;
    }
    if (_i.dat.type == 'label') {
      _i.formatLabel();
    }
    if (_i.dat.type == 'divider') {
      _i.formatDivider();
    }
    if (_i.dat.type == 'item') {
      _i.formatItem();
    }
    if (_i.dat.type == 'message') {
      _i.formatMessage();
    }

    if (_i.dat.selected) {
      _i.select();
    }

    if (_i.attachTo) {
      _i.attachTo.appendChild(_i.container);
    }

    _i.container.appendChild(_i.bground.container);
    _i.container.appendChild(_i.hover.container);
    _i.container.appendChild(_i.inner);
    _i.inner.appendChild(_i.text);

    if (_i.dat.type == 'item' && !_i.multiselect && !_i.dat.isDisabledItem) {
      xAddEventListener(_i.container, 'mouseover', _i.containerMouseover, true);
      xAddEventListener(_i.container, 'mouseout', _i.containerMouseout, true);
    }

    if (_i.dat.isDisabledItem && _i.dat.popupMessage) {
      _i.phasesAlertPopup = phasesAlertPopup(
        _i.dat.popupMessage,
        parentPopupContainer
      );

      xAddEventListener(_i.container, 'mouseover', _i.mouseInOrFocus, true);
      xAddEventListener(_i.container, 'focus', _i.mouseInOrFocus, true);
      xAddEventListener(_i.container, 'mouseout', _i.mouseOutOrBlur, true);
      xAddEventListener(_i.container, 'blur', _i.mouseOutOrBlur, true);
    }
  };

  _i.constructor();
}
