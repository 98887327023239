function SettingsMyPreferences(attachTo) {
  var _i = this;
  if (!(this instanceof SettingsMyPreferences)) {
    return new SettingsMyPreferences(attachTo);
  }

  _i.attachTo = xGetElementById(attachTo);
  _i.passwordFieldsContainer = null;
  _i.calendarLoading = false;

  _i.hidePasswordFields = function () {
    _i.passwordFieldsContainer.style.display = 'none';
    _i.passwordReset.container.style.display = 'block';
    // Clear passwords from input textboxes.
    $('input[type="password"').each(function (i) {
      $(this).val('');
    });
  };

  _i.showPasswordFields = function () {
    _i.passwordReset.container.style.display = 'none';
    _i.passwordFieldsContainer.style.display = 'block';
  };

  _i.toggleUserCalendar = function () {
    if (_i.calendarLoading) return;
    _i.calendarLoading = true;
    gService.updateUserCalendar({ cmd: 'toggle' }, _i.renderCalendarHTML);
  };

  _i.enableCalendarButtonClick = function () {
    var confirmEnable = primaryConfirmationOnlyPopup(
      I18n.t('msg_calendar_subscription_privacy'),
      I18n.t('lbl_i_understand'),
      _i.toggleUserCalendar,
      null,
      null,
      null,
      150,
      260 * window.rm.l10n.getWidthMultiplier()
    );
    confirmEnable.show(_i.registerButton.container);
  };

  _i.rotateUserCalendar = function () {
    if (_i.calendarLoading) return;
    _i.calendarLoading = true;
    gService.updateUserCalendar({ cmd: 'rotate' }, _i.renderCalendarHTML);
  };

  _i.signinResponseCallback = function (data) {
    _i.hidePasswordFields();
    if (data.signed_in) {
      setCookiesAfterSignIn(data);
      clearSessionStorage();
    } else {
      window.location.href = getBaseURL() + 'signin';
    }
  };

  _i.passwordSaved = function () {
    var user = gData.getMe();
    gService.signin(
      user.username || user.email,
      _i.password.getValue(),
      user.id,
      null,
      _i.signinResponseCallback
    );
  };

  _i.passwordSaveFailed = function () {
    _i.passwordCurrent.setAlert(I18n.t('err_password_incorrect'));
  };

  _i.savePassword = function () {
    if (_i.password.getValue() != _i.passwordConfirm.getValue()) {
      _i.password.setAlert(I18n.t('err_password_mismatch'));
      return;
    }
    var user = gData.getMe();
    userdata = {};
    userdata.id = user.id;
    userdata.newPassword = _i.password.getValue();
    userdata.currentPassword = _i.passwordCurrent.getValue();
    gService.updateUser(userdata, _i.passwordSaved, _i.passwordSaveFailed);
  };

  _i.initPasswordFields = function () {
    if (_i.passwordFieldsContainer == null) {
      _i.passwordFieldsContainer = document.createElement('DIV');
      _i.passwordFieldsContainer.className = 'blockFloatNot';
      _i.passwordFieldsContainer.id = 'passwordFieldsContainer';
      _i.passwordFieldsContainer.style.display = 'none';
      _i.container.appendChild(_i.passwordFieldsContainer);
    }
    _i.passwordCurrent = new PasswordInput(
      'passwordFieldsContainer',
      I18n.t('lbl_current_password'),
      '',
      'blockFloatNot settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageInputSmall'
    );

    _i.password = new PasswordInput(
      'passwordFieldsContainer',
      I18n.t('lbl_new_password'),
      '',
      'blockFloatNot settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageInputSmall'
    );

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloat settingsSpacer1';
    _i.passwordFieldsContainer.appendChild(spacer);

    _i.passwordConfirm = new PasswordInput(
      'passwordFieldsContainer',
      I18n.t('lbl_confirm_new_password'),
      '',
      'blockFloat settingsPageEditInputContainerSmall',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageInputSmall'
    );

    _i.passwordlabelDiv = document.createElement('DIV');
    _i.passwordlabelDiv.className =
      'blockFloatNot fnt-r-13 settingsPagePasswordLabel';
    _i.passwordlabelDiv.innerHTML = I18n.t('msg_password_suggestion');
    _i.passwordFieldsContainer.appendChild(_i.passwordlabelDiv);

    var spacer = document.createElement('DIV');
    spacer.className = 'blockFloatNot settingsSpacerImage1';
    spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
    _i.passwordFieldsContainer.appendChild(spacer);

    _i.nestedDiv = document.createElement('DIV');
    _i.nestedDiv.className = 'floatRightDiv';
    _i.passwordFieldsContainer.appendChild(_i.nestedDiv);

    _i.cancelButton = new secondaryButton('settingsSecondaryButtonRight');
    _i.cancelButton.setButtonText(I18n.t('lbl_cancel'));
    _i.cancelButton.setCallback(_i.hidePasswordFields);
    _i.nestedDiv.appendChild(_i.cancelButton.container);
    _i.cancelButton.container.tabIndex = 0;

    _i.saveButton = new secondaryButton('settingsSecondaryButtonRight');
    _i.saveButton.setButtonText(I18n.t('lbl_save'));
    _i.saveButton.setCallback(_i.savePassword);
    _i.nestedDiv.appendChild(_i.saveButton.container);
    _i.saveButton.container.tabIndex = 0;
  };

  _i.initNotificationPreferences = function (enabled, callback) {
    // section header
    var label = document.createElement('H3');
    label.className = 'blockFloatNot fnt-b-14 settingsTimeTrackingLabel';
    if (!enabled) label.className += ' settingsDisabled';
    label.innerHTML = I18n.t('lbl_notification_preferences');
    _i.container.appendChild(label);
    var sublabel = document.createElement('DIV');
    sublabel.className =
      'blockFloatNot fnt-r-12 settingsNotificationsInfoLabel';

    sublabel.innerHTML = enabled
      ? I18n.t('msg_email_notification_preference')
      : I18n.t('msg_email_notification_preference_disabled');
    _i.container.appendChild(sublabel);

    if (!enabled) return callback();

    var moreInfoLabel = document.createElement('DIV');
    moreInfoLabel.className =
      'blockFloatNot fnt-r-12 settingsNotificationsMoreInfoLabel';
    moreInfoLabel.innerHTML = `<a target="_blank" href="https://help.smartsheet.com/articles/2481311">${I18n.t(
      'lbl_learn_more_lc'
    )}</a>`;
    _i.container.appendChild(moreInfoLabel);

    _i.notificationEmailSelections = {};

    if (window.ENS_IS_DOWN) return callback();

    gService.getUserNotificationSettings(function (settings) {
      if (!settings) return callback();
      createUIControls(settings);
      return callback();
    });

    var toggleNotificationSettings = function (status) {
      Object.keys(_i.notificationEmailSelections).forEach(function (key) {
        _i.notificationEmailSelections[key].setEnabled(key === status);
      });
      // Clients timezone utc offset
      var offset = new Date().getTimezoneOffset();
      gService.updateUserNotificationSettings({
        status: status,
        offset: offset,
      });
    };

    var createUIControls = function (settings) {
      var enabled = settings.notifications_enabled == true;
      var frequency = settings.send_notifications || 'daily';
      var radialControl = document.createElement('DIV');
      radialControl.style.cssText = 'margin-top: 20px;';

      _i.notificationEmailSelections['off'] = new SelectionToggle(
        radialControl,
        _i.buildPlainTextSpan(I18n.t('lbl_off')),
        enabled == false,
        'blockFloatNot settingsNotificationSelectorItem',
        'fnt-r-15 settingsSelectorText',
        'settingsSelectorIcon'
      );
      // --> "Do not send me email notifications"
      _i.notificationEmailSelections['off'].setCallback(function () {
        toggleNotificationSettings('off');
      });
      _i.notificationEmailSelections['off'].inputContainer.appendChild(
        document.createElement('br')
      );
      _i.notificationEmailSelections['off'].inputContainer.appendChild(
        _i.buildSelectorTextSpan(I18n.t('msg_email_notifications_off'))
      );

      _i.notificationEmailSelections['immediate'] = new SelectionToggle(
        radialControl,
        _i.buildPlainTextSpan(I18n.t('lbl_immediately')),
        enabled && frequency === 'immediate',
        'blockFloatNot settingsNotificationSelectorItem',
        'fnt-r-15 settingsSelectorText',
        'settingsSelectorIcon'
      );
      // --> "Email me at most once a day with any changes"
      _i.notificationEmailSelections['immediate'].setCallback(function () {
        toggleNotificationSettings('immediate');
      });
      _i.notificationEmailSelections['immediate'].inputContainer.appendChild(
        document.createElement('br')
      );
      _i.notificationEmailSelections['immediate'].inputContainer.appendChild(
        _i.buildSelectorTextSpan(I18n.t('msg_email_notifications_immediately'))
      );

      _i.notificationEmailSelections['daily'] = new SelectionToggle(
        radialControl,
        _i.buildPlainTextSpan(I18n.t('lbl_daily_default')),
        enabled && frequency === 'daily',
        'blockFloatNot settingsNotificationSelectorItem',
        'fnt-r-15 settingsSelectorText',
        'settingsSelectorIcon'
      );
      // --> "Email me at most once a day with any changes"
      _i.notificationEmailSelections['daily'].setCallback(function () {
        toggleNotificationSettings('daily');
      });
      _i.notificationEmailSelections['daily'].inputContainer.appendChild(
        document.createElement('br')
      );
      _i.notificationEmailSelections['daily'].inputContainer.appendChild(
        _i.buildSelectorTextSpan(I18n.t('msg_email_notifications_daily'))
      );

      // _i.notificationEmailSelections["weekly"] = new SelectionToggle(radialControl,
      //   _i.buildPlainTextSpan("Weekly"),
      //   enabled && frequency === "weekly",
      //   "blockFloatNot settingsNotificationSelectorItem",
      //   "fnt-r-15 settingsSelectorText",
      //   "settingsSelectorIcon"
      // )
      // --> "Email me a weekly summary with any changes"
      // _i.notificationEmailSelections["weekly"].setCallback(function(){ toggleNotificationSettings("weekly"); });
      // _i.notificationEmailSelections["weekly"].inputContainer.appendChild(document.createElement("br"));
      // _i.notificationEmailSelections["weekly"].inputContainer.appendChild(_i.buildSelectorTextSpan("Email me a weekly summary with any changes"))

      _i.container.appendChild(radialControl);
    };
  };

  _i.initCalendarPreferences = function (enabled) {
    if (_i.calendarContainer == null) {
      _i.calendarContainer = document.createElement('DIV');
      _i.calendarContainer.className = 'blockFloatNot';
      _i.calendarContainer.id = 'calendarContainer';
      _i.container.appendChild(_i.calendarContainer);
    }
    // section header
    var label = document.createElement('H3');
    label.className = 'blockFloatNot fnt-b-14 settingsTimeTrackingLabel';
    if (!enabled) label.className += ' settingsDisabled';
    label.innerHTML = I18n.t('lbl_calendar_subscription');
    _i.calendarContainer.appendChild(label);
    var sublabel = document.createElement('DIV');
    sublabel.className =
      'blockFloatNot fnt-r-12 settingsNotificationsInfoLabel';

    sublabel.innerHTML = enabled
      ? I18n.t('msg_calendar_subscription_setup')
      : I18n.t('msg_calendar_subscription_disabled');
    _i.calendarContainer.appendChild(sublabel);

    if (!enabled) return;

    var moreInfoLabel = document.createElement('DIV');
    moreInfoLabel.className =
      'blockFloatNot fnt-r-12 settingsNotificationsMoreInfoLabel';
    moreInfoLabel.innerHTML =
      '<a target="_blank" href="https://help.smartsheet.com/articles/2480881">' +
      I18n.t('lbl_learn_more_lc') +
      '</a>';
    _i.calendarContainer.appendChild(moreInfoLabel);

    _i.calendarInactiveContainer = document.createElement('DIV');
    _i.calendarInactiveContainer.className = 'blockFloatNot';
    _i.calendarInactiveContainer.id = 'calendarInactiveContainer';

    _i.registerButton = new primaryButton('blockFloatNot');
    _i.registerButton.setButtonText(I18n.t('lbl_enable'));
    _i.registerButton.setCallback(_i.enableCalendarButtonClick);

    _i.calendarInactiveContainer.appendChild(_i.registerButton.container);
    _i.calendarContainer.appendChild(_i.calendarInactiveContainer);

    _i.calendarActiveContainer = document.createElement('DIV');
    _i.calendarActiveContainer.className = 'blockFloatNot';
    _i.calendarActiveContainer.id = 'calendarActiveContainer';

    _i.calendarInput = new TextInput(
      _i.calendarActiveContainer,
      I18n.t('lbl_calendar_subscription_url'),
      '',
      ' ',
      'blockFloatNot fnt-r-13 settingsPageTextInputLabel',
      'blockFloatNot fnt-r-15 settingsPageTextInputLarge'
    );

    _i.calendarInput.inputText.readOnly = true;

    var unregisterButton = new destructiveButton(
      'blockFloat spritesButtonTextContainerWide closeaccountbutton'
    );
    unregisterButton.container.style = 'margin-top:10px;';
    unregisterButton.setButtonText(I18n.t('lbl_disable'));
    unregisterButton.setCallback(_i.toggleUserCalendar);

    _i.calendarActiveContainer.appendChild(unregisterButton.container);

    var rotateButton = new secondaryButton(
      'blockFloat spritesButtonTextContainerWide'
    );
    rotateButton.container.style = 'margin-top:10px; margin-left:10px;';
    rotateButton.setButtonText(I18n.t('lbl_reset_url'));
    rotateButton.setCallback(_i.rotateUserCalendar);

    _i.calendarActiveContainer.appendChild(rotateButton.container);

    _i.calendarContainer.appendChild(_i.calendarActiveContainer);
    _i.calendarInactiveContainer.style.display = 'none';
    _i.calendarActiveContainer.style.display = 'none';
    gService.findUserCalendar(function (calendar) {
      if (!calendar || !calendar.guid)
        gService.createUserCalendar({ active: false }, _i.renderCalendarHTML);
      else _i.renderCalendarHTML(calendar);
    });
  };

  _i.renderCalendarHTML = function (calendar) {
    _i.calendarLoading = false;
    if (!calendar || !calendar.guid || calendar.active === false) {
      _i.calendarInactiveContainer.style.display = 'block';
      _i.calendarActiveContainer.style.display = 'none';
    } else {
      _i.calendarInactiveContainer.style.display = 'none';
      _i.calendarActiveContainer.style.display = 'block';

      var calendarUrl = API_BASE_URL + '/ics/' + calendar.guid;
      _i.calendarInput.setValue(calendarUrl);
    }
  };

  _i.buildSelectorTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'settingsSelectorSpanText';
    ret.innerText = str;
    return ret;
  };

  _i.buildPlainTextSpan = function (str) {
    var ret = document.createElement('SPAN');
    ret.className = 'fnt-r-15';
    ret.innerText = str;
    return ret;
  };

  _i.initTermsOfServiceSection = function () {
    var tosContainer = document.createElement('DIV'),
      tosTitle = document.createElement('H3'),
      tosBody = document.createElement('DIV');

    tosTitle.innerHTML = I18n.t('lbl_terms_of_service_privacy_policy');
    tosTitle.className = 'blockFloatNot fnt-b-14 settingsTimeTrackingLabel';

    tosBody.innerHTML = I18n.t('msg_suppport_page_html');
    tosBody.className = 'blockFloatNot fnt-r-12 settingsNotificationsInfoLabel';

    tosContainer.insertAdjacentElement('beforeEnd', tosTitle);
    tosContainer.insertAdjacentElement('beforeEnd', tosBody);

    _i.container.insertAdjacentElement('afterend', tosContainer);
  };

  _i.initAuthorizedApplicationsSection = function () {
    var authorizedAppsContainer = document.createElement('DIV');
    authorizedAppsContainer.id = 'authorizedAppsContainer';

    var title = document.createElement('H3');
    title.innerHTML = I18n.t('lbl_applications_with_account_access');
    title.className = 'blockFloatNot fnt-b-14 settingsTimeTrackingLabel';

    authorizedAppsContainer.insertAdjacentElement('beforeEnd', title);
    _i.container.insertAdjacentElement('afterend', authorizedAppsContainer);
    AuthorizedApplications(authorizedAppsContainer.id);
  };

  _i.constructor = function () {
    _i.container = document.createElement('DIV');
    _i.container.className =
      'blockFloatNot settingsMyPreferencesContentContainer';
    _i.container.id = 'settingsMyPreferencesContentContainer';
    _i.attachTo.appendChild(_i.container);

    if (!window.isIframeView) {
      var spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot settingsSpacer3';
      _i.container.appendChild(spacer);
    }

    if (!gData.accountSettings.moduleEnabled('sso')) {
      // sso enabled accounts do not get login / password management features

      _i.label = document.createElement('H3');
      _i.label.className = 'blockFloatNot fnt-r-14 settingsMyPrefLoginLabel';
      _i.label.innerHTML = I18n.t('lbl_login_password');
      _i.container.appendChild(_i.label);

      var user = gData.getMe();
      _i.login_username = new TagView(
        _i.container,
        I18n.t('lbl_login'),
        user.email,
        'blockFloatNot',
        'fnt-r-12 settingsPrefTag',
        'fnt-r-14 settingsPrefName'
      );

      _i.passwordReset = new secondaryButton(
        'blockFloatNot settingsSecondaryButton'
      );
      _i.passwordReset.setButtonText(I18n.t('lbl_change_my_password'));
      _i.passwordReset.setCallback(_i.showPasswordFields);
      _i.container.appendChild(_i.passwordReset.container);
      _i.initPasswordFields();
    }
    if (!window.isIframeView) {
      // Notifications enabled accounts have additional settings they can control within their preferences.
      _i.initNotificationPreferences(
        organizationNotificationsEnabled(),
        function () {
          _i.initCalendarPreferences(
            gData.accountSettings.calendars_enabled == '1'
          );
        }
      );
    }

    _i.initTermsOfServiceSection();

    if (gData.accountSettings.moduleEnabled('smartsheet integration')) {
      _i.initAuthorizedApplicationsSection();
    }
  };

  _i.constructor();
}
