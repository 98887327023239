function phasesAlertPopup(prompt, parentPopupContainer) {
  if (!(this instanceof phasesAlertPopup)) {
    return new phasesAlertPopup(prompt, parentPopupContainer);
  }

  const WIDTH = 245;
  const HEIGHT = 45;
  const MESSAGE_AREA_PADDING = 7;
  const MESSAGE_AREA_HEIGHT = 12;
  const TRANSITION_DURATION = 225;
  const TRANSITION_DELAY = 1000;
  const HIDE_DELAY = TRANSITION_DURATION + TRANSITION_DELAY;

  const _i = this;

  _i.popupContainer;
  _i.content;
  _i.timeoutId;

  _i.hide = function () {
    _i.popupContainer.container.style.transitionDelay = TRANSITION_DELAY + 'ms';
    _i.popupContainer.container.style.opacity = 0;
    _i.timeoutId = setTimeout(() => {
      _i.popupContainer.hide();
    }, HIDE_DELAY);
  };

  _i.show = function (attachToElement, xOffset, yOffset) {
    clearTimeout(_i.timeoutId);
    _i.popupContainer.pointAtElement(attachToElement, xOffset, yOffset);
    _i.popupContainer.container.style.transitionDelay = 0 + 'ms';
    _i.popupContainer.container.style.opacity = 1;
  };

  // function used to get the number of lines of text so we can adjust the height of the popup
  function getNumberOfLines(text, width, fontSize, lineHeight) {
    // Create a temporary element
    const tempElement = document.createElement('DIV');
    tempElement.style.width = width + 'px';
    tempElement.style.fontSize = fontSize + 'px';
    tempElement.style.lineHeight = lineHeight + 'px';
    tempElement.style.visibility = 'hidden';
    tempElement.style.position = 'absolute';
    tempElement.style.whiteSpace = 'pre-wrap'; // Ensure text wraps
    tempElement.style.overflow = 'visible';
    tempElement.style.maxHeight = 'none';
    tempElement.style.padding = MESSAGE_AREA_PADDING + 'px';
    tempElement.innerHTML = text;

    // Append the temporary element to the body
    document.body.appendChild(tempElement);
    // Measure the height of the text content
    const contentHeight = tempElement.offsetHeight;
    // Remove the temporary element from the body
    document.body.removeChild(tempElement);
    // Calculate the number of lines
    const numberOfLines = Math.floor(contentHeight / lineHeight);

    return numberOfLines;
  }

  function getHeight(numberOfLines) {
    let height = 45;
    if (numberOfLines >= 5) {
      height += 42;
    } else if (numberOfLines === 4) {
      height += 30;
    } else if (numberOfLines === 3) {
      height += 10;
    }
    return height;
  }

  function init() {
    const spec = parentPopupContainer
      ? compSpritesButtonsIcons.wideDialogSpecsGray
      : compSpritesButtonsIcons.wideDialogSpecs;

    const viewport = document.getElementById('mainCon');

    _i.content = document.createElement('DIV');
    _i.content.style.width = WIDTH + 'px';
    _i.content.style.height = HEIGHT + 'px';

    const message = document.createElement('DIV');
    message.className = 'blockFloatNot fnt-r-14';
    message.innerHTML = prompt;
    message.style.width = WIDTH - MESSAGE_AREA_PADDING * 2 + 'px';
    message.style.height =
      MESSAGE_AREA_HEIGHT - MESSAGE_AREA_PADDING * 2 + 'px';
    message.style.padding = MESSAGE_AREA_PADDING + 'px';
    message.style.fontSize = '12px';
    message.style.lineHeight = '16px';
    _i.content.appendChild(message);
    const textContent = message.textContent || message.innerText;
    const numberOfLines = getNumberOfLines(
      textContent,
      WIDTH - MESSAGE_AREA_PADDING * 2,
      12,
      16
    );
    const popupHeight = getHeight(numberOfLines);

    _i.popupContainer = new popupContainer(
      spec,
      WIDTH,
      popupHeight,
      3, // innerMargin
      true, // autoOrient
      'sc', // defaultDirection
      viewport, // dialogViewport
      ['sc', 'sw'], // preferredDirections
      null, // blurCallback
      parentPopupContainer,
      true // hidePointer
    );

    _i.popupContainer.container.style.opacity = 0;
    _i.popupContainer.container.style.zIndex = 1000;
    _i.popupContainer.container.style.transitionProperty = 'opacity';
    _i.popupContainer.container.style.transitionTimingFunction = 'ease';
    _i.popupContainer.container.style.transitionDuration =
      TRANSITION_DURATION + 'ms';
    _i.popupContainer.container.style.transitionDelay = 0 + 'ms';

    _i.popupContainer.attachContent(_i.content);
  }

  init();
}
