var gData = null;

var AVAILABILITY_SINCE = Date.addDays(
  new Date(),
  QueryString.all_availability == 1 ? -600 : -60
).getTime();

// dayCount = number of working days each week (excluding holiday closures which are seperate)
// workingDays = array of the seven days (0 - 6 for Sunday - Saturday) set to true if that's a working day, false otherwise
// workdayIndices = array of the days that are working days. Length is equal to workWeek.dayCount and each entry orders the weekdays of the workweek
//  for example a restaurant open Tuesday through Sunday would contain [2, 3, 4, 5, 6, 0]
function WorkWeek() {
  var _i = this,
    _hoursInWorkDay = null;

  _i._isWorkdayCache_1 = {}; // treatCompanyHolidayAsWorkday
  _i._isWorkdayCache_2 = {}; // not treatCompanyHolidayAsWorkday

  _i.isHoliday = function (d) {
    return gData.accountSettings.holidaysByDate[d.toDateString()] != null;
  };

  _i.isWorkday = function (d, treatCompanyHolidayAsWorkday) {
    var ci = d.getTime(); // cache index
    var result = treatCompanyHolidayAsWorkday
      ? _i._isWorkdayCache_1[ci]
      : _i._isWorkdayCache_2[ci];

    if (result != undefined) {
      return result;
    } else {
      result = false;

      if (_i.workingDays[d.getDay()]) {
        if (treatCompanyHolidayAsWorkday || !_i.isHoliday(d)) {
          result = true;
        }
      }

      if (treatCompanyHolidayAsWorkday) _i._isWorkdayCache_1[ci] = result;
      else _i._isWorkdayCache_2[ci] = result;

      return result;
    }
  };

  // decrements a date to midnite of the NEXT day
  // so a project ending on Friday has and end date
  // of Sat 00:00:00
  _i.trimEndDateToWorkday = function (d) {
    d = _i.lastWorkDayBefore(d, false);
    d.toStartOfDay().addDays(1);
    return d;
  };

  _i.firstWorkdayOfWeek = function (d) {
    var given = d,
      previous,
      i = 0,
      MAX_DAYS_BACK = 7;

    d = Date.toStartOfDay(d);
    while (i < MAX_DAYS_BACK && !_i.isWorkday(d, true)) {
      d = d.addDays(-1);
      i++;
    }

    previous = Date.addDays(d, -1);

    while (i < MAX_DAYS_BACK && _i.isWorkday(previous, true)) {
      d = previous;
      i++;
      previous = Date.addDays(previous, -1);
    }

    return i <= MAX_DAYS_BACK ? d : Date.toStartOfWeek(Date.addDays(given, -1));
  };

  _i.lastWorkDayBeforeEx = function (d, treatCompanyHolidayAsWorkday) {
    d = new Date(d);
    do {
      d.addDays(-1);
    } while (!_i.isWorkday(d, treatCompanyHolidayAsWorkday));
    return d;
  };

  // todo - remove after migrating all code to lastWorkDayBeforeEx
  _i.lastWorkDayBefore = function (d, treatCompanyHolidayAsWorkday) {
    // bug
    treatCompanyHolidayAsWorkday = treatCompanyHolidayAsWorkday || true;

    d = new Date(d);
    do {
      d.addDays(-1);
    } while (!_i.isWorkday(d, treatCompanyHolidayAsWorkday));
    return d;
  };

  _i.nextWorkDayInclusiveEx = function (d, treatCompanyHolidayAsWorkday) {
    d = new Date(d);
    while (!_i.isWorkday(d, treatCompanyHolidayAsWorkday)) {
      d.addDays(1);
    }
    return d;
  };

  // todo - remove after migrating all code to nextWorkDayInclusiveEx
  _i.nextWorkDayInclusive = function (d, treatCompanyHolidayAsWorkday) {
    // bug
    treatCompanyHolidayAsWorkday = treatCompanyHolidayAsWorkday || true;

    d = new Date(d);
    while (!_i.isWorkday(d, treatCompanyHolidayAsWorkday)) {
      d.addDays(1);
    }
    return d;
  };

  _i.advanceWorkdays = function (d, count, includeCompanyHolidays) {
    var day = d.getDay();
    if (!_i.workingDays) {
      throw "Can't advance from non working day";
    }
    var delta = count > 0 ? 1 : -1;
    count = Math.round(Math.abs(count));
    if (!includeCompanyHolidays) {
      var holidaysByDate = gData.accountSettings.holidaysByDate;
    }
    while (count > 0) {
      d.setDate(d.getDate() + delta);
      // If this is a working weekday
      if (_i.workingDays[d.getDay()]) {
        // And not a company holiday
        if (includeCompanyHolidays || !holidaysByDate[d.toDateString()]) {
          // Decrement the counter
          --count;
        }
      }
    }
  };

  // deperecated. use Date.calendarDayDiff instead.
  _i.calendarDayDiff = function (a, b) {
    return Date.calendarDayDiff(a, b);
  };

  // Return the difference between two days in number of workdays
  _i.workdayDiff = function (a, b, inclusive) {
    if (a.getTime() > b.getTime()) {
      return -_i.workdayDiff(b, a, inclusive);
    }
    if (inclusive) {
      b = new Date(b);
      b.setDate(b.getDate() + 1);
    }
    var totalDays = Date.calendarDayDiff(a, b);
    var weeks = Math.floor(totalDays / 7);
    // Calculate the workdays in the remaining time
    remainder = 0;
    var d = new Date(a);
    d.setDate(d.getDate() + 7 * weeks);
    while (!Date.sameDay(d, b)) {
      if (_i.workingDays[d.getDay()]) {
        ++remainder;
      }
      d.setDate(d.getDate() + 1);
      if (d.getTime() > b.getTime()) {
        break;
      }
    }
    var holidayCount = 0;
    var holidays = gData.accountSettings.holidays;
    for (var i = 0; i < holidays.length; ++i) {
      var h = holidays[i];
      var hEnd = new Date(h.date);
      hEnd.setDate(hEnd.getDate() + 1);
      // Don't count holidays that fall on a weekend
      if (!isWeekend(h.date) && dateRangesOverlap(a, b, h.date, hEnd)) {
        ++holidayCount;
      }
    }
    return weeks * _i.dayCount + remainder - holidayCount;
  };

  _i.getHoursInWorkWeek = function () {
    return gData.accountSettings.hours_in_workday * _i.dayCount;
  };

  _i.getHoursInWorkday = function () {
    _hoursInWorkDay == null &&
      (_hoursInWorkDay = Number(gData.accountSettings.hours_in_workday));
    return _hoursInWorkDay;
  };

  _i.getWorkdaysInMonth = function (d) {
    var workdays = [];
    var holidaysByDate = gData.accountSettings.holidaysByDate;
    d = new Date(d);
    d.toStartOfDay();
    d.setDate(1);
    var month = d.getMonth();
    do {
      var date = d.getDate();
      if (_i.workingDays[d.getDay()]) {
        // If not a company holiday
        if (!holidaysByDate[d.toDateString()]) {
          workdays.push(date);
        }
      }
      d.setDate(d.getDate() + 1);
    } while (d.getMonth() == month);

    return workdays;
  };

  _i.constructor = function () {
    _i.workingDays = [];
    _i.dayCount = 0;
    _i.workdayIndices = [];
    var mask = _i.dayMask;
    var i;
    for (i = 0; i < 7; ++i) {
      _i.dayCount += mask & 1;
      _i.workingDays.push((mask & 1) == 1);
      mask >>= 1;
    }
    for (i = _i.firstDay; i < _i.firstDay + 7; ++i) {
      var index = i % 7;
      if (_i.workingDays[index]) {
        _i.workdayIndices.push(index);
      }
    }
  };

  _i.constructor();
}

function Assignable() {
  var _i = this;

  _i.participants = [];

  _i.setParticipants = function (participants) {
    _i.participants = participants;
  };

  _i.getParticipants = function () {
    return _i.participants;
  };
}

function LeaveType() {
  Assignable.call(this);
}

function Project() {
  var _i = this;

  Assignable.call(this);

  // Updated version: takes vacation/internal/other leave into account
  _i.getUtilization = function (assignments, workWeek) {
    var hours = 0;
    // This should be a hash look up not a loop through the array
    for (var i = 0; i < assignments.length; ++i) {
      var a = assignments[i];
      if (a.assignable_id == _i.id) {
        var user = gData.getUser(a.user_id);
        hours +=
          user.getDaysOnAssignment(a) * gData.accountSettings.hours_in_workday;
      }
    }
    var u = 1;
    if (_i.resource_hours) {
      u = hours / _i.resource_hours;
    }
    return u;
  };

  _i.hasPendingUpdates = function () {
    var result = false;
    if (_i.has_pending_updates) {
      result = true;
    } else {
      for (var i = 0; !result && i < _i.children.length; i++) {
        if (_i.children[i].hasPendingUpdates()) {
          result = true;
        }
      }
    }
    return result;
  };

  _i.getIdsOfSelfAndChildren = function () {
    var ids = [_i.id];
    if (_i.children && _i.children.length) {
      for (var i = 0; i < _i.children.length; i++) {
        ids.push(_i.children[i].id);
      }
    }
    return ids;
  };
}

function AccountSettings() {
  var _i = this;

  _i.holidaysByDate = {};

  _i.canUserEditModel = function (user, model, modelUserId) {
    for (var i = 0; i < gData.accountSettings.model_access_rules.length; ++i) {
      var rule = gData.accountSettings.model_access_rules[i];
      if (rule.user_type_id == user.user_type_id && rule.model == model) {
        if (
          rule.allow_create == 'all' &&
          rule.allow_update == 'all' &&
          rule.allow_destroy == 'all'
        )
          return true;
        else if (
          rule.allow_create == 'mine' &&
          rule.allow_update == 'mine' &&
          rule.allow_destroy == 'mine' &&
          user.id == modelUserId
        )
          return true;
      }
    }
    return false;
  };

  _i.canUserReadModel = function (user, model, modelUserId) {
    for (var i = 0; i < gData.accountSettings.model_access_rules.length; ++i) {
      var rule = gData.accountSettings.model_access_rules[i];
      if (rule.user_type_id == user.user_type_id && rule.model == model) {
        if (rule.allow_read == 'all') return true;
        else if (rule.allow_read == 'mine' && user.id == modelUserId)
          return true;
      }
    }
    return false;
  };

  _i.canUserPerformCustomAction = function (user, model, customAction) {
    for (var i = 0; i < gData.accountSettings.model_access_rules.length; ++i) {
      var rule = gData.accountSettings.model_access_rules[i];
      if (rule.user_type_id == user.user_type_id && rule.model == model) {
        if (
          rule.custom_actions &&
          rule.custom_actions.indexOf(customAction) >= 0
        ) {
          return true;
        }
      }
    }
    return false;
  };

  _i.reportLevel = function (user) {
    for (var i = 0; i < gData.accountSettings.model_access_rules.length; ++i) {
      var rule = gData.accountSettings.model_access_rules[i];
      if (rule.user_type_id == user.user_type_id && rule.model == 'Report') {
        return rule.allow_read;
      }
    }
    return 'none';
  };

  _i.getHolidayInfo = function (d) {
    return _i.holidaysByDate[d.toDateString()];
  };

  _i.moduleEnabled = function (m) {
    if (!_i.modules_enabled_by_name) {
      // Build the modules enabled lookup table.
      _i.modules_enabled_by_name = {};
      for (var i = 0; i < _i.modules_enabled.length; ++i) {
        var tag = _i.modules_enabled[i];
        _i.modules_enabled_by_name[tag.name] = tag.value > 0;
      }
    }

    if (Object.prototype.hasOwnProperty.call(_i.modules_enabled_by_name, m)) {
      return _i.modules_enabled_by_name[m];
    }
    return false;
  };

  _i.moduleEnabledValue = function (m) {
    if (!_i.modules_enabled_value_by_name) {
      // Build the modules enabled lookup table.
      _i.modules_enabled_value_by_name = {};
      for (var i = 0; i < _i.modules_enabled.length; ++i) {
        var tag = _i.modules_enabled[i];
        _i.modules_enabled_value_by_name[tag.name] = tag.value;
      }
    }

    if (
      Object.prototype.hasOwnProperty.call(_i.modules_enabled_value_by_name, m)
    ) {
      return _i.modules_enabled_value_by_name[m];
    }
    return false;
  };

  _i.constructor = function () {
    _i.activation_date = parseRubyDate(_i.activation_date);
    for (var i = 0; i < _i.holidays.length; ++i) {
      var h = _i.holidays[i];
      h.date = parseRubyDate(h.date);
      _i.holidaysByDate[h.date.toDateString()] = h;
    }
  };

  _i.constructor();
}

function HistoryLog() {
  var _i = this;

  _i.constructor = function () {
    _i.log_date = parseRubyDate(_i.updated_at);
    _i.log_action = _i.action_name;
    _i.log_objectOfAction = _i.object_of_action;
    _i.log_objectOfActionId = _i.object_of_action_id;

    if (_i.log_objectOfActionId == '' || _i.log_objectOfActionId == null) {
      _i.log_action = 'ignore';
    }

    _i.log_data = JSON.parse(_i.data);
    if (_i.log_data && 'assignment' in _i.log_data) {
      _i.log_assignment = gData.getAssignableOrChild(
        _i.log_data.assignment.assignable_id,
        false
      );
    }

    // verify this actually modified some data if an update
    if (_i.log_action == 'update' && _i.log_objectOfAction == 'assignment') {
      var check_ignore = true;
      var has_starts_in = false;
      var has_ends_in = false;
      for (var prop in _i.log_data) {
        if (prop == 'starts_at') has_starts_in = true;
        else if (prop == 'ends_at') has_ends_in = true;
        else check_ignore = false;
      }

      if (check_ignore && has_starts_in && has_ends_in) {
        if (
          _i.log_data.ends_at[0] == _i.log_data.ends_at[1] &&
          _i.log_data.starts_at[0] == _i.log_data.starts_at[1]
        ) {
          _i.log_action = 'ignore';
        }
      }
    }
  };

  _i.constructor();
}

function User() {
  var _i = this,
    _availabilityCache = {},
    _hours_in_workday_cached,
    _cachedStartDate = null,
    _cachedEndDate = null;

  _i.getCurrentProjects = function () {
    // Inclusive dates
    var start = new Date();
    var end = new Date();
    end.setDate(end.getDate() + 1);
    start.toStartOfDay();
    end.toStartOfDay();

    var ret = [];

    for (var i = 0; _i.assignments && i < _i.assignments.length; ++i) {
      var a = _i.assignments[i];

      var project = gData.getAssignableOrChild(a.assignable_id);
      // If this assignable is a project and the date ranges overlap, add metadata
      if (
        project &&
        project.type == 'Project' &&
        dateRangesOverlap(start, end, a.starts_at, a.ends_at)
      ) {
        ret.push(project);
      }
    }

    return ret;
  };

  _i.isDateValidForEmployment = function (date) {
    if (
      (date >= _i.getValidStartDate() || _i.getValidStartDate() == null) &&
      (_i.getValidEndDate() == null || date <= _i.getValidEndDate())
    ) {
      return true;
    }
    return false;
  };

  _i.clearEmploymentDateCache = function () {
    _i._cachedStartDate = null;
    _i._cachedEndDate = null;
  };

  _i.getValidStartDate = function () {
    if (_i.cachedStartDate) {
      return _i.cachedStartDate;
    }
    _cachedStartDate = _i.hire_date;
    return _cachedStartDate;
  };

  _i.getValidEndDate = function () {
    if (_i.cachedEndDate) {
      return _i.cachedEndDate;
    }
    _i.cachedEndDate = _i.termination_date || _i.deleted_at;
    return _i.cachedEndDate;
  };

  // include leave types
  _i.getCurrentAssignables = function () {
    // Inclusive dates
    var start = new Date();
    var end = new Date();
    end.setDate(end.getDate() + 1);
    start.toStartOfDay();
    end.toStartOfDay();
    var today = new Date();
    today.toStartOfDay();

    var ret = [];

    for (var i = 0; _i.assignments && i < _i.assignments.length; ++i) {
      var a = _i.assignments[i];
      var project = gData.getAssignableOrChild(a.assignable_id);
      // If this assignable is a project and the date ranges overlap, add metadata
      if (project && dateRangesOverlap(start, end, a.starts_at, a.ends_at)) {
        ret.push(project);
      }
    }

    return ret;
  };

  _i.getTimeEntriesForAssignableAndDate = function (assignable_id, date) {
    var ret = [],
      te;
    for (var i = 0; i < _i.timeEntries.length; i++) {
      te = _i.timeEntries[i];
      if (te.assignable_id == assignable_id && Date.sameDay(date, te.date)) {
        ret.push(te);
      }
    }
    return ret;
  };

  _i.getTimeEntriesForDate = function (date) {
    var ret = [];
    for (var i = 0; i < _i.timeEntries.length; i++) {
      var entryDate = new Date(_i.timeEntries[i].date);
      entryDate.toStartOfDay();
      var compare = Date.toStartOfDay(date);
      if (!(compare > entryDate || entryDate > compare)) {
        ret.push(_i.timeEntries[i]);
      }
    }
    return ret;
  };

  _i.getAssignablesForDateRange = function (start, end) {
    // Inclusive dates
    var start = new Date(start);
    var end = new Date(end);
    end.setDate(end.getDate() + 1);
    start.toStartOfDay();
    end.toStartOfDay();
    var today = new Date();
    today.toStartOfDay();
    var projects = [];
    for (var i = 0; _i.assignments && i < _i.assignments.length; ++i) {
      var a = _i.assignments[i];
      var project = gData.getAssignableOrChild(a.assignable_id);
      // If this assignable is a project and the date ranges overlap, add metadata
      if (project && dateRangesOverlap(start, end, a.starts_at, a.ends_at)) {
        // TODO: need to remove any duplicates
        projects.push(project);
      }
    }
    return projects;
  };

  _i.getDaysOnAssignment = function (assignment) {
    var days = 0;
    if (!_i.timeline) {
      _i.buildUtilizationTimeline();
    }

    for (var i = 0; i < _i.timeline.length; ++i) {
      var block = _i.timeline[i];
      for (var j = 0; j < block.assignments.length; ++j) {
        if (block.assignments[j].id == assignment.id) {
          // Add time for this block
          var start = new Date(block.startTime);
          var end = new Date(block.endTime);
          if (block.projectUtilizationScale > 0) {
            days +=
              gData.workWeek.workdayDiff(start, end) *
              assignment.percent *
              block.projectUtilizationScale;
          }
        }
      }
    }

    return days;
  };

  _i.isParttimeDuring = function (from, to) {
    var normalHoursInWorkday = gData.accountSettings.hours_in_workday,
      endTime = to.getTime();
    while (from.getTime() < endTime) {
      if (
        gData.workWeek.isWorkday(from) &&
        _i.getAvailabilityOn(from) < normalHoursInWorkday
      ) {
        return true;
      }
      from = Date.addDays(from, 1);
    }
    return false;
  };

  _i.getAvailabilityOn = function (date) {
    var key = new Date(date.getTime()).setHours(0, 0, 0, 0), // start of day, as ms
      value = _availabilityCache[key],
      a;

    if (!(value === 0 || value)) {
      var notAvailable =
        !gData.workWeek.isWorkday(date) ||
        (_i.termination_date && date > _i.termination_date) ||
        (_i.hire_date && date < _i.hire_date);
      if (notAvailable) {
        value = 0;
      } else {
        for (
          var i = 0;
          _i.availabilities && i < _i.availabilities.length;
          i++
        ) {
          a = _i.availabilities[i];
          if (
            (a.starts_at == null ||
              Date.calendarDayDiff(a.starts_at_as_date, date) >= 0) &&
            (a.ends_at == null ||
              Date.calendarDayDiff(date, a.ends_at_as_date) >= 0)
          ) {
            value = a.days[date.getDay()];
            break;
          }
        }
        if (!(value === 0 || value))
          value = Number(gData.accountSettings.hours_in_workday);
      }
      _availabilityCache[key] = value;
    }
    return value;
  };

  _i.getAverageAvailableHoursPerDayDuring = function (from, to) {
    var availableHours = 0,
      workDays = 0,
      h,
      endTime = to.getTime();
    while (from.getTime() < endTime) {
      h = _i.getAvailabilityOn(from);
      if (h > 0) {
        availableHours += h;
        workDays += 1;
      }
      from = Date.addDays(from, 1);
    }
    return availableHours / (workDays || 1);
  };

  _i.getAvailableHoursDuring = function (from, to) {
    var availableHours = 0,
      endTime = to.getTime();
    while (from.getTime() < endTime) {
      availableHours += _i.getAvailabilityOn(from);
      from = Date.addDays(from, 1);
    }
    return availableHours;
  };

  _i.getWorkdaysDuring = function (from, to) {
    var workDays = 0,
      endTime = to.getTime();
    while (from.getTime() < endTime) {
      if (_i.getAvailabilityOn(from) > 0) {
        workDays += 1;
      }
      from = Date.addDays(from, 1);
    }
    return workDays;
  };

  _i.getNextWorkDayStarting = function (date) {
    var d = new Date(date),
      addedDays = 0;
    // prevent infinite loop with 10 year limit
    while (_i.getAvailabilityOn(d) <= 0 && ++addedDays < 365 * 10) {
      d = d.addDays(1);
    }
    return d;
  };

  _i.getLastWorkDayStarting = function (date) {
    var d = new Date(date),
      addedDays = 0;
    // prevent infinite loop with 10 year limit
    while (_i.getAvailabilityOn(d) <= 0 && ++addedDays < 365 * 10) {
      d.addDays(-1);
    }
    return d;
  };

  _i.getLastWorkDayBefore = function (date) {
    return _i.getLastWorkDayStarting(new Date(date).addDays(-1));
  };

  // get the number of work hours
  _i.getHoursInWorkWeek = function (start_date) {
    var date = start_date,
      hours = 0;
    for (var i = 0; i < 7; i++) {
      hours += _i.getAvailabilityOn(date);
      date = Date.addDays(date, 1);
    }
    return hours;
  };

  _i.getTimelineBlockByDate = function (d) {
    var startOfDay = new Date(d);
    startOfDay.toStartOfDay();
    var t = startOfDay.getTime();

    for (var i = 0; i < _i.timeline.length; ++i) {
      var block = _i.timeline[i];
      if (t >= block.startTime && t < block.endTime) {
        return block;
      }
    }
    return null;
  };

  _i.utilizationFromAssignment = function (a, options) {
    if (options && options.assignmentFilter && !options.assignmentFilter(a)) {
      return 0;
    }
    var available_hours, adjustedEndsAtDate, workDaysDuringAssignment;
    adjustedEndsAtDate;

    // NB: Assignment endDate is inclusive, but work day calculations use an exclusive end date.

    if (!_hours_in_workday_cached) {
      _hours_in_workday_cached = Number(gData.accountSettings.hours_in_workday);
    }

    switch (a.allocation_mode) {
      case 'percent':
        return a.percent;
      case 'hours_per_day':
        adjustedEndsAtDate = Date.addDays(a.ends_at, 1);
        workDaysDuringAssignment = _i.getWorkdaysDuring(
          a.starts_at,
          adjustedEndsAtDate
        );
        if (workDaysDuringAssignment == 0) {
          return a.hours_per_day / gData.workWeek.getHoursInWorkday();
        } else {
          return (
            (a.hours_per_day * workDaysDuringAssignment) /
            _i.getAvailableHoursDuring(a.starts_at, adjustedEndsAtDate)
          );
        }
      case 'fixed':
        adjustedEndsAtDate = Date.addDays(a.ends_at, 1);
        available_hours = _i.getAvailableHoursDuring(
          a.starts_at,
          adjustedEndsAtDate
        );
        if (available_hours == 0) {
          // Use the organization-wide hours per work day.
          workDaysDuringAssignment = _i.getWorkdaysDuring(
            a.starts_at,
            adjustedEndsAtDate
          );
          if (workDaysDuringAssignment == 0) {
            workDaysDuringAssignment = Date.calendarDayDiff(
              a.starts_at,
              adjustedEndsAtDate
            );
          }
          return (
            a.fixed_hours /
            (gData.workWeek.getHoursInWorkday() * workDaysDuringAssignment)
          );
        } else {
          return a.fixed_hours / available_hours;
        }
      default:
        throw 'unknown allocation mode';
    }
  };

  _i.getUtilizationScaleForDateRange = function (from, to) {
    return (
      _i.getAverageAvailableHoursPerDayDuring(from, to) /
      Number(gData.accountSettings.hours_in_workday)
    );
  };

  _i.buildUtilizationTimeline = function (options) {
    performance.mark('buildUtilizationTimeline Start');

    var i, j, k, o;

    // Final result goes here:
    _i.timeline = [];

    // Cached calculations go here:
    _i.timelineEvents = [];
    _i.timelineEventsByDate = {};

    // Build a map of times to arrays of events (start/end of projects)
    for (i = 0; i < _i.assignments.length; ++i) {
      var a = _i.assignments[i];
      var s = a.starts_at.getTime();

      // ends_at date is inclusive, so the next timeline event should start the day after.
      var e = Date.addDays(a.ends_at, 1).getTime();
      if (!_i.timelineEventsByDate[s]) {
        _i.timelineEventsByDate[s] = [];
        _i.timelineEvents.push({
          time: s,
          events: _i.timelineEventsByDate[s],
        });
      }
      o = {
        start: true,
        time: s,
        assignment: a,
      };
      _i.timelineEventsByDate[s].push(o);
      if (!_i.timelineEventsByDate[e]) {
        _i.timelineEventsByDate[e] = [];
        _i.timelineEvents.push({
          time: e,
          events: _i.timelineEventsByDate[e],
        });
      }
      o = {
        start: false,
        time: e,
        assignment: a,
      };
      _i.timelineEventsByDate[e].push(o);
    }

    // availability boundaries that don't fall on an existing timeline event date
    for (i = 0; _i.availabilities && i < _i.availabilities.length; i++) {
      var a = _i.availabilities[i];
      (s = a.starts_at ? parseRubyDate(a.starts_at).getTime() : null),
        (e = a.ends_at ? parseRubyDate(a.ends_at).addDays(1).getTime() : null);

      if (s && !_i.timelineEventsByDate[s]) {
        _i.timelineEventsByDate[s] = [];
        _i.timelineEvents.push({
          time: s,
          events: _i.timelineEventsByDate[s],
        });
      }
      if (e && !_i.timelineEventsByDate[e]) {
        _i.timelineEventsByDate[e] = [];
        _i.timelineEvents.push({
          time: e,
          events: _i.timelineEventsByDate[e],
        });
      }
    }

    _i.timelineEvents.sort(function (a, b) {
      return a.time - b.time;
    });

    var currentDate = null;
    var utilizationScale = 1;
    var assignments = [];

    for (i = 0; i < _i.timelineEvents.length; ++i) {
      var event = _i.timelineEvents[i];
      if (currentDate && event.time > AVAILABILITY_SINCE) {
        // Build the current timeblock and store it in the timeline
        var utilization = 0;
        for (j = 0; j < assignments.length; ++j) {
          utilization += _i.utilizationFromAssignment(assignments[j], options);
        }

        utilizationScale = _i.getUtilizationScaleForDateRange(
          new Date(currentDate),
          new Date(event.time).addDays(1)
        );

        var block = {
          startTime:
            currentDate > AVAILABILITY_SINCE ? currentDate : AVAILABILITY_SINCE,
          endTime: event.time,
          assignments: [],
          utilization: utilization,
          projectUtilizationScale: utilizationScale,
        };

        for (k = 0; k < assignments.length; ++k) {
          block.assignments.push(assignments[k]);
        }
        _i.timeline.push(block);
      }
      // Update the project list
      for (j = 0; j < event.events.length; ++j) {
        var e = event.events[j];
        if (e.start) {
          assignments.push(e.assignment);
        } else {
          for (k = 0; k < assignments.length; ++k) {
            if (assignments[k].id == e.assignment.id) {
              assignments.splice(k, 1);
              break;
            }
          }
        }
      }
      currentDate = event.time;
    }

    // add end-cap
    var lastEntry = _i.timeline[_i.timeline.length - 1];
    var today = new Date();
    today.toStartOfDay();

    if (lastEntry) {
      var endCap = {
        startTime: lastEntry.endTime,
        endTime: Date.addDays(today, 365).getTime(),
        assignments: [],
        utilization: 0.0,
        projectUtilizationScale: 1,
        isEndCap: true,
      };
      _i.timeline.push(endCap);
    } else {
      // This user has an empty timeline
      var endCap = {
        startTime: AVAILABILITY_SINCE,
        endTime: Date.addDays(today, 365).getTime(),
        assignments: [],
        utilization: 0.0,
        projectUtilizationScale: 1,
        isEndCap: true,
      };
      _i.timeline.push(endCap);
    }

    performance.mark('buildUtilizationTimeline End');
    performance.measure(
      'buildUtilizationTimeline',
      'buildUtilizationTimeline Start',
      'buildUtilizationTimeline End'
    );
  };

  _i.getUtilizationOverTime = function (startDate, endDate) {
    var startTime = startDate.getTime();
    var endTime = endDate.getTime();
    var viewTime = endTime - startTime;
    var u = 0;

    var viewDays = gData.workWeek.workdayDiff(startDate, endDate);

    for (var i = 0; i < _i.timeline.length; ++i) {
      var block = _i.timeline[i];
      if (block.startTime < endTime && block.endTime > startTime) {
        var overlapStart = Math.max(startTime, block.startTime);
        var overlapEnd = Math.min(endTime, block.endTime);
        var blockWorkDays = gData.workWeek.workdayDiff(
          new Date(overlapStart),
          new Date(overlapEnd)
        );
        u += blockWorkDays * block.utilization;
      } else if (block.startTime > endTime) {
        // Passed everything that's relevant, stop looking
        break;
      }
    }
    return u / viewDays;
  };

  _i.isBillable = function () {
    return _i.billable;
  };

  _i.utilizationTarget = function () {
    return _i.billability_target;
  };

  _i.isContractor = function () {
    var is = false;
    _.each(
      gData.userTypes,
      function (type) {
        if (type.id == this.user_type_id && type.value == 'Contractor') {
          is = true;
        }
      },
      this
    );
    return is;
  };

  _i.isPortfolioViewer = function () {
    var is = false;
    _.each(
      gData.userTypes,
      function (type) {
        if (type.id == this.user_type_id && type.value == 'Portfolio Viewer') {
          is = true;
        }
      },
      this
    );
    return is;
  };

  _i.getTerminationDate = function () {
    return _i.termination_date || _i.deleted_at;
  };

  _i.hasTag = function (tag) {
    for (var i = 0; i < _i.tags.length; ++i) {
      var t = _i.tags[i];
      if (
        t.namespace == tag.namespace &&
        t.value == tag.value &&
        t.name == tag.name
      ) {
        return true;
      }
    }
    return false;
  };

  // Don't use this function directly, you probably want to use service.userAddTag which calls this function after receiving
  // a server response
  _i.addTag = function (tag) {
    if (!_i.hasTag(tag)) {
      _i.tags.push(tag);
    }
  };

  // Don't use this function directly, you probably want to use service.userRemoveTag which calls this function after receiving
  // a server response
  _i.removeTag = function (tag) {
    for (var i = 0; i < _i.tags.length; ++i) {
      var t = _i.tags[i];
      if (
        t.namespace == tag.namespace &&
        t.value == tag.value &&
        t.name == tag.name
      ) {
        _i.tags.splice(i, 1);
        return;
      }
    }
  };

  _i.constructor = function () {
    _i.hire_date = parseRubyDate(_i.hire_date);
    if (_i.termination_date) {
      _i.termination_date = parseRubyDate(_i.termination_date);
    }
    if (_i.deleted_at) {
      _i.deleted_at = parseRubyDateTime(_i.deleted_at);
    }
    if (_i.thumbnail == null || _i.thumbnail == '') {
      _i.thumbnail = DEFAULT_PERSON_THUMBNAIL_URL;
    }
    _i.timeEntries = _i.timeEntries || [];
    _i.timeEntriesByDate = _i.timeEntriesByDate || {};
    if (_i.firstName == '' || _i.firstName == null) {
      _i.firstName = _i.email;
      if (_i.lastName == null) _i.lastName = '';
    }
    if (_i.assignments == null) _i.assignments = [];
  };

  _i.constructor();
}

function data() {
  var _i = this;
  var me = null;

  _i._users = [];
  _i._usersById = {};
  _i.placeholderResources = null;
  _i.placeholdersById = {};
  _i._cachedUsersOnTheBench = null;
  _i._potentialProjectOwners = [];

  _i.userTypes = [
    { id: 1, name: 'userlevel10', value: 'Resourcing Admin' },
    { id: 2, name: 'userlevel20', value: 'Portfolio Editor' },
    { id: 7, name: 'userlevel70', value: 'People Scheduler' },
    { id: 3, name: 'userlevel30', value: 'Portfolio Reporter' },
    { id: 4, name: 'userlevel40', value: 'Portfolio Viewer' },
    { id: 8, name: 'userlevel80', value: 'Project Editor' },
    { id: 5, name: 'userlevel50', value: 'Contractor' },
  ];

  _i._projects = [];
  _i._allprojects = [];
  _i._projectsById = {};

  _i.timeEntries = [];
  _i.timeEntriesById = {};

  _i._leaveTypes = [];
  _i._leaveTypesById = {};

  _i._assignables = [];
  _i._assignablesById = {};
  _i._assignablesAllById = {};

  _i.searchData = null;

  _i.statuses = [];
  _i.statusesByUserId = {};

  _i.historyLogs = [];

  _i.accountSettings = null;

  _i.projectStates = [];

  _i.assignments = [];
  _i.assignmentsById = [];

  _i.resourceRequests = [];

  _i.feeditems = [];
  _i.billRatesByAssignableId = {};
  _i.accountBillRates = [];
  _i.tagsByNameSpace = [];
  _i.budget_items = [];

  _i.tags = [];

  _i.workWeek;
  _i.allAssignablesHaveBeenLoaded = false;

  _i.clearUsers = function () {
    _i._users = [];
    _i._usersById = {};
  };

  _i.clearPlaceholders = function () {
    _i.placeholderResources = null;
    _i.placeholdersById = {};
  };

  _i.clearProjects = function () {
    _i._projects = [];
    _i._allprojects = [];
    _i._projectsById = {};
    _i.updateAssignables();
  };

  _i.clearLeaveTypes = function () {
    _i.LeaveTypes = [];
    _i.LeaveTypesById = {};
    _i.updateAssignables();
  };

  _i.clearStatuses = function () {
    _i.statuses = [];
    _i.statusesByUserId = {};
  };

  _i.isUsersListEmpty = function () {
    return _i._users.length === 0;
  };

  // This is called to build search data before its been received from the server
  _i.buildSearchData = function () {
    var projects = gData.getProjects();
    var users = gData.getUsers();

    _i.searchData = {
      project: [],
      user: [],
    };

    for (var i = 0; i < projects.length; ++i) {
      var o = projects[i];
      if (!o.archived) {
        _i.searchData.project.push({
          match: (o.client ? o.client + ' ' : '') + o.name,
          id: o.id,
          guid: o.guid,
          item_type: 'project',
          param: o.project_state,
        });
      }
    }

    for (var i = 0; i < users.length; ++i) {
      var o = users[i];
      if (!o.archived) {
        _i.searchData.user.push({
          match: o.displayName,
          id: o.id,
          guid: o.guid,
          item_type: 'user',
          param: o.thumbnail,
        });
      }
    }
  };

  _i.setSearchData = function (searchData) {
    _i.searchData = {};
    for (var i = 0; i < searchData.length; ++i) {
      var o = searchData[i];
      if (!_i.searchData[o.item_type]) {
        _i.searchData[o.item_type] = [];
      }
      _i.searchData[o.item_type].push(o);
    }
  };

  _i.getSearchData = function () {
    return _i.searchData;
  };

  _i.getAll = function (a, excludeDeleted) {
    if (excludeDeleted) {
      var all = a;
      a = [];
      for (var i = 0; i < all.length; ++i) {
        if (!(all[i].archived || all[i].deleted_at || all[i].deleted)) {
          a.push(all[i]);
        }
      }
    }
    return a;
  };

  _i.getById = function (a, id, excludeDeleted) {
    var o = a[id];
    if (excludeDeleted && o && (o.archived || o.deleted)) {
      o = null;
    }
    return o;
  };

  _i.getProjects = function (excludeDeleted) {
    return _i.getAll(_i._projects, excludeDeleted);
  };

  _i.getProject = function (id, excludeDeleted) {
    return _i.getById(_i._projectsById, id, excludeDeleted);
  };

  _i.getPotentialProjectOwners = function () {
    return _i.getAll(_i._potentialProjectOwners);
  };

  _i.setPotentialProjectOwners = function (potential_owners) {
    _i._potentialProjectOwners = potential_owners;
  };

  _i.getUsers = function (excludeDeleted) {
    return _i.getAll(_i._users, excludeDeleted);
  };

  // For general/external use: ID may belong to either user or placeholder.
  // This unifies user/placeholder lookup for assignments, etc.
  _i.getUser = function (id, excludeDeleted) {
    user = _i.getById(_i._usersById, id, excludeDeleted);
    if (!user) {
      // Try placeholder resources. Placeholder resources cannot be 'archived', so
      // excludeDeleted is always true.
      user = _i.getById(_i.placeholdersById, id, true);
    }
    return user;
  };

  _i.getUsersByIds = function (ids) {
    ids = ids || [];
    var results = [];
    for (var i = 0; i < _i._users.length; i++) {
      if (ids.indexOf(_i._users[i].id) > -1) {
        results.push(_i._users[i]);
      }
    }
    return results;
  };

  _i.getUserByGuid = function (guid) {
    for (var i = 0; i < _i._users.length; i++) {
      if (_i._users[i].guid == guid) return _i._users[i];
    }
    return null;
  };

  _i.getProjectByGuid = function (guid) {
    for (var i = 0; i < _i._projects.length; i++) {
      if (_i._projects[i].guid == guid) return _i._projects[i];
    }
    return null;
  };

  _i.getLeaveTypes = function (excludeDeleted) {
    return _i.getAll(_i._leaveTypes, excludeDeleted);
  };

  _i.getLeaveType = function (id, excludeDeleted) {
    return _i.getById(_i._leaveTypesById, id, excludeDeleted);
  };

  _i.allAssignablesLoaded = function () {
    return _i.allAssignablesHaveBeenLoaded;
  };

  _i.getAssignables = function (excludeDeleted) {
    return _i.getAll(_i._assignables, excludeDeleted);
  };

  _i.getAssignable = function (id, excludeDeleted) {
    return _i.getById(_i._assignablesById, id, excludeDeleted);
  };

  _i.getAssignableOrChild = function (id, excludeDeleted) {
    return _i.getById(_i._assignablesAllById, id, excludeDeleted);
  };

  _i.addAssignableAndChildren = function (project) {
    _i._assignablesAllById[project.id] = project;
    _i._allprojects.push(project);
    for (var i = 0; project.children && i < project.children.length; i++) {
      _i.addAssignableAndChildren(project.children[i]);
    }
  };

  _i.updateAssignables = function () {
    _i._assignables = [];
    _i._assignablesById = {};
    _i._assignablesAllById = {};

    for (var i = 0; i < _i._projects.length; ++i) {
      _i._assignables.push(_i._projects[i]);
      _i._assignablesById[_i._projects[i].id] = _i._projects[i];
      _i.addAssignableAndChildren(_i._projects[i]);
    }

    for (var i = 0; i < _i._leaveTypes.length; ++i) {
      _i._assignables.push(_i._leaveTypes[i]);
      _i._assignablesById[_i._leaveTypes[i].id] = _i._leaveTypes[i];
      _i.addAssignableAndChildren(_i._leaveTypes[i]);
    }
  };

  // use for sorting
  _i.assignableComparer = function (a, b) {
    if (a.client && b.client) {
      var sa = a.client.toLowerCase();
      var sb = b.client.toLowerCase();
      return sa < sb ? -1 : 1;
    } else if (b.client) return 1;
    else return -1;
  };

  _i.projectClientAndNameComparer = function (p1, p2) {
    return _i.assignableClientAndNameComparer(p1.value, p2.value);
  };

  // Sorts by client name and project name
  _i.assignableClientAndNameComparer = function (a, b) {
    var projectNameA = a.name.toLowerCase();
    var projectNameB = b.name.toLowerCase();
    if (a.client && b.client && a.name && b.name) {
      var projectClientA = a.client.toLowerCase();
      var projectClientB = b.client.toLowerCase();

      if (projectClientA === projectClientB) {
        // Handle case where both project names are non-numeric
        if (isNaN(projectNameA) && isNaN(projectNameB)) {
          return projectNameA < projectNameB ? -1 : 1;
        }
        // Handle case where one project name is non-numeric
        if (isNaN(projectNameA) || isNaN(projectNameB)) {
          return isNaN(projectNameB) ? -1 : 1;
        } else {
          // Handle case where both project names are numeric
          return parseInt(projectNameA, 10) < parseInt(projectNameB, 10)
            ? -1
            : 1;
        }
      }
      if (projectClientA > projectClientB) {
        return 1;
      }
      if (projectClientA < projectClientB) {
        return -1;
      }
      // Compare two client-less projects
    } else if (!a.client && !b.client && !a.leave_type && !b.leave_type) {
      return projectNameA < projectNameB ? -1 : 1;
      // Compare client-less project and project with client
    } else if ((!a.client || !b.client) && !a.leave_type && !b.leave_type) {
      return a.client ? -1 : 1;
      // Compare two leave types
    } else if (a.leave_type && b.leave_type && !a.client && !b.client) {
      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    }
    // Compare client-less project and leave type
    else if (!a.client || (!b.client && (a.leave_type || b.leave_type))) {
      return b.leave_type ? -1 : 1;
      // Compare leave type and project with client
    } else if ((a.client || b.client) && (a.leave_type || b.leave_type)) {
      return a.client ? -1 : 1;
    }
  };

  _i.setProjectStates = function (states) {
    _i.projectStates = states;
  };

  _i.setResourceTypes = function (types) {
    _i.resourceTypes = types;
  };

  var _cached_timeBudgetCategories = null;

  _i.getTimeBudgetCategories = function () {
    var ret, i, bi;

    if (!_cached_timeBudgetCategories) {
      ret = [];

      for (i = 0; i < _i.budget_items.length; i++) {
        bi = _i.budget_items[i];
        if (
          bi.category != '' &&
          bi.category != null &&
          (bi.item_type == 'TimeFees' || bi.item_type == 'TimeFeesDays')
        ) {
          ret.push(bi);
        }
      }
      ret.sort(function (a, b) {
        return stringComparer(
          a.category.toLowerCase(),
          b.category.toLowerCase()
        );
      });
      _cached_timeBudgetCategories = ret;
    }
    return _cached_timeBudgetCategories;
  };

  _i.getBudgetByItemType = function (item_type) {
    ret = [];

    for (var i = 0; i < _i.budget_items.length; i++) {
      if (_i.budget_items[i].item_type == item_type)
        ret.push(_i.budget_items[i]);
    }
    return ret;
  };

  _i.getBudgetCategoriesFromData = function () {
    ret = [];

    for (i = 0; i < _i.budget_items.length; i++) {
      bi = _i.budget_items[i];
      if (bi.category != '' && bi.category != null) {
        ret.push(bi.category);
      }
    }
    return ret;
  };

  _i.updateBudgetItem = function (data) {
    for (var i = 0; i < _i.budget_items.length; i++) {
      if (_i.budget_items[i].id == data.id) {
        _i.budget_items[i] = data;
        return;
      }
    }
    _i.budget_items.push(data);
  };

  _i.deleteBudgetItem = function (data) {
    for (var i = 0; i < _i.budget_items.length; i++) {
      if (_i.budget_items[i].id == data.id) {
        _i.budget_items.splice(i, 1);
        return;
      }
    }
  };

  _i.addBudgetItem = function (data) {
    if (data.category == null) data.category = '';
    _i.budget_items.push(data);
  };

  _i.addBudget = function (data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i].category == null) data[i].category = '';
      _i.budget_items.push(data[i]);
    }
  };

  _i.setBillRates = function (assignableId, rates) {
    _i.billRatesByAssignableId[assignableId] = rates;
  };

  _i.getBillRates = function (assignableId) {
    billRates = _i.billRatesByAssignableId[assignableId];
    if (_.isArray(billRates)) {
      return billRates;
    } else {
      return null;
    }
  };

  _i.setAccountBillRates = function (rates) {
    _i.accountBillRates = rates;
  };

  _i.getAccountBillRates = function () {
    return _i.accountBillRates;
  };

  _i.getDefaultBillRateForDisciplineRole = function (discipline_id, role_id) {
    var defaultRate = null,
      score = 0,
      rates = _i.accountBillRates;

    for (var i = 0; i < _i.accountBillRates.length; i++) {
      if (
        rates[i].discipline_id == discipline_id &&
        rates[i].role_id == role_id
      ) {
        defaultRate = rates[i];
        score = 8;
      } else if (
        score < 8 &&
        rates[i].role_id == role_id &&
        rates[i].discipline_id == null &&
        role_id != null
      ) {
        defaultRate = rates[i];
        score = 4;
      } else if (
        score < 4 &&
        rates[i].role_id == null &&
        rates[i].discipline_id == discipline_id &&
        discipline_id != null
      ) {
        defaultRate = rates[i];
        score = 2;
      } else if (
        score < 2 &&
        rates[i].role_id == null &&
        rates[i].discipline_id == null
      ) {
        defaultRate = rates[i];
      }
    }
    return defaultRate;
  };

  _i.getDefaultBillRate = function () {
    for (var i = 0; i < _i.accountBillRates.length; i++) {
      if (
        _i.accountBillRates[i].discipline_id == null &&
        _i.accountBillRates[i].role_id == null
      ) {
        return _i.accountBillRates[i];
      }
    }
    return null;
  };

  _i.verifyTagIdInNameSpace = function (id, namespace) {
    var tags = _i.getTagsByNamespace(namespace);
    for (var i = 0; tags && i < tags.length; i++) {
      if (tags[i].id == id) return true;
    }
    return false;
  };

  _i.setStatuses = function (statuses) {
    _i.clearStatuses();
    _i.statuses = [];

    var lastNight = new Date().setHours(0, 0, 0, 0);
    for (var i = 0; i < statuses.length; ++i) {
      var oldstatus = null;
      var addstatus = true;
      for (var j = 0; j < _i.statuses.length; ++j) {
        if (_i.statuses[j].user_id == statuses[i].user_id) {
          if (
            statuses[i].updated_at &&
            _i.statuses[j].updated_at &&
            new Date(statuses[i].updated_at).getTime() >
              new Date(_i.statuses[j].updated_at).getTime()
          ) {
            // Replace the status for this user
            _i.statuses[j] = statuses[i];
            _i.statusesByUserId[status.user_id] = statuses[i];
          }
          addstatus = false;
          break;
        }
      }
      if (addstatus) {
        _i.statuses.push(statuses[i]);
        _i.statusesByUserId[statuses[i].user_id] = statuses[i];
      }
      if (statuses[i].updated_at != null) {
        _i.addStatusItemToFeed(statuses[i]);
      }
    }
    _i.feeditems.sort(function (a, b) {
      var sa = parseRubyDateTime(a.date, true);
      var sb = parseRubyDateTime(b.date, true);
      if (sa == sb) return 0;
      return sa > sb ? -1 : 1;
    });
  };

  _i.addStatusItemToFeed = function (status) {
    feed = {};
    feed.status = status;
    feed.history_log = null;
    feed.date = status.updated_at;
    _i.feeditems.push(feed);
  };

  _i.setStatus = function (status) {
    for (var i = 0; i < _i.statuses.length; ++i) {
      if (_i.statuses[i].user_id == status.user_id) {
        // Replace the status for this user
        _i.statuses[i] = status;
        _i.statusesByUserId[status.user_id] = status;
        break;
      }
    }
  };

  _i.getDeletedUsers = function () {
    var users = [];
    for (var i = 0; i < _i._users.length; i++) {
      if (_i._users[i].deleted) {
        users.push(_i._users[i]);
      }
    }
    return users;
  };

  // Example getUsersByStatus("WFH") returns all users that are currently working from home
  _i.getUsersByStatus = function (status) {
    var users = [];

    // We can access usersById directly here, since status does not apply to placeholders.
    for (var i = 0; i < _i.statuses.length; ++i) {
      if (_i.statuses[i].status == status) {
        user = _i._usersById[_i.statuses[i].user_id];
        if (user && !user.deleted) {
          users.push(_i._usersById[_i.statuses[i].user_id]);
        }
      }
    }
    return users;
  };

  _i.getUsersOnTheBench = function (callback) {
    if (_i._cachedUsersOnTheBench) {
      window.benchResult = _i._cachedUsersOnTheBench;
      callback && callback();
      return _i._cachedUsersOnTheBench;
    }

    var result = {};
    result.restOfThisWeek = [];
    result.nextWeek = [];

    var today = new Date();
    today.toStartOfDay();

    if (!_i.workWeek.isWorkday(today)) {
      today.addDays(7);
    }

    var startOfWeek = Date.forDayOfWeek(
      today,
      _i.accountSettings.first_working_day
    );
    var endOfWeek = Date.addDays(startOfWeek, 6);
    var startOfNextWeek = Date.addDays(startOfWeek, 7);
    var endOfNextWeek = Date.addDays(startOfNextWeek, 6);

    _i.getUsers(true /*excludeDeleted*/).forEach(function (user) {
      if (user.billable) {
        var u;
        user.buildUtilizationTimeline();
        u = user.getUtilizationOverTime(today, endOfWeek);
        // if(u * 100 < user.billability_target)
        if (u * 100 < 100) {
          result.restOfThisWeek.push({ userId: user.id, utilization: u });
        }
        u = user.getUtilizationOverTime(startOfNextWeek, endOfNextWeek);
        // if(u * 100 < user.billability_target)
        if (u * 100 < 100) {
          result.nextWeek.push({ userId: user.id, utilization: u });
        }
      }
    });

    _i._cachedUsersOnTheBench = result;
    window.benchResult = result;
    callback && callback();
    return result;
  };

  function compareUsersByDisplayName(a, b) {
    var sa = a.displayName ? a.displayName.toLowerCase() : '';
    var sb = b.displayName ? b.displayName.toLowerCase() : '';
    if (sa == sb) return 0;
    return sa < sb ? -1 : 1;
  }

  _i.setUsers = function (users, doNotProcess, doNotSort) {
    _i.clearUsers();
    _i._users = users;
    if (!doNotSort) {
      _i._users.sort(compareUsersByDisplayName);
    }
    for (var i = 0; i < users.length; ++i) {
      if (!doNotProcess) {
        _i.processUser(users[i]);
      }
      _i._usersById[users[i].id] = users[i];
    }
  };

  function compareAssignmentsByUserId(a, b) {
    return a.user_id - b.user_id;
  }

  _i.setAssignmentsOfUsersAndAssignables = function (assignments, reset) {
    if (reset) {
      Object.keys(_i._usersById).forEach(function (k) {
        _i._usersById[k].assignments = [];
      });
      Object.keys(_i.placeholdersById).forEach(function (k) {
        _i.placeholdersById[k].assignments = [];
      });
      Object.keys(_i._assignablesAllById).forEach(function (k) {
        _i._assignablesAllById[k].assignments = [];
      });
    }

    assignments.forEach(function (assignment) {
      var u = _i.getUser(assignment.user_id),
        a = _i._assignablesAllById[assignment.assignable_id];

      // TODO: Assignments for placeholders should not be returned if placeholders are disabled.
      // For now, work around by skipping this processing if user is not found.
      if (!u || !a) return;

      while (a && a.parent_id) {
        a = _i._assignablesAllById[a.parent_id];
      }

      __A(a, 'assignable not found');

      if (!u.assignments) {
        u.assignments = [];
      }
      if (!a.assignments) {
        a.assignments = [];
      }

      var existingAssignment = _i.assignmentsById[assignment.id];
      if (existingAssignment) {
        _i.updateAssignment(assignment);
        if (reset) {
          u.assignments.push(existingAssignment);
          a.assignments.push(existingAssignment);
        }
      } else {
        _i.assignments.push(assignment);
        _i.assignmentsById[assignment.id] = assignment;
        _i.processAssignment(assignment);
        u.assignments.push(assignment);
        a.assignments.push(assignment);
      }
    });
  };

  _i.setAssignmentsWithMissingProjectsAndUsers = function (assignments, reset) {
    if (reset) {
      Object.keys(_i._usersById).forEach(function (k) {
        _i._usersById[k].assignments = [];
      });
      Object.keys(_i.placeholdersById).forEach(function (k) {
        _i.placeholdersById[k].assignments = [];
      });
      Object.keys(_i._assignablesAllById).forEach(function (k) {
        _i._assignablesAllById[k].assignments = [];
      });
    }

    var missingAssignableIds = [];
    var missingUserIds = [];
    var unprocessedAssignments = assignments;
    var processAssignments = function () {
      unprocessedAssignments = unprocessedAssignments.filter(function (
        assignment
      ) {
        var u = _i.getUser(assignment.user_id);
        var a = _i._assignablesAllById[assignment.assignable_id];
        var parent_id = a && a.parent_id;
        if (parent_id) {
          a = _i._assignablesAllById[parent_id];
        }
        var processAssignment = function () {
          if (!u.assignments) {
            u.assignments = [];
          }
          if (!a.assignments) {
            a.assignments = [];
          }

          var existingAssignment = _i.assignmentsById[assignment.id];
          if (existingAssignment) {
            _i.updateAssignment(assignment);
            if (reset) {
              u.assignments.push(existingAssignment);
              a.assignments.push(existingAssignment);
            }
          } else {
            _i.assignments.push(assignment);
            _i.assignmentsById[assignment.id] = assignment;
            _i.processAssignment(assignment);
            u.assignments.push(assignment);
            a.assignments.push(assignment);
          }
        };
        if (a && u) {
          processAssignment();
          return false;
        } else {
          if (!a) {
            var a_id = parent_id || assignment.assignable_id;
            if (missingAssignableIds.indexOf(a_id) === -1) {
              missingAssignableIds.push(a_id);
            }
          }
          if (!u) {
            if (missingUserIds.indexOf(assignment.user_id) === -1) {
              assignment.user_id && missingUserIds.push(assignment.user_id);
            }
            if (!assignment.user_id) {
              // We have an unassigned work item
              _i.processAssignment(assignment);
              _i.assignments.push(assignment);
              _i.assignmentsById[assignment.id] = assignment;
              var ids = pluckUnique(a.assignments, 'id');
              if (!ids.indexOf(assignment.id.toString()) >= 0) {
                a.assignments.push(assignment);
              }
            }
          }
          return true;
        }
      });
    };
    var previousMissingAssignableIds;
    var getAssignablesPromise = function () {
      return new Promise(function (resolve) {
        var thereAreMissingAssignables =
          missingAssignableIds.length > 0 &&
          !_.isEqual(missingAssignableIds, previousMissingAssignableIds);
        if (thereAreMissingAssignables) {
          previousMissingAssignableIds = missingAssignableIds;
          var url = '/api/v1/projects/query';
          var params = {
            with_phases: true,
            with_archived: true,
            per_page: missingAssignableIds.length,
            id: missingAssignableIds,
          };
          postJSV1(
            url,
            params,
            function (data) {
              data.data.forEach(function (a) {
                gData.updateOnlyThisProject(a);
              });
              missingAssignableIds = [];
              processAssignments();
              getAssignablesPromise().then(resolve);
            },
            null,
            false,
            true
          );
        } else {
          resolve();
        }
      });
    };
    var previousMissingUserIds;
    var getUsersPromise = function () {
      return new Promise(function (resolve) {
        var thereAreMissingUsers =
          missingUserIds.length > 0 &&
          !_.isEqual(missingUserIds, previousMissingUserIds);
        if (thereAreMissingUsers) {
          previousMissingUserIds = missingUserIds;
          var url = '/api/v1/users/query';
          var params = {
            with_archived: true,
            per_page: missingUserIds.length,
            id: missingUserIds,
          };
          postJSV1(
            url,
            params,
            function (data) {
              data.data.forEach(function (u) {
                u.displayName = u.display_name;
                gData.updateUser(u);
              });
              missingUserIds = [];
              processAssignments();
              getUsersPromise().then(resolve);
            },
            null,
            false,
            true
          );
        } else {
          resolve();
        }
      });
    };
    processAssignments();
    return Promise.all([getAssignablesPromise(), getUsersPromise()]);
  };

  _i.removeAssignmentFromUserAndAssignable = function (assignment) {
    var u = _i.getUser(assignment.user_id),
      a = _i._assignablesAllById[assignment.assignable_id],
      i;

    while (a && a.parent_id) {
      a = _i._assignablesAllById[a.parent_id];
    }

    __A(a, 'assignable not found');

    if (u) {
      for (i = 0; u.assignments && i < u.assignments.length; i++) {
        if (u.assignments[i].id === assignment.id) {
          u.assignments.splice(i, 1);
          break;
        }
      }
    }

    for (i = 0; a.assignments && i < a.assignments.length; i++) {
      if (a.assignments[i].id === assignment.id) {
        a.assignments.splice(i, 1);
        break;
      }
    }
  };

  _i.updateUser = function (user) {
    _i.processUser(user);
    _i._usersById[user.id] = user;

    for (var i = 0; i < _i._users.length; ++i) {
      if (_i._users[i].id === user.id) {
        // Replace existing user
        _i._users[i] = user;
        return;
      }
    }
    // Add new user
    _i._users.push(user);
  };

  _i.deleteUser = function (user) {
    user.deleted = true;
    user.archived = true;
    user.type = 'User'; // NOTE backend was updated, response doesn't include the update user object
  };

  _i.setMe = function (user) {
    me = user;
  };

  _i.getMe = function () {
    if (me) {
      // Placeholders do not apply for getMe() use, so we can access usersById directly here.
      var user = _i._usersById[me.id];
      if (user && !user.username && me) user.username = me.username;
      return user ? user : me;
    } else {
      __A(false, 'me not initialized');
      return null;
    }
  };

  _i.isUserPartTime = function (user_id, assignment) {
    var user = _i.getUser(user_id);
    if (!user) return false;
    var date = new Date(assignment.starts_at);
    for (var i = 0; i < 7; i++) {
      if (
        _i.workWeek.isWorkday(date) &&
        user.getAvailabilityOn(date) < _i.accountSettings.hours_in_workday
      )
        return true;
      date.setDate(date.getDate() + 1);
    }
    return false;
  };

  _i.calculateAssignmentHours = function (assignment) {
    var user = _i.getUser(assignment.user_id);

    var ret = 0;
    var date = new Date(assignment.starts_at);
    var hours_in_workday = _i.accountSettings.hours_in_workday;
    while (date.getTime() < assignment.ends_at.getTime()) {
      if (_i.workWeek.isWorkday(date)) {
        if (assignment.allocation_mode == 'percent') {
          ret +=
            assignment.percent *
            (user ? user.getAvailabilityOn(date) : hours_in_workday);
        } else {
          // hours_per_day
          ret += assignment.hours_per_day;
        }
      }
      date.setDate(date.getDate() + 1);
    }
    return ret;
  };

  _i.numberOfHoursInAssignment = function (assignment) {
    switch (assignment.allocation_mode) {
      case 'fixed_hours':
        return assignment.fixed_hours;
      case 'hours_per_day':
      case 'percent':
        return _i.calculateAssignmentHours(assignment);
    }
  };

  _i.clearTimeEntries = function () {
    var users = _i.getUsers();
    for (var i = 0; i < users.length; ++i) {
      users[i].timeEntries = [];
      users[i].timeEntriesByDate = {};
    }
    _i.timeEntries = [];
    _i.timeEntriesByDate = {};
  };

  _i.addTimeEntry = function (entry, skipprocess) {
    var user = _i.getUser(entry.user_id);
    if (!skipprocess) _i.processTimeEntry(entry);

    var date = entry.date.toDateString();
    _i.timeEntries.push(entry);
    _i.timeEntriesByDate[date] = _i.timeEntriesByDate[date] || [];
    _i.timeEntriesByDate[date].push(entry);

    if (user) {
      user.timeEntries.push(entry);
      user.timeEntriesByDate[date] = user.timeEntriesByDate[date] || [];
      user.timeEntriesByDate[date].push(entry);
    }
  };

  _i.setTimeEntries = function (entries) {
    _i.clearTimeEntries();
    var entry;
    for (var i = 0; i < entries.length; ++i) {
      entry = entries[i];
      _i.addTimeEntry(entry);
    }
  };

  _i.deleteTimeEntry = function (entry) {
    var user = _i.getUser(entry.user_id);
    if (user) {
      var entries = user.timeEntries;
      if (entries.removeById(entry.id)) {
        // Remove from map by date
        var date = entry.date.toDateString();
        var byDate = user.timeEntriesByDate[date] || [];
        byDate.removeById(entry.id);
      }
    }
    entries = _i.timeEntries;
    if (entries.removeById(entry.id)) {
      // Remove from map by date
      var date = entry.date.toDateString();
      var byDate = _i.timeEntriesByDate[date] || [];
      byDate.removeById(entry.id);
    }
  };

  _i.setLeaveTypes = function (leaveTypes) {
    _i.clearLeaveTypes();
    _i._leaveTypes = leaveTypes;
    if (!_i._leaveTypes) _i._leaveTypes = [];
    for (var i = 0; i < _i._leaveTypes.length; ++i) {
      _i.processLeaveType(_i._leaveTypes[i]);
      _i._leaveTypesById[_i._leaveTypes[i].id] = _i._leaveTypes[i];
    }

    _i.updateAssignables();
  };

  _i.updateLeaveType = function (leaveType) {
    _i.processLeaveType(leaveType);
    _i._leaveTypesById[leaveType.id] = leaveType;

    for (var i = 0; i < _i._leaveTypes.length; ++i) {
      if (_i._leaveTypes[i].id === leaveType.id) {
        // Replace existing leaveType
        _i._leaveTypes[i] = leaveType;
        return;
      }
    }
    // Add new leaveType
    _i._leaveTypes.push(leaveType);
    _i.updateAssignables();
  };

  _i.deleteLeaveType = function (leaveType) {
    _i._leaveTypesById[leaveType.id] = null;
    if (_i._leaveTypes.removeById(leaveType.id)) {
      _i.updateAssignables();
    }
  };

  _i.setProjects = function (projects) {
    _i.clearProjects();

    // _i._projects contains only top level projectsm, not phases, so it should be set from
    // the top level list.
    _i._projects = projects;

    for (var i = 0; i < projects.length; ++i) {
      _i.processProject(projects[i]);
    }
    _i.updateAssignables();
  };

  // Optimization to update a single project without resetting entire assignables
  // collection.
  _i.updateOnlyThisProject = function (project) {
    _i.processProject(project, true);
    _i._assignablesAllById[project.id] = project;
    var found = false;
    for (var i = 0; i < _i._allprojects.length; ++i) {
      if (_i._allprojects[i].id === project.id) {
        // Replace existing project
        found = true;
        _i._allprojects[i] = project;
        break;
      }
    }
    if (!found) _i._allprojects.push(project);

    if (project.parent_id == null) {
      _i._projectsById[project.id] = project;
      for (var i = 0; i < _i._projects.length; ++i) {
        if (_i._projects[i].id === project.id) {
          // Replace existing project
          _i._projects[i] = project;
          return;
        }
      }
      // Add new project
      _i._projects.push(project);
    } else {
      var parent = _i.getProject(project.parent_id);
      if (parent) {
        for (var i = 0; i < parent.children.length; i++) {
          if (parent.children[i].id == project.id) {
            parent.children[i] = project;
            return;
          }
        }
        parent.children.push(project);
      }
    }
  };

  _i.updateProject = function (project) {
    ARC.expire('projects');
    _i.processProject(project, true);
    _i._assignablesAllById[project.id] = project;
    var found = false;
    for (var i = 0; i < _i._allprojects.length; ++i) {
      if (_i._allprojects[i].id === project.id) {
        // Replace existing project
        found = true;
        _i._allprojects[i] = project;
        break;
      }
    }
    if (!found) _i._allprojects.push(project);

    if (project.parent_id == null) {
      _i._projectsById[project.id] = project;
      for (var i = 0; i < _i._projects.length; ++i) {
        if (_i._projects[i].id === project.id) {
          // Replace existing project
          _i._projects[i] = project;
          _i.updateAssignables();
          return;
        }
      }
      // Add new project
      _i._projects.push(project);
    } else {
      var parent = _i.getProject(project.parent_id);
      if (parent) {
        for (var i = 0; i < parent.children.length; i++) {
          if (parent.children[i].id == project.id) {
            parent.children[i] = project;
            return;
          }
        }
        parent.children.push(project);
      }
    }
    _i.updateAssignables();
  };

  _i.addAssignment = function (assignment) {
    _i.setAssignmentsOfUsersAndAssignables([assignment], false);
  };

  _i.updateAssignment = function (assignment) {
    var a = _i.assignmentsById[assignment.id],
      key;
    __A(a, 'cannot update nonexistent assignment');
    for (key in a) {
      delete a[key];
    }
    for (key in assignment) {
      a[key] = assignment[key];
    }
    _i.processAssignment(a);
  };

  _i.deleteProject = function (project, fullDelete) {
    // remove all assignments belonging to this project or phase from gData
    var i, assignment;

    for (i = 0; fullDelete && i < _i.assignments.length; i++) {
      assignment = _i.assignments[i];
      if (project.id == assignment.assignable_id) {
        _i.removeAssignmentFromUserAndAssignable(assignment);
        _i.assignments.splice(i, 1);
        i--;
      }
    }

    _i._projectsById[project.id] = null;
    if (project.parent_id != null) {
      var parent = _i._projectsById[project.parent_id];
      for (var i = 0; parent && i < parent.children.length; i++) {
        if (parent.children[i].id == project.id) {
          parent.children.splice(i, 1);
          break;
        }
      }
    }

    _i.updateAssignables();
  };

  _i.deleteAssignment = function (assignment) {
    _i.assignmentsById[assignment.id] = null;
    _i.removeAssignmentFromUserAndAssignable(assignment);
    _i.assignments.removeById(assignment.id);
  };

  _i.deleteAssignmentSeries = function (repetitionId, deleteRepetitionsAfter) {
    var assignmentsToDelete = [];

    deleteRepetitionsAfter = deleteRepetitionsAfter || new Date(0);

    _i.assignments.forEach(function (a) {
      if (
        a.repetition_id == repetitionId &&
        a.starts_at >= deleteRepetitionsAfter
      ) {
        assignmentsToDelete.push(a);
      }
    });

    assignmentsToDelete.forEach(function (a) {
      _i.deleteAssignment(a);
    });
  };

  _i.addHistoryLogToFeed = function (log) {
    feed = {};
    feed.date = log.updated_at;
    feed.history_log = log;
    feed.status = null;
    _i.feeditems.push(feed);
  };

  _i.setHistoryLogs = function (logs) {
    _i.historyLogs = [];

    for (var i = 0; i < logs.length; i++) {
      if (_i.processHistoryLog(logs[i])) {
        _i.addHistoryLogToFeed(logs[i]);
        _i.historyLogs.push(logs[i]);
      }
    }
    // we want these sorted in descending order
    _i.historyLogs.sort(function (a, b) {
      var sa = parseRubyDateTime(a.updated_at, true);
      var sb = parseRubyDateTime(b.updated_at, true);
      if (sa == sb) return 0;
      return sa > sb ? -1 : 1;
    });
    _i.feeditems.sort(function (a, b) {
      var sa = parseRubyDateTime(a.date, true);
      var sb = parseRubyDateTime(b.date, true);
      sa = sa.getTime();
      sb = sb.getTime();
      if (sa == sb) {
        if (a.log_action == 'create') return 1;
        else if (b.log_action == 'create') return -1;
        else return 0;
      }
      return sa > sb ? -1 : 1;
    });
  };

  _i.setWorkWeek = function (workWeek) {
    _i.processWorkWeek(workWeek);
    _i.workWeek = workWeek;
  };

  _i.getTagsByNamespace = function (namespaceString) {
    var tags = _i.tagsByNameSpace[namespaceString];
    return tags ? tags : [];
  };

  // This assumes that the name == value
  _i.findTag = function (namespace, value) {
    return _i.findTagByNamespaceAndValue(namespace, value);
  };

  _i.findTagByNamespaceAndValue = function (namespace, value) {
    var set = _i.tagsByNameSpace[namespace];
    var match;
    _.each(set, function (tag) {
      if (tag.value == value) {
        match = tag;
      }
    });
    return match;
  };

  _i.findTagByNamespaceAndName = function (namespace, name) {
    var set = _i.tagsByNameSpace[namespace];
    var match;
    _.each(set, function (tag) {
      if (tag.name == name) {
        match = tag;
      }
    });
    return match;
  };

  _i.removeTagFromNamespace = function (tag, parentNamespace) {
    if (tag.namespace) {
      for (var i = 0; i < _i.tagsByNameSpace[tag.namespace].length; i++) {
        if (_i.tagsByNameSpace[tag.namespace][i].id == tag.id) {
          _i.tagsByNameSpace[tag.namespace].splice(i, 1);
          break;
        }
      }
    } else {
      if (tag.id && _i.tagsByNameSpace && _i.tagsByNameSpace[parentNamespace]) {
        _i.tagsByNameSpace[parentNamespace].forEach(function (currTag, index) {
          if (currTag.id === tag.id) {
            _i.tagsByNameSpace[parentNamespace].splice(index, 1);
            return;
          }
        });
      }
    }
  };

  _i.updateTag = function (tag) {
    if (tag.namespace && _i.tagsByNameSpace[tag.namespace]) {
      var t = -1;
      for (var i = 0; i < _i.tagsByNameSpace[tag.namespace].length; i++) {
        if (_i.tagsByNameSpace[tag.namespace][i].id == tag.id) {
          t = i;
          break;
        }
      }
      if (t > -1) {
        _i.tagsByNameSpace[tag.namespace][t].value = tag.value;
        _i.tagsByNameSpace[tag.namespace][t].name = tag.name;
      }
    }
  };

  _i.processTag = function (tag) {
    if (tag.value == null) tag.value = '';
    if (tag.name == null) tag.name = '';
    if (tag.namespace == '' || tag.namespace == null) {
      // TODO Since introducing user and project tags, needs to figure out what to do with this
      _i.tags.push(tag);
    }
    if (tag.namespace) {
      if (!_i.tagsByNameSpace[tag.namespace]) {
        _i.tagsByNameSpace[tag.namespace] = [];
      }
      _i.tagsByNameSpace[tag.namespace].push(tag);
    }
  };

  _i.processAllTags = function (data) {
    for (var i = 0; i < data.length; i++) {
      tag = data[i];
      if (tag.value == null) tag.value = '';
      if (tag.name == null) tag.name = '';
      if (tag.namespace == '' || tag.namespace == null) _i.tags.push(tag);
      if (tag.namespace) {
        if (!_i.tagsByNameSpace[tag.namespace]) {
          _i.tagsByNameSpace[tag.namespace] = [];
        }
        _i.tagsByNameSpace[tag.namespace].push(tag);
      }
    }
  };

  _i.addTagsForNamespace = function (data, namespaceString) {
    _i.tagsByNameSpace[namespaceString] = data;
  };

  _i.addCustomFields = function (data, namespace) {
    if (namespace == 'assignables') {
      _i.projectCustomFields = data;
    } else if (namespace == 'users') {
      _i.peopleCustomFields = data;
    } else {
      __A(
        false,
        'custom fields supported only for users/assignables namespaces'
      );
    }
    _.each(data, function (field) {
      if (
        field.options &&
        (field.data_type == 'selection_list' ||
          field.data_type == 'multiple_choice_selection_list')
      ) {
        field.options.sort(function (a, b) {
          a = String(a).toLowerCase();
          b = String(b).toLowerCase();
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      }
    });
  };

  _i.getCustomFields = function (namespace) {
    var fields = null;
    if (namespace == 'assignables') {
      fields = _i.projectCustomFields;
    } else if (namespace == 'users') {
      fields = _i.peopleCustomFields;
    } else {
      __A(
        false,
        'custom fields supported only for users/assignables namespaces'
      );
    }
    return fields;
  };

  _i.processCustomField = function (field) {
    var existingField = _i.findCustomField(field.id, field.namespace);
    if (existingField) {
      _.assign(existingField, field);
    } else {
      if (field.namespace == 'assignables') {
        _i.projectCustomFields.push(field);
      } else if (field.namespace == 'users') {
        _i.peopleCustomFields.push(field);
      }
    }
  };

  _i.findCustomField = function (id, namespace) {
    var field = null;
    if (namespace == 'assignables') {
      field = _.find(_i.projectCustomFields, { id: id });
    } else if (namespace == 'users') {
      field = _.find(_i.peopleCustomFields, { id: id });
    }
    return field;
  };

  _i.removeCustomField = function (field) {
    if (field.namespace == 'assignables') {
      _.remove(_i.projectCustomFields, { id: field.id });
    } else if (field.namespace == 'users') {
      _.remove(_i.peopleCustomFields, { id: field.id });
    } else {
      __A(
        false,
        'custom fields supported only for users/assignables namespaces'
      );
    }
  };

  var assignableCustomFieldValueObjectsByFieldableId = {};
  var userCustomFieldValueObjectsByFieldableId = {};

  // Returns values for a fieldable object. If there are none, returns an empty array.
  _i.getCustomFieldValueObjects = function (custom_fieldable_id, namespace) {
    var valueObjects = null;
    if (namespace == 'assignables') {
      valueObjects =
        assignableCustomFieldValueObjectsByFieldableId[custom_fieldable_id];
    } else if (namespace == 'users') {
      valueObjects =
        userCustomFieldValueObjectsByFieldableId[custom_fieldable_id];
    }
    return valueObjects;
  };

  _i.getValuesForCustomField = function (
    custom_fieldable_id,
    custom_field_id,
    namespace,
    fallbackToDefault
  ) {
    var attrs = { custom_field_id: custom_field_id },
      valueObjects = _.filter(
        _i.getCustomFieldValueObjects(custom_fieldable_id, namespace),
        attrs
      );

    var values =
      valueObjects && valueObjects.length > 0
        ? _.pluck(valueObjects, 'value')
        : [];
    if (fallbackToDefault && (!values || values.length == 0)) {
      values.push(_i.findCustomField(custom_field_id, namespace).default_value);
    }

    return values;
  };

  _i.setCustomFieldValueObjects = function (
    custom_fieldable_id,
    namespace,
    valueObjects
  ) {
    if (namespace == 'assignables') {
      assignableCustomFieldValueObjectsByFieldableId[custom_fieldable_id] =
        valueObjects;
    } else if (namespace == 'users') {
      userCustomFieldValueObjectsByFieldableId[custom_fieldable_id] =
        valueObjects;
    }
  };

  _i.setPlaceholderResources = function (data) {
    _i.clearPlaceholders();
    if (data) {
      _i.placeholderResources = [];
      for (var i = 0; i < data.length; ++i) {
        _i.processPlaceholderResource(data[i]);
      }
    }
    _i.placeholderResources.sort(compareUsersByDisplayName);
  };

  _i.getPlaceholderResources = function () {
    return _i.placeholderResources;
  };

  _i.processPlaceholderResource = function (resource, sort) {
    resource.thumbnail = resource.thumbnail
      ? resource.thumbnail
      : DEFAULT_PERSON_THUMBNAIL_URL;
    var i, a;
    User.call(resource);
    for (i = 0; resource.assignments && i < resource.assignments.length; ++i) {
      _i.processAssignment(resource.assignments[i]);
      _i.assignments.push(resource.assignments[i]);
    }

    // On the schedule, placeholders appear with users, which may be filtered by tags.
    // Placeholders do not support tags at present - set placeholder tags to empty
    // collection so tag filtering works seamlessly for users and placeholders.
    resource.tags = [];

    // Set locationValue to be the same as location. This serves as an adapter so V0 (users)
    // and V1 (placeholders) API results can be treated the same.
    resource.locationValue = resource.location;

    var existingResource = _i.placeholdersById[resource.id];
    if (existingResource) {
      for (var i = 0; i < _i.placeholderResources.length; ++i) {
        if (_i.placeholderResources[i].id === resource.id) {
          // Replace existing resource.
          _i.placeholderResources[i] = resource;
          break;
        }
      }
    } else {
      _i.placeholderResources.push(resource);
    }
    _i.placeholdersById[resource.id] = resource;

    // If this processing happens after create/update of individual resource, we
    // need to re-sort the list.
    if (sort) {
      _i.placeholderResources.sort(compareUsersByDisplayName);
    }
  };

  _i.removePlaceholderResource = function (resource) {
    _.remove(_i.placeholderResources, { id: resource.id });
    _i.placeholdersById[resource.id] = null;
  };

  _i.setSsoSettings = function (data) {
    _i.ssoSettings = data;
  };

  _i.updateSsoSettings = function (settings) {
    _i.ssoSettings = [];
    _i.ssoSettings.push(settings);
  };

  _i.getSsoSettings = function () {
    return _i.ssoSettings;
  };

  _i.resetBranding = function () {
    _i.accountSettings.enterprise_header_logo_url = null;
    _i.accountSettings.enterprise_header_bgcolor = null;
    _i.accountSettings.enterprise_header_color = null;
    _i.accountSettings.enterprise_header_shadow_enabled = null;
    var uname =
      (window.whoami.first_name || '') + ' ' + (window.whoami.last_name || '');
    var cname = window.accountSettings.org_name;
    updatePageHeader('pageHdr', 7, uname, cname);
  };

  _i.updateBranding = function (brand) {
    if (brand.enterprise_header_logo_url) {
      _i.accountSettings.enterprise_header_logo_url =
        brand.enterprise_header_logo_url;
    }
    if (brand.enterprise_header_bgcolor) {
      _i.accountSettings.enterprise_header_bgcolor =
        brand.enterprise_header_bgcolor;
    }
    if (brand.enterprise_header_color) {
      _i.accountSettings.enterprise_header_color =
        brand.enterprise_header_color;
    }
    if (brand.enterprise_header_shadow_enabled != null) {
      _i.accountSettings.enterprise_header_shadow_enabled =
        brand.enterprise_header_shadow_enabled == 'true';
    }
    var uname =
      (window.whoami.first_name || '') + ' ' + (window.whoami.last_name || '');
    var cname = window.accountSettings.org_name;
    updatePageHeader('pageHdr', 7, uname, cname);
  };

  _i.setAccountSettings = function (accountSettings) {
    // parse out the settings we need, and add them to the object
    if (accountSettings.settings != null) {
      for (i = 0; i < accountSettings.settings.length; i++) {
        var setting = accountSettings.settings[i];

        if (setting.value == null) {
          continue;
        }

        if (setting.name == 'working days') {
          accountSettings.working_days = setting.value;
        } else if (setting.name == 'company name') {
          accountSettings.company_name = setting.value;
        } else if (setting.name == 'hours in workday') {
          accountSettings.hours_in_workday = setting.value;
        } else if (setting.name == 'first working day') {
          accountSettings.first_working_day = setting.value;
        } else if (setting.name == 'activation date') {
          accountSettings.activation_date = setting.value;
        } else if (setting.name == 'time entry max percent') {
          accountSettings.time_entry_max_percent = setting.value;
        } else if (setting.name == 'time entry min step percent') {
          accountSettings.time_entry_min_step_percent = setting.value;
        } else if (setting.name == 'allow_bulk_confirm_time_entries') {
          accountSettings.allow_bulk_confirm_time_entries = setting.value;
        } else if (setting.name == 'minimum hours reported') {
          accountSettings.min_hours_report = setting.value;
        } else if (setting.name == 'show progress') {
          accountSettings.showprogress = setting.value;
        } else if (setting.name == 'calc incurred using') {
          accountSettings.incurredhours_using = setting.value;
        } else if (setting.name == 'calc incurred expenses using') {
          accountSettings.incurredexpenses_using = setting.value;
        } else if (setting.name == 'use weekend_holiday hours') {
          accountSettings.weekend_hours = setting.value;
        } else if (setting.name == 'locale') {
          accountSettings.locale = setting.value;
        } else if (setting.name == 'allocate unit') {
          accountSettings.allocateunit = setting.value;
        } else if (setting.name == 'billing email') {
          accountSettings.billing_email = setting.value;
        } else if (setting.name == 'billing address') {
          accountSettings.billing_address = setting.value;
        } else if (setting.name == 'time_entry_approvals') {
          accountSettings.time_entry_approvals = setting.value;
        } else if (setting.name == 'expense_approvals') {
          accountSettings.expense_approvals = setting.value;
        } else if (setting.name == 'project_based_approvals') {
          accountSettings.project_based_approvals = setting.value;
        } else if (setting.name == 'approval_notifications_enabled') {
          accountSettings.approval_notifications_enabled = setting.value;
        } else if (setting.name == 'lock_entries_upon_approval') {
          accountSettings.lock_entries_upon_approval = setting.value;
        } else if (setting.name == 'approver_unlock_approval_enabled') {
          accountSettings.approver_unlock_approval_enabled = setting.value;
        } else if (setting.name == 'enterprise-portal-url') {
          accountSettings.enterprise_portal_url = setting.value;
        } else if (setting.name == 'enterprise-support-url') {
          accountSettings.enterprise_support_url = setting.value;
        } else if (setting.name == 'enterprise-header-logo-url') {
          accountSettings.enterprise_header_logo_url = setting.value;
        } else if (setting.name == 'enterprise-signout-url') {
          accountSettings.enterprise_signout_url = setting.value;
        } else if (setting.name == 'enterprise-header-bgcolor') {
          accountSettings.enterprise_header_bgcolor = setting.value;
        } else if (setting.name == 'enterprise-header-color') {
          accountSettings.enterprise_header_color = setting.value;
        } else if (setting.name == 'enterprise-header-shadow-enabled') {
          accountSettings.enterprise_header_shadow_enabled =
            setting.value == 'true';
        } else if (setting.name == 'enterprise-view-resource-requests-url') {
          accountSettings.enterprise_view_resource_requests_url = setting.value;
        } else if (setting.name == 'enterprise-create-resource-requests-url') {
          accountSettings.enterprise_create_resource_requests_url =
            setting.value;
        } else if (setting.name == 'restricted_tag_mode') {
          accountSettings.restricted_tag_mode =
            '0' /* forced to be always off instead of setting.value */;
        } else if (setting.name == 'developer_contact_email') {
          accountSettings.developer_contact_email = setting.value;
        } else if (setting.name == 'team_size') {
          accountSettings.team_size = setting.value;
        } else if (setting.name == 'locale_currency_symbol') {
          accountSettings.locale_currency_symbol = setting.value;
          if (setting.value) {
            I18n.lookup('number.currency.format').unit = setting.value;
          }
        } else if (setting.name == 'notifications_enabled') {
          accountSettings.notifications_enabled = setting.value == '1';
        } else if (setting.name == 'calendars_enabled') {
          accountSettings.calendars_enabled = setting.value == '1';
        } else if (setting.name == 'today_is_incurred') {
          accountSettings.today_is_incurred = setting.value;
        }
        //Pass all the tags into the tag pool
        _i.processAllTags(accountSettings.settings);
      }

      if (!accountSettings.allocateunit) {
        accountSettings.allocateunit = 'percentage';
      }
    }

    _i.setWorkWeek({
      firstDay: accountSettings.first_working_day,
      dayMask: accountSettings.working_days,
    });

    AccountSettings.call(accountSettings);

    _i.accountSettings = accountSettings;
  };

  _i.getSubscription = function (opts, callback) {
    var queryParam = opts.planLimits ? '?plan_limits=true' : '';
    var url =
      SERVICE_BASE_URL +
      '/subscriptions/' +
      window.accountSettings.subscription.id +
      queryParam;
    new getJSON(url, callback);
  };

  _i.updateHolidays = function (obj) {
    if (!_i.accountSettings) return;
    _i.accountSettings.holidays = obj;
    _i.accountSettings.holidaysByDate = [];
    for (var i = 0; i < _i.accountSettings.holidays.length; ++i) {
      var h = _i.accountSettings.holidays[i];
      h.date = parseRubyDate(h.date);
      _i.accountSettings.holidaysByDate[h.date.toDateString()] = h;
    }
  };

  _i.processTimeEntry = function (entry) {
    entry.date = parseRubyDate(entry.date);
  };

  _i.getDisplayName = function (user) {
    if (user.displayName) {
      return user.displayName;
    } else if (user.display_name) {
      return user.display_name;
    } else if (user.name) {
      return user.name;
    } else {
      var s = '';
      if (user.first_name) {
        s += user.first_name;
      }
      if (user.last_name) {
        if (user.first_name) {
          s += ' ';
        }
        s += user.last_name;
      }
      return s;
    }
  };

  _i.processUser = function (user) {
    var i, a;
    User.call(user);
    if (user.firstName == null) {
      user.firstName = '';
    }
    user.displayName = _i.getDisplayName(user);

    for (i = 0; user.assignments && i < user.assignments.length; ++i) {
      _i.processAssignment(user.assignments[i]);
      _i.assignments.push(user.assignments[i]);
    }
    for (i = 0; user.availabilities && i < user.availabilities.length; ++i) {
      a = user.availabilities[i];
      a.starts_at_as_date = parseRubyDate(a.starts_at);
      a.ends_at_as_date = parseRubyDate(a.ends_at);
    }
    user.clearEmploymentDateCache();
  };

  _i.processLeaveType = function (leaveType) {
    LeaveType.call(leaveType);
  };

  _i.processProject = function (project, force) {
    var children;

    if (!project.__processed || force) {
      Project.call(project);
      if (project.starts_at && typeof project.starts_at == 'string')
        project.starts_at = parseRubyDate(project.starts_at);
      if (project.ends_at && typeof project.ends_at == 'string')
        project.ends_at = parseRubyDate(project.ends_at);
      if (
        project.client_tag &&
        project.client_tag.displayName != null &&
        project.client_tag.displayName != ''
      ) {
        project.client = project.client_tag.displayName;
      } else if (project.client == null) {
        project.client = '';
      }
      if (!project.project_state) project.project_state = '';
      if (project.tags == null) project.tags = [];
      project.__processed = true;

      if (project.bill_rates && project.bill_rates.data.length > 0) {
        _i.setBillRates(project.id, project.bill_rates.data);
      }
    }

    // support legacy and V1 api formats
    children = project.children
      ? project.children.data
        ? project.children.data
        : project.children
      : [];

    for (var i = 0; children && i < children.length; i++) {
      children[i].project_state = project.project_state;
      children[i].client = project.client;
      _i.processProject(children[i], force);
    }

    // replace V1 nested collection structure with just children
    project.children = children;

    _i._projectsById[project.id] = project;
  };

  _i.processAssignment = function (assignment) {
    if (
      typeof assignment.starts_at == 'string' &&
      typeof assignment.ends_at == 'string'
    ) {
      if (assignment.all_day_assignment) {
        assignment.starts_at = parseRubyDate(assignment.starts_at);
        assignment.ends_at = parseRubyDate(assignment.ends_at);
      } else {
        __A(false, 'only all day assignments supported');

        // BUG: parseRubyDateTime actually reduces the dates by 1, so this is
        // buggy. It was not noticed because all_day_assignment is always true
        // and doesn't seem to be used anywhere else. Consider either removing
        // all_day_assignment or making this work.
        // assignment.starts_at = parseRubyDateTime(assignment.starts_at);
        // assignment.ends_at = parseRubyDateTime(assignment.ends_at);
      }
    }
  };

  _i.processWorkWeek = function (workWeek) {
    WorkWeek.call(workWeek);
  };

  _i.processHistoryLog = function (historyLog) {
    HistoryLog.call(historyLog);
    historyLog.log_user = _i.getUser(historyLog.user_id);
    return historyLog.log_action != 'ignore';
  };

  _i.arrayContains = function (a, obj) {
    var i = a.length;
    while (i--) {
      if (a[i] == obj) {
        return true;
      }
    }
    return false;
  };

  _i.setParticipantsOnAssignable = function (proj) {
    // build participants for this project
    var proj_ids = [];
    proj_ids.push(proj.id);
    for (var i = 0; i < proj.children.length; i++) {
      proj_ids.push(proj.children[i].id);
    }
    var users = _i.getUsers();
    for (var i = 0; i < users.length; i++) {
      for (var j = 0; j < users[i].assignments.length; j++) {
        if (_i.arrayContains(proj_ids, users[i].assignments[j].assignable_id)) {
          proj.participants.push(users[i]);
          break;
        }
      }
    }
  };

  _i.getClientListFromProjects = function () {
    var clients = [];
    for (var i = 0; i < _i._projects.length; i++) {
      if (_i._projects[i].client)
        clients.push(_i._projects[i].client.ltrim().rtrim());
    }
    clients.sort(function (a, b) {
      var sa = a.toLowerCase();
      var sb = b.toLowerCase();
      if (sa == sb) return 0;
      return sa < sb ? -1 : 1;
    });
    var index = 1;
    while (index < clients.length) {
      if (clients[index].toLowerCase() == clients[index - 1].toLowerCase()) {
        clients.splice(index, 1);
      } else {
        index++;
      }
    }
    return clients;
  };

  _i.addResourceRequests = function (rrData) {
    _i.resourceRequests || (_i.resourceRequests = []);
    _i.resourceRequests.push.apply(_i.resourceRequests, rrData);
  };

  _i.getUserAndProjectDataSize = function () {
    var userCount = window.totalUsers,
      projectCount = window.totalProjects;

    if (!(userCount && projectCount)) return;

    var orgMagnitude = userCount + projectCount,
      dataSize;

    // switch(true) is required for a range
    switch (true) {
      case orgMagnitude >= 100 && orgMagnitude < 250: {
        dataSize = 'projectsPlusUsers100to250';
        break;
      }
      case orgMagnitude >= 250 && orgMagnitude < 750: {
        dataSize = 'projectsPlusUsers250to750';
        break;
      }
      case orgMagnitude >= 750 && orgMagnitude < 1500: {
        dataSize = 'projectsPlusUsers750to1500';
        break;
      }
      case orgMagnitude >= 1500: {
        dataSize = 'projectsPlusUsers1500Plus';
        break;
      }
      default: {
        dataSize = 'projectsPlusUsersBelow100';
      }
    }
    return dataSize;
  };

  _i.getStatusOptions = function () {
    return window.accountSettings.status_options;
  };

  _i.getStatusOptionForAssignment = function (assignment) {
    var defaultStatusOption = {
        label: '[none]',
        color: 'gray',
      },
      statusOptions = _i.getStatusOptions();

    // If the account has no status options, return the default status
    if (!statusOptions.length) {
      return defaultStatusOption;
    }
    // If the assignment does not have a status assigned, return the first option from the planned stage or the default status option
    if (!assignment.status_option_id) {
      var activeStatusOptions = _.filter(statusOptions, {
        deleted_at: null,
        stage: 'planned',
      });
      var firstStatusOption = _.reduce(
        activeStatusOptions,
        function (prev, curr) {
          return prev.order < curr.order ? prev : curr;
        }
      );
      if (firstStatusOption) {
        return firstStatusOption;
      } else {
        return defaultStatusOption;
      }
    }
    // If the assignment has a status assigned, return the matching status
    var statusOption = _.find(statusOptions, {
      id: assignment.status_option_id,
    });

    return statusOption;
  };
}

function service() {
  var _i = this;
  _i.loadingAllAssignables = false;

  gData = new data();
  gDataLoading = false;

  _i.SERVER_ADDRESS = SERVICE_BASE_URL;
  _i.SERVER_ADDRESS_V1 = API_BASE_URL;

  _i.createAccount = function (data, callback, errorcallback) {
    var url = _i.SERVER_ADDRESS + '/organizations';
    var payload = {};
    if (data.firstName) {
      payload['first_name'] = data.firstName;
    }
    if (data.lastName) {
      payload['last_name'] = data.lastName;
    }
    if (data.email) {
      payload['email'] = data.email;
    }
    if (data.company) {
      payload['company'] = data.company;
    }
    if (data.phoneNumber) {
      payload['phone_number'] = data.phoneNumber;
    }
    if (data.projectedTeamSize) {
      payload['team_size'] = data.projectedTeamSize;
    }
    if (data.howFound) {
      payload['how_found'] = data.howFound;
    }
    if (data.password) {
      payload['password'] = data.password;
    }
    if (data.referredBy) {
      payload['referred_by'] = data.referredBy;
    }
    if (data.subscribe) {
      payload['subscribe'] = data.subscribe;
    }
    if (data.captcha) {
      payload['captcha'] = data.captcha;
    }
    if (data.populate_sample_data) {
      payload['populate_sample_data'] = data.populate_sample_data;
    }
    postJSV1(
      url,
      payload,
      function (response) {
        gData.setStatus(response.data);
        callback && callback(response.data);
      },
      errorcallback,
      true
    );
  };

  _i.replaceAccountOwnerID = function (id, callback) {
    var url = _i.SERVER_ADDRESS + '/organizations/accountowner';
    putJSV1(url, { owner: id }, function () {
      callback && callback();
      gData._users.forEach(function (u) {
        if (u.id == id) {
          u.account_owner = true;
        } else {
          u.account_owner = false;
        }
      });
    });
  };

  _i.replaceAccountName = function (name, callback) {
    var url = _i.SERVER_ADDRESS + '/organizations/accountname';
    putJSV1(url, { newname: name }, function (data) {
      callback && callback(data);
    });
  };

  function setGDataAccountSettingsOnUpdate(name, value) {
    var setting;
    switch (name) {
      case 'allocate unit':
        setting = 'allocateunit';
        break;
      case 'calc incurred using':
        setting = 'incurredhours_using';
        break;
      case 'calc incurred expenses using':
        setting = 'incurredexpenses_using';
        break;
      case 'use weekend_holiday hours':
        setting = 'weekend_hours';
        break;
      case 'minimum hours reported':
        setting = 'min_hours_report';
        break;
      case 'show progress':
        setting = 'showprogress';
        break;
      case 'calendars_enabled':
        setting = 'calendars_enabled';
        break;
      default:
        setting = name.replace(/ /gi, '_');
    }
    if (gData.accountSettings[setting]) {
      gData.accountSettings[setting] = value;
    }
  }

  _i.updateAccountSetting = function (name, value, callback) {
    var url = _i.SERVER_ADDRESS + '/organizations/update_settings';
    var payload = {};
    payload['namespace'] = 'organization settings';
    payload['name'] = name;
    payload['value'] = value;

    putJSV1(url, payload, function (data) {
      callback && callback(data);
      setGDataAccountSettingsOnUpdate(name, value);
    });
  };

  _i.addUser = function (data, callback, errorCallback) {
    var url = _i.SERVER_ADDRESS + '/users/create',
      params = {};

    if (data.firstName) {
      params.first_name = data.firstName;
    }
    if (data.lastName) {
      params.last_name = data.lastName;
    }
    if (data.licenseType) {
      params.license_type = data.licenseType;
    }
    if (data.email) {
      params.email = data.email;
    }
    if (data.mobile_phone) {
      params.mobile_phone = data.mobile_phone;
    }
    if (data.office_phone) {
      params.office_phone = data.office_phone;
    }
    if (data.employee_number) {
      params.employee_number = data.employee_number;
    }
    if (data.invitationemail) {
      params.invitationemail = data.invitationemail;
    }
    if (data.note) {
      params.note = data.note;
    }
    if (data.discipline) {
      params.discipline = JSON.stringify(data.discipline);
    }
    if (data.role) {
      params.role = JSON.stringify(data.role);
    }
    if (data.user_type_id) {
      params.user_type_id = data.user_type_id;
    }
    if (data.location) {
      params.location = JSON.stringify(data.location);
    }
    if (data.tags) {
      params.tags = JSON.stringify(data.tags);
    }
    if (data.custom_field_values) {
      params.custom_field_values = JSON.stringify(data.custom_field_values);
    }
    if (data.billability_target || data.billability_target == 0) {
      params.billability_target = data.billability_target;
    }
    if (data.billrate || data.billrate == 0) {
      params.billrate = data.billrate;
    }
    // null means no date
    if (data.hire_date || data.hire_date === null) {
      params.hire_date = data.hire_date;
    }
    if (data.termination_date || data.termination_date === null) {
      params.termination_date = data.termination_date;
    }

    postJS(
      url,
      params,
      function (response) {
        gData.updateUser(response);
        ARC.expire('users');
        callback && callback(response);
      },
      errorCallback
    );
  };

  _i.finishUserInvite = function (data, callback) {
    var url = _i.SERVER_ADDRESS + '/users/finishinvite',
      params = {};

    if (data.password) {
      params.password = data.password;
    }
    if (data.token) {
      params.token = data.token;
    }
    if (data.subscribe) {
      params.subscribe = data.subscribe;
    }
    postJS(url, params, function (data) {
      ARC.expire('users');
      callback && callback(data);
    });
  };

  _i.passwordresetfinalize = function (data, callback, errorCallback) {
    var url = _i.SERVER_ADDRESS_V1 + '/logins/passwordresetfinalize',
      params = {};

    params.username = data.email;
    params.password = data.password;
    params.guid = data.guid;

    postJS(
      url,
      params,
      function (obj) {
        callback && callback(obj);
      },
      function (err) {
        errorCallback && errorCallback(err);
      }
    );
  };

  _i.passwordreset = function (data, callback, errorCallback) {
    var url = _i.SERVER_ADDRESS_V1 + '/logins/passwordreset',
      params = {};

    params.username = data.email;

    postJS(
      url,
      params,
      function (obj) {
        callback && callback(obj);
      },
      function (err) {
        errorCallback && errorCallback(err);
      }
    );
  };

  _i.resendInvite = function (id, callback, email) {
    var url = _i.SERVER_ADDRESS + '/users/' + id + '/resendinvite';
    var payload = {};
    if (email) payload['email'] = email;
    postJSV1(url, payload, function (data) {
      callback && callback(data);
    });
  };

  _i.updatewithinvite = function (data, callback) {
    var url = _i.SERVER_ADDRESS + '/users/' + data.id + '/updatewithinvite';
    var payload = {};
    if (data.firstName) {
      payload['first_name'] = data.firstName;
    }
    if (data.lastName) {
      payload['last_name'] = data.lastName;
    }
    if (data.email) {
      payload['email'] = data.email;
    }
    if (data.note) {
      payload['note'] = data.note;
    }
    postJSV1(url, payload, function (obj) {
      gData.updateUser(obj, _i.userUpdateError);
      ARC.expire('users');
      callback && callback(obj);
    });
  };

  _i.createAvailability = function (data, user_id, callback, errorCallback) {
    var url = _i.SERVER_ADDRESS + '/users/' + user_id + '/availabilities';
    var payload = {};
    if (data.starts_at) {
      payload['starts_at'] = data.starts_at;
    }
    if (data.ends_at) {
      payload['ends_at'] = data.ends_at;
    }
    payload['day0'] = data.day0;
    payload['day1'] = data.day1;
    payload['day2'] = data.day2;
    payload['day3'] = data.day3;
    payload['day4'] = data.day4;
    payload['day5'] = data.day5;
    payload['day6'] = data.day6;

    postJSV1(
      url,
      payload,
      function (obj) {
        callback && callback(obj);
        ARC.expire('users');
      },
      errorCallback
    );
  };

  _i.updateAvailability = function (
    data,
    user_id,
    availability_id,
    callback,
    errorCallback
  ) {
    var url =
      _i.SERVER_ADDRESS_V1 +
      '/users/' +
      user_id +
      '/availabilities/' +
      availability_id;
    putJSV1(
      url,
      data,
      function (response) {
        callback && callback(response);
      },
      errorCallback
    );
  };

  _i.deleteAvailability = function (
    user_id,
    availability_id,
    callback,
    errorCallback
  ) {
    var url =
      _i.SERVER_ADDRESS_V1 +
      '/users/' +
      user_id +
      '/availabilities/' +
      availability_id;
    deleteJSV1(
      url,
      function (response) {
        callback && callback(response);
      },
      errorCallback
    );
  };

  _i.getAllTags = function (callback) {
    // Reset tags by namespace to avoid duplication.
    gData.tagsByNameSpace = [];
    new injectJS(
      _i.SERVER_ADDRESS + '/tags',
      function (data) {
        gData.processAllTags(data);
        callback && callback(data);
      },
      null,
      false,
      false
    );
  };

  _i.removeTagFromNamespace = function (
    data,
    callback,
    errorCallback,
    parentNamespace
  ) {
    var url =
      _i.SERVER_ADDRESS + '/tags/delete_tag?id=' + encodeURIComponent(data.id);
    deleteJSV1(
      url,
      function () {
        gData.removeTagFromNamespace(data, parentNamespace);
        callback && callback();
      },
      errorCallback
    );
  };

  _i.updateCategoryTag = function (data, callback) {
    var url = _i.SERVER_ADDRESS + '/tags/update_category_tag',
      params = {};

    params.id = data.id;
    params.namespace = data.namespace;
    params.name = data.name;
    params.value = data.value || '';

    putJS(url, params, function (obj) {
      gData.updateTag(obj);
      callback && callback(obj);
    });
  };

  _i.createCategoryTag = function (data, callback) {
    var url = _i.SERVER_ADDRESS + '/tags/create_category_tag',
      params = {};

    params.namespace = data.namespace;
    params.name = data.name;
    params.value = data.value;

    postJS(url, params, function (obj) {
      gData.processTag(obj);
      callback && callback(obj);
    });
  };

  _i.getTagsWithNamespace = function (namespaceString, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/tags?namespace=' + namespaceString;
    var pagingComplete = function (tags) {
      gData.addTagsForNamespace(tags, namespaceString);
      callback && callback(tags);
    };

    _i.getCollectionByPage(url, pagingComplete, onError);
  };

  _i.getStatusOptions = function (callback, onError, opts) {
    // status options are now available in window.accountSettings
    setTimeout(function () {
      callback && callback(window.accountSettings.status_options);
    }, 1);
  };

  _i.createStatusOption = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/status_options';

    postJSV1(
      url,
      data,
      function (response) {
        window.accountSettings.status_options.push(response);
        callback && callback(response);
      },
      onError
    );
  };

  _i.updateStatusOption = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/status_options/' + data.id;

    putJSV1(
      url,
      data,
      function (response) {
        var i = -1;
        while (++i < window.accountSettings.status_options.length) {
          if (window.accountSettings.status_options[i].id === response.id)
            break;
        }
        window.accountSettings.status_options[i] = response;
        callback && callback(response);
      },
      onError
    );
  };

  _i.deleteStatusOption = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/status_options/' + data.id;

    deleteJS(
      url,
      function (response) {
        var i = -1;
        while (++i < window.accountSettings.status_options.length) {
          if (window.accountSettings.status_options[i].id === data.id) {
            window.accountSettings.status_options.splice(i, 1);
            break;
          }
        }
        callback && callback();
      },
      onError
    );
  };

  _i.getPotentialProjectOwners = function (callback, onError, opts) {
    var url = _i.SERVER_ADDRESS_V1 + '/users/potential_owners';

    if (window.urlParam('id')) {
      url += '?assignable_id=' + window.urlParam('id');
    }

    var pagingComplete = function (resources) {
      gData.setPotentialProjectOwners(resources || []);
      callback && callback(resources);
    };

    _i.getCollectionByPage(url, pagingComplete, onError, true, opts?.perPage);
  };

  _i.getCollectionByPage = function (
    url,
    callback,
    onError,
    allowCache,
    perPage
  ) {
    var collection = [],
      pathname = new URL(url).pathname,
      serverAddressPart = url.substr(0, url.indexOf(pathname)),
      urlObj = new URL(url);

    // TODO Use URL.searchParams (requires a non-trivial polyfill)

    if (url.indexOf('per_page') < 0) {
      // default per_page to 100
      if (url.indexOf('?') < 0) {
        url += `?per_page=${perPage || 100}`;
      } else {
        url += `&per_page=${perPage || 100}`;
      }
    }

    var getPage = function (pageUrl) {
      if (pageUrl.indexOf('/') === 0) {
        var urlPrefix = window.isIframeView
          ? window.APP_ENDPOINT
          : serverAddressPart;
        // paging.next may be a relative URL. use original serverAddressPart
        pageUrl = urlPrefix + pageUrl;
      }
      new injectJS(
        pageUrl,
        function (obj) {
          collection = collection.concat(obj.data);
          // for project owners in project setting page, backend returns not
          // only data but also editors and owner
          if (obj.editors) {
            collection = collection.concat(obj.editors);
          }
          // we still need the owner because we need a default vaule for the
          // project owner drop down
          if (obj.owner) {
            collection = collection.concat(obj.owner);
          }
          if (obj.paging && obj.paging.next) {
            getPage(obj.paging.next);
          } else {
            callback && callback(collection);
          }
        },
        onError,
        false,
        allowCache
      );
    };

    getPage(url);
  };

  _i.getPlaceholderResources = function (
    callback,
    includeCustomFieldValues,
    onError
  ) {
    var url = _i.SERVER_ADDRESS_V1 + '/placeholder_resources?';
    if (includeCustomFieldValues) {
      url += '&fields=custom_field_values';
    }

    var pagingComplete = function (resources) {
      gData.setPlaceholderResources(resources || []);
      callback && callback(resources);
    };

    _i.getCollectionByPage(url, pagingComplete, onError, true);
  };

  _i.getSsoSettings = function (callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/sso_settings?';

    var pagingComplete = function (settings) {
      gData.setSsoSettings(settings || []);
      callback && callback(settings);
    };

    _i.getCollectionByPage(url, pagingComplete, onError, false);
  };

  _i.getSsoSettingsPromise = function () {
    return new Promise(function (resolve, reject) {
      _i.getSsoSettings(resolve, reject);
    });
  };

  _i.createPlaceholderResource = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/placeholder_resources';

    postJSV1(
      url,
      data,
      function (response) {
        gData.processPlaceholderResource(response, true);
        ARC.expire('placeholder_resources');
        callback && callback(response);
      },
      onError
    );
  };

  _i.updatePlaceholderResource = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/placeholder_resources/' + data.id;

    putJSV1(
      url,
      data,
      function (response) {
        gData.processPlaceholderResource(response, true);
        ARC.expire('placeholder_resources');
        callback && callback(response);
      },
      onError
    );
  };

  _i.removePlaceholderResource = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/placeholder_resources/' + data.id;

    deleteJS(
      url,
      function () {
        gData.removePlaceholderResource(data);
        ARC.expire('placeholder_resources');
        callback && callback(data);
      },
      onError
    );
  };

  _i.createSsoSettings = function (data, callback, onError) {
    data.sso_type = 'idp';
    var url = _i.SERVER_ADDRESS_V1 + '/sso_settings';

    postJSV1(
      url,
      data,
      function (obj) {
        gData.updateSsoSettings(obj);
        callback && callback(obj);
      },
      onError
    );
  };

  _i.updateSsoSettings = function (data, callback, onError) {
    data.sso_type = 'idp';
    var url = _i.SERVER_ADDRESS_V1 + '/sso_settings/' + data.id;

    putJSV1(
      url,
      data,
      function (obj) {
        gData.updateSsoSettings(obj);
        callback && callback(obj);
      },
      onError
    );
  };

  _i.getActiveSsoUsers = function (callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/sso_settings/active_sso_users';

    new injectJS(
      url,
      callback,
      onError,
      false, // noauth
      false // allowCache
    );
  };

  _i.getCustomFields = function (namespace, callback, onError) {
    // This method is ony used in legacy JS which always wants to get all the pages
    // of data available. So use a sufficiently (and arbitrarily) large per_page
    // value so we can fetch the custom fields at once and save roundtrips. This also
    // make the response cacheable, which is more complex when fetching it page by page.
    // If any account reaches beyond a 1000 custom fields per namespace, we can re-vist
    // hardcoded limit here.

    var url =
      _i.SERVER_ADDRESS_V1 +
      '/custom_fields?namespace=' +
      namespace +
      '&per_page=1000';

    var cb = function (fields) {
      gData.addCustomFields(fields.data, namespace);
      callback && callback(fields.data);
    };

    new injectJS(
      url,
      cb,
      onError,
      false, // noauth
      true // allowCache
    );
  };

  _i.createCustomField = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/custom_fields';

    postJS(
      url,
      data,
      function (obj) {
        gData.processCustomField(obj);
        callback && callback(obj);
        ARC.expire('custom_fields');
      },
      onError
    );
  };

  _i.updateCustomField = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/custom_fields/' + data.id;

    putJS(
      url,
      data,
      function (obj) {
        gData.processCustomField(obj);
        callback && callback(obj);
        ARC.expire('custom_fields');
      },
      onError
    );
  };

  _i.removeCustomField = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/custom_fields/' + data.id;

    deleteJS(
      url,
      function () {
        gData.removeCustomField(data);
        callback && callback(data);
        ARC.expire('custom_fields');
      },
      onError
    );
  };

  _i.getCustomFieldValues = function (
    custom_fieldable_id,
    namespace,
    callback,
    onError
  ) {
    var controllerPath = namespace == 'assignables' ? '/projects/' : '/users/',
      url =
        _i.SERVER_ADDRESS_V1 +
        controllerPath +
        custom_fieldable_id +
        '/custom_field_values';

    var pagingComplete = function (values) {
      gData.setCustomFieldValueObjects(custom_fieldable_id, namespace, values);
      callback && callback(values);
    };

    _i.getCollectionByPage(url, pagingComplete, onError);
  };

  _i.bulkApprove = function (starts_at, ends_at, type, callback, onError) {
    starts_at = starts_at.toRubyDateYMD();
    ends_at = ends_at.toRubyDateYMD();
    var url = _i.SERVER_ADDRESS_V1 + '/approvals';
    var starts_at = new Date(2012, 0, 1).toRubyDateYMD();
    var data = {
      status: 'approved',
      starts_at: starts_at,
      ends_at: ends_at,
    };
    if (type) data['approvable_type'] = type;
    postJS(
      url,
      data,
      function (obj) {
        callback && callback(obj);
      },
      onError
    );
  };

  _i.getProjectStates = function (callback) {
    new injectJS(
      _i.SERVER_ADDRESS + '/tags/project_states',
      function (data) {
        gData.setProjectStates(data);
        callback && callback(data);
      },
      null,
      false,
      true
    );
  };

  _i.getUserTypes = function (callback) {
    setTimeout(function () {
      callback && callback(_i.userTypes);
    }, 1);
  };

  _i.getEverything = function (onSuccess, onError) {
    var remainingCallbacks = 6,
      assignments;

    function cb() {
      remainingCallbacks -= 1;
      if (remainingCallbacks == 0) {
        gData.setAssignmentsOfUsersAndAssignables(assignments);
        onSuccess && onSuccess();
      }
    }

    injectJS(
      _i.SERVER_ADDRESS + '/users?with_deleted=true',
      function (data) {
        gData.setUsers(data || []);
        cb();
      },
      onError,
      false,
      true
    );

    injectJS(
      _i.SERVER_ADDRESS + '/projects?with_deleted=true',
      function (data) {
        gData.setProjects(data || []);
        cb();
      },
      onError,
      false,
      true
    );

    injectJS(
      _i.SERVER_ADDRESS + '/leave_types?with_deleted=true',
      function (data) {
        gData.setLeaveTypes(data || []);
        cb();
      },
      onError,
      false,
      true
    );

    injectJS(
      _i.SERVER_ADDRESS + '/tags',
      function (data) {
        gData.processAllTags(data || []);
        cb();
      },
      onError,
      false,
      false
    );

    if (
      gData.accountSettings.canUserReadModel(gData.getMe(), 'BillRate', null)
    ) {
      injectJS(
        _i.SERVER_ADDRESS_V1 + '/bill_rates',
        function (response) {
          gData.setAccountBillRates(response.data);
          cb();
        },
        onError,
        false,
        false
      );
    } else {
      remainingCallbacks -= 1;
    }

    injectJS(
      _i.SERVER_ADDRESS + '/assignments',
      function (data) {
        assignments = data;
        cb();
      },
      onError,
      false,
      false
    );
  };

  _i.getScheduleDataV2 = function (callback) {
    var remainingCallbacks = 7;

    function cb() {
      remainingCallbacks -= 1;
      if (remainingCallbacks == 0) {
        callback();
      }
    }

    injectJS(
      _i.SERVER_ADDRESS + '/users',
      function (data) {
        gData.setUsers(data || []);
        cb();
      },
      null,
      false,
      true
    );

    injectJS(
      _i.SERVER_ADDRESS + '/projects?entity_mapping=true',
      function (data) {
        gData.setProjects(data || []);
        cb();
      },
      null,
      false,
      true
    );

    injectJS(
      _i.SERVER_ADDRESS + '/leave_types?with_deleted=true',
      function (data) {
        gData.setLeaveTypes(data || []);
        cb();
      },
      null,
      false,
      true
    );

    // todo - depends on users (and projects)?
    injectJS(
      _i.SERVER_ADDRESS + '/tags',
      function (data) {
        gData.processAllTags(data || []);
        cb();
      },
      null,
      false,
      false
    );

    _i.getMe(
      function (data) {
        cb();
      },
      null,
      false,
      true
    );

    _i.getPlaceholderResources(function (resources) {
      cb();
    }, false);

    _i.getStatusOptions(cb, null, { withDeleted: true });
  };

  _i.getAssignmentsEx = function (
    start_date,
    end_date,
    user_id,
    assignable_id,
    repetition_id,
    reset,
    callback
  ) {
    var url = _i.SERVER_ADDRESS + '/assignments?startdate=';

    if (start_date) {
      url += start_date.toRubyDate();
    }

    if (end_date) {
      url += '&enddate=';
      url += end_date.toRubyDate();
    }

    if (user_id) {
      url += '&user_id=';
      url += user_id;
    }

    if (assignable_id) {
      url += '&assignable_id=';
      url += assignable_id;
    }

    if (repetition_id) {
      url += '&repetition_id=';
      url += repetition_id;
    }

    injectJS(
      url,
      function (data) {
        var i = 0;

        gData.setAssignmentsOfUsersAndAssignables(data, reset);
        callback && callback(data);
      },
      null,
      false,
      false
    );
  };

  _i.getAssignments = function (start_date, end_date, reset, callback) {
    _i.getAssignmentsEx(
      start_date,
      end_date,
      null,
      null,
      null,
      reset,
      callback
    );
  };

  // Options hash can currently contain:
  // {boolean} excludeUnassigned - Scope the request to exclude assignments where user_id is null
  // {boolean} excludeArchived - Scope the request to exclude assignments for archived projects
  // {number} scopeToAssignableId - Scope the request to exclude assignments only for a given assignable_id
  _i.getAssignmentsChunked = function (
    start_date,
    end_date,
    reset,
    options,
    callback
  ) {
    var chunkRequest = true;
    var url = _i.SERVER_ADDRESS + '/assignments?startdate=';

    if (start_date) {
      url += start_date.toRubyDate();
    }

    if (end_date) {
      url += '&enddate=';
      url += end_date.toRubyDate();
    }

    if (options && !_.isUndefined(options.scopeToAssignableIds)) {
      chunkRequest = false;
      url += '&assignable_id=' + options.scopeToAssignableIds;
    }

    if (options && options.excludeUnassigned) {
      url += '&exclude_unassigned=true';
    }

    if (options && options.excludeArchived) {
      url += '&exclude_archived=true';
    }

    url += '&include_row_id=true';

    if (chunkRequest) {
      // Set the request count to the maximum number of parallel requests that can be processed by
      // most major browsers. These change from time to time so we should document them and adjust the count
      // to always support the minimum of the major browsers.
      // -----------
      // Safari 4:  6
      // Chrome 6:  7
      // FireFox4:  6
      // ------------
      var requestCount = 6;
      Promise.all(
        _.times(requestCount, function (i) {
          return new Promise(function (resolve, reject) {
            injectJS(
              url + '&chunk=[' + (i + 1) + ',' + requestCount + ']',
              function (data) {
                resolve(data);
              },
              null,
              false,
              false
            );
          });
        })
      ).then(function (chunkedData) {
        var data = _.flatten(chunkedData);
        gData
          .setAssignmentsWithMissingProjectsAndUsers(data, reset)
          .then(function () {
            callback && callback(data);
          });
      });
    } else {
      injectJS(
        url,
        function (data) {
          var i = 0;

          gData.setAssignmentsWithMissingProjectsAndUsers(data, reset);
          callback && callback(data);
        },
        null,
        false,
        false
      );
    }
  };

  _i.getProjectPage = function (callback) {
    var remainingCallbacks = 3;

    function cb() {
      remainingCallbacks -= 1;
      if (remainingCallbacks == 0) {
        callback();
      }
    }

    injectJS(
      _i.SERVER_ADDRESS + '/users?with_deleted=true',
      function (data) {
        gData.setUsers(data || []);
        cb();
      },
      null,
      false,
      true
    );

    injectJS(
      _i.SERVER_ADDRESS + '/tags',
      function (data) {
        gData.processAllTags(data || []);
        cb();
      },
      null,
      false,
      false
    );

    _i.getPlaceholderResources(function (resources) {
      cb();
    }, false);

    // TODO Cleanup after testing.
    // new injectJS(_i.SERVER_ADDRESS + "/forprojectpage", function (data) {
    //   data.users = data.users || [];
    //   data.placeholder_resources = data.placeholder_resources || [];
    //   gData.setUsers(data.users);
    //   var me = gData.getUser(data.me);
    //   gData.setMe(me);
    //   gData.setAccountSettings(data.organization_settings);
    //   gData.processAllTags(data.tags);
    //   gData.setStatuses(data.statuses);
    //   gData.setPlaceholderResources(data.placeholder_resources);
    //   callback && callback(data);
    // }, null, false, false);
  };

  _i.getMe = function (callback) {
    if (window.whoami) {
      callback &&
        setTimeout(function () {
          callback(window.whoami);
        }, 1);
    } else {
      __A(false, 'not using window.whoami');
      new injectJS(
        _i.SERVER_ADDRESS + '/me',
        function (data) {
          gData.setMe(data);
          callback && callback(data);
        },
        null,
        false,
        true
      );
    }
  };

  _i.getAssignmentsForProj = function (callback, proj_id) {
    var url = _i.SERVER_ADDRESS_V1 + '/projects/';
    url += proj_id;
    perPage = '&per_page=100000';
    withPhases = '&with_phases=true';
    url += '/assignments?' + perPage + withPhases;

    new injectJS(url, function (data) {
      gData.setAssignmentsOfUsersAndAssignables(data.data);
      callback && callback(data);
    });
  };

  _i.getSmartsheetOrg = function (callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/smartsheet/organization';
    return new Promise(function (resolve, reject) {
      new injectJS(url, function (data) {
        callback && callback(data);
        resolve(data);
      });
    });
  };

  _i.disconnectSmartsheetOrg = function (callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/smartsheet/organization';
    return new Promise(function (resolve, reject) {
      new deleteJS(url, function (data) {
        callback && callback(data);
        resolve(data);
      });
    });
  };

  _i.getApiTokens = function (callback) {
    new injectJS(
      _i.SERVER_ADDRESS + '/api_tokens',
      function (data) {
        gData.api_tokens = data;
        callback && callback(data);
      },
      function (error) {
        callback(error);
      },
      false,
      false
    );
  };

  _i.getFutureProjectsForUser = function (user) {
    var retval = [];
    var projects = [];
    var length = 0;
    var assignments = user.assignments ? user.assignments : [];
    var currentproj = [];
    // first look for the current project
    // we do this separate so we do not include any past assignments
    // for the current project as past projects
    for (var i = 0; i < assignments.length; i++) {
      var s = assignments[i].starts_at.getTime();
      var e = assignments[i].ends_at.getTime();
      var currentTime = new Date().getTime();
      if (e > currentTime && s < currentTime) {
        var proj = gData.getAssignableOrChild(
          assignments[i].assignable_id,
          false
        );
        if (proj) {
          if (proj.parent_id) proj = gData.getProject(proj.parent_id, false);
          currentproj.push(proj.id);
        }
      }
    }
    for (var i = 0; i < assignments.length; i++) {
      var s = assignments[i].starts_at.getTime();
      var currentTime = new Date().getTime();
      if (s > currentTime) {
        var proj = gData.getAssignableOrChild(
          assignments[i].assignable_id,
          false
        );
        if (proj.parent_id) proj = gData.getProject(proj.parent_id, false);
        // if the project has not been added to the return value, add it
        var isCurrent = false;
        for (j = 0; j < currentproj.length; j++) {
          if (proj && proj.id == currentproj[j]) isCurrent = true;
        }

        if (
          proj &&
          proj.type == 'Project' &&
          !isCurrent &&
          !projects[proj.id]
        ) {
          projects[proj.id] = proj;
          retval[length++] = proj;
        }
      }
    }
    return retval;
  };

  _i.getPastProjectsForUser = function (user) {
    var retval = [];
    var projects = [];
    var length = 0;
    var assignments = user.assignments ? user.assignments : [];
    var currentproj = [];
    // first look for the current project
    // we do this separate so we do not include any past assignments
    // for the current project as past projects
    for (var i = 0; i < assignments.length; i++) {
      var s = assignments[i].starts_at.getTime();
      var e = assignments[i].ends_at.getTime();
      var currentTime = new Date().getTime();
      if (e > currentTime && s < currentTime) {
        var proj = gData.getAssignableOrChild(
          assignments[i].assignable_id,
          false
        );
        if (proj) {
          if (proj.parent_id) proj = gData.getProject(proj.parent_id, false);
          currentproj.push(proj.id);
        }
      }
    }
    for (var i = 0; i < assignments.length; i++) {
      var e = assignments[i].ends_at.getTime();
      var currentTime = new Date().getTime();
      if (e < currentTime) {
        var proj = gData.getAssignableOrChild(
          assignments[i].assignable_id,
          false
        );
        if (proj.parent_id) proj = gData.getProject(proj.parent_id, false);
        // if the project has not been added to the return value, add it
        var isCurrent = false;
        for (j = 0; j < currentproj.length; j++) {
          if (proj && proj.id == currentproj[j]) isCurrent = true;
        }

        if (
          proj &&
          proj.type == 'Project' &&
          !isCurrent &&
          !projects[proj.id]
        ) {
          projects[proj.id] = proj;
          retval[length++] = proj;
        }
      }
    }
    return retval;
  };

  _i.getHistoryLogs = function (callback, startDate, endDate, projectid) {
    // TODO deprecate
    var url = _i.SERVER_ADDRESS + '/history_logs?';

    if (startDate) url += 'startdate=' + startDate;
    if (endDate) url += '&enddate=' + endDate;
    if (projectid) url += '&project_id=' + projectid;
    new injectJS(
      url,
      function (data) {
        gData.setHistoryLogs(data);
        callback && callback(data);
      },
      null,
      false,
      false
    );
  };

  _i.getAssignables = function (callback, withDeleted) {
    var url = _i.SERVER_ADDRESS + '/assignables';
    if (withDeleted) {
      url += '?with_deleted=true';
    }
    new injectJS(url, function (data) {
      var leaveTypes = [];
      var projects = [];
      for (var i = 0; i < data.length; ++i) {
        var o = data[i];
        if (o.type == 'Project') {
          projects.push(o);
        } else {
          leaveTypes.push(o);
        }
      }
      gData.setProjects(projects);
      gData.setLeaveTypes(leaveTypes);
      _i.loadingAllAssignables = false;
      gData.allAssignablesHaveBeenLoaded = true;
      callback && callback(data);
    });
  };

  /*
   * This method is necessary for 'addProjectDataControl', which is used by 'editStatusControl'.
   * We need to keep track of whether or not all assignables have been loaded.  Ideally, the
   * edit status view would keep track of this; however, the edit status view is newly instantiated
   * each time the user clicks Update Status.
   * TODO:  Find a better way to keep track of this information.
   */
  _i.getAssignablesForStatusView = function (callback, withDeleted) {
    _i.loadingAllAssignables = true;
    _i.getAssignables(callback, withDeleted);
  };

  _i.isLoadingAllAssignables = function () {
    return _i.loadingAllAssignables;
  };

  _i.getProjects = function (callback, withDeleted, withChildren) {
    var url = _i.SERVER_ADDRESS_V1 + '/projects?',
      fields = '&fields=tags', // get tags by default
      perPage = '&per_page=100000';

    withDeleted && (url += '&with_archived=true');
    withChildren && (fields += ',children');

    url += fields + perPage;
    new injectJS(
      url,
      function (data) {
        gData.setProjects(data.data);
        callback && callback(data.data);
      },
      null,
      false,
      true
    );
  };

  _i.getLeaveTypes = function (callback, withDeleted) {
    var url = _i.SERVER_ADDRESS + '/leave_types';
    if (withDeleted) {
      url += '?with_deleted=true';
    }
    new injectJS(
      url,
      function (data) {
        gData.setLeaveTypes(data);
        callback && callback(data);
      },
      null,
      false,
      true
    );
  };

  _i.getLeaveTypesV1 = function (callback, withDeleted, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/leave_types';
    if (withDeleted) {
      url += '?with_deleted=true';
    }

    var pagingComplete = function (data) {
      gData.setLeaveTypes(data);
      callback && callback(data);
    };

    _i.getCollectionByPage(url, pagingComplete, onError, true);
  };

  _i.getUsers = function (callback, withDeleted) {
    var url = _i.SERVER_ADDRESS + '/users';
    if (withDeleted) {
      url += '?with_deleted=true';
    }
    new injectJS(
      url,
      function (data) {
        gData.setUsers(data);
        callback && callback(data);
      },
      null,
      false,
      true
    );
  };

  _i.getUsersById = function (ids, callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/users/query_by_id?per_page=99999';
    var data = { ids: ids };
    postJSV1(url, data, function (response) {
      gData.setUsers(response.data);
      callback && callback(data);
    });
  };

  _i.getProjectUsers = function (projectId, callback, withDeleted) {
    var url = _i.SERVER_ADDRESS + '/projects/' + projectId + '/users';
    if (withDeleted) {
      url += '?with_deleted=true';
    }
    new injectJS(
      url,
      function (data) {
        gData.setUsers(data);
        callback && callback(data);
      },
      null,
      false,
      true
    );
  };

  _i.getUser = function (id, callback) {
    new injectJS(_i.SERVER_ADDRESS + '/users/' + id, function (data) {
      gData.setUsers([data]);
      callback && callback(data);
    });
  };

  _i.upgradeUser = async function (id, onError) {
    const url = _i.SERVER_ADDRESS_V1 + `/users/${id}/upgrade`;
    const csrfToken = document
      .querySelector('meta[name="csrf-token"]')
      .getAttribute('content');
    const headers = {
      'X-CSRF-Token': csrfToken,
    };

    if (window.isIframeView && window.clientState) {
      headers['x-smar-xsrf'] = window.clientState.sessionKey || undefined;
    }

    // Use custom request code, because the put/post/etc functions used elsewhere in this file don't provide a way to wait for them
    return window
      .fetch(url, {
        method: 'PUT',
        headers: headers,
      })
      .then((obj) => {
        if (obj.error) {
          if (onError) {
            onError(obj);
          }
          return false;
        }
        return true;
      })
      .catch((error) => {
        onError(error);
        return false;
      });
  };

  // This updateUser is called from the user edit page
  _i.updateUser = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS + '/users/' + data.id,
      params = {};

    if (data.firstName) {
      params.first_name = data.firstName;
    }
    if (data.lastName) {
      params.last_name = data.lastName;
    }

    params.mobile_phone = data.mobile_phone;
    params.office_phone = data.office_phone;
    params.employee_number = data.employee_number;

    if (
      gData.accountSettings.moduleEnabled('relationship-based approvals') &&
      data.approver_user_ids
    ) {
      params.approver_user_ids = JSON.stringify(data.approver_user_ids);
    }

    if (data.location) {
      params.location = JSON.stringify(data.location);
    }
    if (data.discipline) {
      params.discipline = JSON.stringify(data.discipline);
    }
    if (data.role) {
      params.role = JSON.stringify(data.role);
    }
    if (data.billability_target || data.billability_target == 0) {
      params.billability_target = data.billability_target;
    }
    if (data.billrate || data.billrate == 0) {
      params.billrate = data.billrate;
    }
    if (data.newPassword && data.currentPassword) {
      params.currentPassword = data.currentPassword;
      params.newPassword = data.newPassword;
    }
    if (data.tags) {
      params.tags = JSON.stringify(data.tags);
    }
    if (data.custom_field_values) {
      params.custom_field_values = JSON.stringify(data.custom_field_values);
    }
    if (data.thumbnail) {
      params.thumbnail = data.thumbnail;
    }
    if (data.user_type_id) {
      params.user_type_id = data.user_type_id;
    }
    // null means no date
    if (data.hire_date || data.hire_date === null) {
      params.hire_date = data.hire_date;
    }
    if (data.termination_date || data.termination_date === null) {
      params.termination_date = data.termination_date;
    }
    if ('email' in data) {
      params.email = data.email;
    }

    if (data.licenseType) {
      params.license_type = data.licenseType;
    }

    putJS(
      url,
      params,
      function (obj) {
        if (obj.error) {
          if (onError) {
            onError(obj);
          }
          return;
        }
        if (obj.data) ARC.expire('users');
        gData.updateUser(obj.data);
        callback && callback(obj.data);
      },
      onError
    );
  };

  _i.updateUserMakeManagedResource = function (id, callback, errorCallback) {
    var url = _i.SERVER_ADDRESS + '/v1/users/' + id,
      data = { license_type: 'managed_resource' };

    putJS(
      url,
      data,
      function (response) {
        // NOTE transform response shape for `gData.updateUser` and subsequently `gData.processUser`
        if (response.first_name) {
          response.firstName = response.first_name;
          response.lastName = response.last_name;
          response.displayName = response.display_name;
        }
        gData.updateUser(response);

        callback && callback(response);
        ARC.expire('users');
      },
      errorCallback
    );
  };

  // NOTE method saves a tag and merges the tags for the specified `namespace`.
  // Used on the Schedule and Reports (legacy).
  _i.addReport = function (id, report, callback) {
    var url = _i.SERVER_ADDRESS + '/users/' + id + '/addreport';
    postJSV1(url, { tag: report }, function (data) {
      var namespace = report.namespace;
      if (data.data.tags && namespace) {
        var currentTagsForNamespace = gData
          .getTagsByNamespace(namespace)
          .filter(function (tag) {
            return tag.id;
          });
        var userTagsForNamespace = data.data.tags.filter(function (tag) {
          return tag.namespace === namespace;
        });
        // NOTE concat current tags onto response tags so old duplicates are eliminated
        var uniqueTagsForNamespace = _.uniq(
          userTagsForNamespace.concat(currentTagsForNamespace),
          'id'
        );
        gData.addTagsForNamespace(uniqueTagsForNamespace, namespace);
      }
      callback && callback();
    });
  };

  _i.updateFilter = function (tag, callback) {
    // TODO Provide a more generic way to save updated tags
    _i.updateCategoryTag(tag, callback);
  };

  _i.removeFilter = function (tag, callback) {
    var tags = gData.tagsByNameSpace['saved gridfilter'];
    for (var i = 0; tags && tags.length; i++) {
      if (tags[i].id == tag.id) {
        tags.splice(i, 1);
        break;
      }
    }
    var data = {};
    data.id = tag.id;
    data.namespace = 'deleted gridfilter';
    data.name = tag.name;
    data.value = tag.value;
    _i.updateCategoryTag(data, callback);
  };

  _i.getSavedReports = function (callback, onerror) {
    var url =
      _i.SERVER_ADDRESS_V1 +
      '/tags?namespace=organization%20reports&per_page=9999';
    injectJS(url, callback, onerror);
  };

  _i.removeReport = function (userId, tag, callback) {
    if (!userId) {
      var reports = window.savedOrgReports;
      for (var i = 0; reports && reports.length; i++) {
        if (reports[i].id === tag.id) {
          reports.splice(i, 1);
          break;
        }
      }
      var data = {};
      data.id = tag.id;
      data.namespace = 'deleted report';
      data.name = tag.name;
      _i.updateCategoryTag(data, callback);
    } else {
      var url = _i.SERVER_ADDRESS + '/users/' + userId + '/removereport';
      postJSV1(url, { tag_id: tag.id }, function (response) {
        gData.updateUser(response.data);
        callback && callback();
      });
    }
  };

  _i.undeleteUser = function (data, callback, errorCallback) {
    var url = _i.SERVER_ADDRESS + '/users/' + data.id + '/undelete';
    var queryParams = {};

    // NOTE this will be defined even in legacy, the API responds with this now and data is a user
    if (data.type) {
      queryParams['type'] = data.type;
    }

    if (data.license_type) {
      queryParams['license_type'] = data.license_type;
    }

    postJSV1(
      url,
      queryParams,
      function (response) {
        gData.updateUser(response.data);
        ARC.expire('users');
        callback && callback();
      },
      function (response) {
        errorCallback && errorCallback(response);
      }
    );
  };

  _i.deleteUser = function (data, callback) {
    deleteJSV1(_i.SERVER_ADDRESS + '/users/' + data.id, function () {
      gData.deleteUser(data);
      ARC.expire('users');
      callback && callback();
    });
  };

  _i.fulldeleteUser = function (data, callback) {
    if (data.account_owner) throw 'deleting account_owner is not allowed';

    deleteJSV1(
      _i.SERVER_ADDRESS + '/users/' + data.id + '/fulldestroy',
      function () {
        gData.deleteUser(data);
        ARC.expire('users');
        callback && callback();
      }
    );
  };

  _i.getProjectsAndAssignments = function (user_id, callback) {
    function cb(projects) {
      gData.setProjects(projects || []);

      injectJS(
        _i.SERVER_ADDRESS + '/assignments?user_id=' + user_id,
        function (data) {
          gData.setAssignmentsOfUsersAndAssignables(data);
          callback && callback();
        },
        null,
        false,
        false
      );
    }

    _i.getCollectionByPage(
      _i.SERVER_ADDRESS_V1 +
        '/users/' +
        user_id +
        '/projects?with_archived=true&per_page=100&with_phases=true',
      cb
    );
  };

  _i.getUserApprovers = function (user_id, callback) {
    function cb(users) {
      var u = gData.getUser(user_id);
      if (
        window.accountSettings.moduleEnabled('crib_user_entitlement') &&
        window.accountSettings.crib_feature_eligibility
      ) {
        u.approver_user_ids = _.map(
          users.filter(function (user) {
            return user['license_type'] != 'unmanaged_resource';
          }),
          'id'
        );
      } else {
        u.approver_user_ids = _.map(users, 'id');
      }
      callback && callback();
    }

    _i.getCollectionByPage(
      _i.SERVER_ADDRESS_V1 + '/users/' + user_id + '/approvers',
      cb
    );
  };

  _i.getUserEditPageData = function (callback) {
    var remainingCallbacks = 3;

    function cb() {
      remainingCallbacks -= 1;
      if (remainingCallbacks == 0) {
        callback();
      }
    }

    injectJS(
      _i.SERVER_ADDRESS +
        '/users?with_deleted=true&guid=' +
        QueryString.user_id,
      function (data) {
        if (!(data && data.length)) {
          // User is a placeholder, redirect to people page
          window.location = '/me';
          return;
        }
        gData.setUsers(data || []);
        var user_id = data[0].id;
        if (QueryString.user_id != null) {
          if (
            gData.accountSettings.moduleEnabled('relationship-based approvals')
          ) {
            _i.getUserApprovers(user_id, cb);
          } else {
            cb();
          }
        }
        cb();
      },
      null,
      false,
      false
    );

    injectJS(
      _i.SERVER_ADDRESS + '/tags',
      function (data) {
        gData.processAllTags(data || []);
        cb();
      },
      null,
      false,
      false
    );
  };

  // get the latest session data
  _i.refreshSession = function (callback, error) {
    var url = _i.SERVER_ADDRESS + '/sessions/current';
    new injectJS(url, callback, error);
  };

  _i.signin = function (email, password, userid, im, callback, error) {
    var url = _i.SERVER_ADDRESS + '/sessions/signin',
      params = {};

    params.user_id = email;
    params.password = password;

    if (userid) params.multiuserid = userid;

    if (im && im.im) params.im = im.im;

    if (im && im.reason) params.reason = im.reason;

    postJS(url, params, callback, error);
  };

  _i.getFederatedMultiUser = function (callback, error) {
    var url = _i.SERVER_ADDRESS_V1 + '/federated_login/signin',
      params = {};

    getJS(url, params, callback, error);
  };

  _i.createHoliday = function (data, callback, error) {
    var url = _i.SERVER_ADDRESS + '/holidays';
    var payload = {};
    payload['name'] = data.name;
    payload['date'] = data.date.toDateString();
    payload['allow_assignments'] = 0;
    postJSV1(
      url,
      payload,
      function (obj) {
        gData.updateHolidays(obj);
        callback && callback(obj);
        ARC.expire('leave_types');
      },
      error
    );
  };

  _i.updateHoliday = function (data, callback) {
    var url = _i.SERVER_ADDRESS + '/holidays/' + data.id;
    var payload = {};
    payload['name'] = data.name;
    payload['date'] = data.date.toDateString();
    payload['allow_assignments'] = 0;
    putJSV1(url, payload, function (obj) {
      gData.updateHolidays(obj);
      callback && callback(obj);
      ARC.expire('leave_types');
    });
  };

  _i.deleteHoliday = function (data, callback, errorcallback) {
    var url = _i.SERVER_ADDRESS + '/holidays/' + data.id;
    deleteJSV1(
      url,
      function (obj) {
        gData.updateHolidays(obj);
        callback && callback(obj);
        ARC.expire('leave_types');
      },
      errorcallback
    );
  };

  _i.saveAssignment = function (data, callback) {
    var url = _i.SERVER_ADDRESS + '/assignments/' + data.id + '?emptyparam=0';
    var payload = {};
    payload['user_id'] = data.user_id;
    payload['assignable_id'] = data.assignable_id;
    payload['starts_at'] = data.starts_at.toRubyDate();
    payload['ends_at'] = data.ends_at.toRubyDate();
    payload['allocation_mode'] = data.allocation_mode;
    payload['percent'] = data.percent || data.percent == 0 ? data.percent : '';
    payload['fixed_hours'] =
      data.fixed_hours || data.fixed_hours == 0 ? data.fixed_hours : '';
    payload['hours_per_day'] =
      data.hours_per_day || data.hours_per_day == 0 ? data.hours_per_day : '';

    putJSV1(url, payload, function (data) {
      if (data.success) {
        gData.updateAssignment(data.data);
        gData._cachedUsersOnTheBench = null;
      }
      callback && callback(data.data);
    });
  };

  _i.saveAssignmentV1 = function (data, callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/assignments/' + data.id,
      params = {};

    params.user_id = data.user_id;
    params.assignable_id = data.assignable_id;
    params.starts_at = data.starts_at.toRubyDate();
    params.ends_at = data.ends_at.toRubyDate();
    params.allocation_mode = data.allocation_mode;
    params.percent = data.percent || data.percent == 0 ? data.percent : null;
    params.fixed_hours =
      data.fixed_hours || data.fixed_hours == 0 ? data.fixed_hours : null;
    params.hours_per_day =
      data.hours_per_day || data.hours_per_day == 0 ? data.hours_per_day : null;
    params.status_option_id = data.status_option_id
      ? data.status_option_id
      : null;

    if (data.hasOwnProperty('status')) {
      params.status = data.status;
    }

    putJSV1(url, params, function (response) {
      if (response) {
        gData.updateAssignment(response);
        gData._cachedUsersOnTheBench = null;
        callback && callback(response);
      }
    });
  };

  _i.createAssignment = function (data, callback) {
    var url = _i.SERVER_ADDRESS + '/assignments';
    var payload = {};
    payload['user_id'] = data.user_id;
    payload['assignable_id'] = data.assignable_id;
    payload['starts_at'] = data.starts_at.toRubyDate();
    payload['ends_at'] = data.ends_at.toRubyDate();
    payload['allocation_mode'] = data.allocation_mode;
    payload['percent'] = data.percent || data.percent == 0 ? data.percent : '';
    payload['fixed_hours'] =
      data.fixed_hours || data.fixed_hours == 0 ? data.fixed_hours : '';
    payload['hours_per_day'] =
      data.hours_per_day || data.hours_per_day == 0 ? data.hours_per_day : '';
    payload['description'] = data.description || '';
    payload['note'] = data.note || '';
    payload['status_option_id'] = data.status_option_id || '';

    if (data.resource_request_id)
      payload['resource_request_id'] = data.resource_request_id;
    if (data.status) payload['status'] = data.status;

    postJSV1(url, payload, function (data) {
      if (data.success) {
        gData.addAssignment(data.data);
        gData._cachedUsersOnTheBench = null;
      }
      callback && callback(data.data);
    });
  };

  _i.createAssignmentV1 = function (data, callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/assignments';
    url += '?user_id=' + data.user_id;
    url += '&assignable_id=' + data.assignable_id;
    url += '&starts_at=' + encodeURIComponent(data.starts_at.toRubyDate());
    url += '&ends_at=' + encodeURIComponent(data.ends_at.toRubyDate());
    url += '&allocation_mode=' + encodeURIComponent(data.allocation_mode);
    url +=
      '&percent=' + (data.percent || data.percent == 0 ? data.percent : '');
    url +=
      '&fixed_hours=' +
      (data.fixed_hours || data.fixed_hours == 0 ? data.fixed_hours : '');
    url +=
      '&hours_per_day=' +
      (data.hours_per_day || data.hours_per_day == 0 ? data.hours_per_day : '');
    url += '&description=' + (data.description || '');

    // TODO: Make params JSON and use postJSV1 instead of putting params on URL.
    postJS(url, null, function (response) {
      if (response) {
        gData.addAssignment(response);
        gData._cachedUsersOnTheBench = null;
        callback && callback(response);
      }
    });
  };

  _i.deleteAssignment = function (data, callback) {
    deleteJSV1(_i.SERVER_ADDRESS + '/assignments/' + data.id, function () {
      gData.deleteAssignment(data);
      gData._cachedUsersOnTheBench = null;
      callback && callback();
    });
  };

  _i.deleteAssignmentV1 = function (data, callback) {
    deleteJS(_i.SERVER_ADDRESS_V1 + '/assignments/' + data.id, function () {
      gData.deleteAssignment(data);
      gData._cachedUsersOnTheBench = null;
      callback && callback();
    });
  };

  _i.fulfillRequestedAssignment = function (data, callback) {
    // Backbone is used here because putJS and $.ajax do not properly
    // handle null values, and setting assignment status to null
    // is how requested assignments get fulfilled.
    var model = new Backbone.Model();
    model.url = _i.SERVER_ADDRESS_V1 + '/assignments/' + data.id;
    model.save(
      {
        id: data.id,
        user_id: data.user_id,
        status: null,
      },
      {
        success: function (model, response) {
          // This is needed because the ends_at of the old API is
          // inconsistent with that of the v1 API,
          // and the old API is assumed to be the source of the data.
          response.ends_at =
            gData.assignmentsById[response.id].ends_at.toRubyDateYMD();

          // Here we treat the update as a delete and create
          // because the user_id has likely changed,
          // and strange things happen if we try to update the
          // user_id of the existing assignment object.
          gData.deleteAssignment(response);
          gData.addAssignment(response);

          gData._cachedUsersOnTheBench = null;
          callback && callback(gData.assignmentsById[response.id]);
        },
      }
    );
  };

  _i.createRepetition = function (assignableId, assignmentId, data, callback) {
    var url =
      _i.SERVER_ADDRESS +
      '/projects/' +
      assignableId +
      '/assignments/' +
      assignmentId +
      '/repetitions';
    postJS(url, data, function (response) {
      if (response) {
        callback && callback(response);
      }
    });
  };

  _i.updateRepetition = function (
    assignableId,
    assignmentId,
    id,
    seriesEndsAt,
    callback
  ) {
    var url =
        _i.SERVER_ADDRESS +
        '/projects/' +
        assignableId +
        '/assignments/' +
        assignmentId +
        '/repetitions/' +
        id,
      data = { ends_at: seriesEndsAt.toRubyDate() };
    putJS(url, data, function (response) {
      gData.deleteAssignmentSeries(id, seriesEndsAt);
      callback && callback(response);
    });
  };

  _i.deleteRepetition = function (assignableId, assignmentId, id, callback) {
    var url =
      _i.SERVER_ADDRESS +
      '/projects/' +
      assignableId +
      '/assignments/' +
      assignmentId +
      '/repetitions/' +
      id;
    deleteJS(url, function () {
      gData.deleteAssignmentSeries(id);
      callback && callback();
    });
  };

  _i.getResourceRequests = function (callback, project_id) {
    var url =
      _i.SERVER_ADDRESS_V1 +
      '/projects/' +
      project_id +
      '/resource_requests?per_page=1000';
    new injectJS(url, function (data) {
      gData.addResourceRequests(data.data);
      callback && callback(data.data);
    });
  };

  _i.findMatchesForAssignment = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS_V1 + '/find_resources';

    postJSV1(
      url,
      data,
      function (response) {
        callback && callback(response);
      },
      onError
    );
  };

  _i.getProject = function (id, callback, fetchBillRates) {
    var url = _i.SERVER_ADDRESS_V1 + '/projects/' + id,
      fields = ['children', 'tags', 'has_pending_updates', 'entity_mapping'],
      perPage = '&per_page=100000';

    if (fetchBillRates == true) {
      fields.push('bill_rates');
    }
    url += '?fields=' + fields.join(',') + perPage;
    new injectJS(url, function (data) {
      gData.setProjects([data]);
      callback && callback(data);
    });
  };

  _i.saveProject = function (data, callback, error, doNotSaveTags) {
    // In order to migrate any legcy project formats to the V1 API, this method implements
    // a series of sanitization steps before sending the data to the API.
    //    a) cleanup client-side params to remove temporary state attached to the project object
    //    b) update any legacy tags to the V1 simple array format
    //    c) exclude psuedo-tags (e.g. client) from the project tags collection

    var url = _i.SERVER_ADDRESS_V1 + '/projects/' + data.id,
      // NOTE prevent unneccessary fields from being sent that are not allowed
      // to be set via the API, in the future these fields should likely just be
      // ignored by the API
      keysToExclude = [
        'archived_at',
        'secureurl',
        'secureurl_expiration',
        'created_at',
        'updated_at',
        'use_parent_bill_rates',
        'children',
        'assignments',
        'participants',
        'deleted',
        '__processed',
        'type',
        'guid',
      ],
      tags = data.tags,
      children = data.children,
      assignments = data.assignments,
      sanitizedProjectData;

    doNotSaveTags && keysToExclude.push('tags');

    sanitizedProjectData = _.pick(data, function (v, key) {
      return _.indexOf(keysToExclude, key) < 0;
    });

    if (tags && tags.length > 0 && typeof tags[0] == 'object') {
      sanitizedProjectData.tags = [];
      tags.forEach(function (t) {
        t.namespace == 'assignables' && sanitizedProjectData.tags.push(t.value);
      });
    }

    // Convert starts_at and ends_at to Ruby date strings to strip out time zones.
    // Otherwise, conversion using JSON.stringify will convert any time zone to UTC.
    // If starts_at/ends_at are not dates, they are likely strings already converted to
    // ruby dates, so don't make any changes.
    if (
      sanitizedProjectData.starts_at &&
      sanitizedProjectData.starts_at instanceof Date
    ) {
      sanitizedProjectData.starts_at =
        sanitizedProjectData.starts_at.toRubyDate();
    }
    if (
      sanitizedProjectData.ends_at &&
      sanitizedProjectData.ends_at instanceof Date
    ) {
      sanitizedProjectData.ends_at = sanitizedProjectData.ends_at.toRubyDate();
    }

    putJSV1(url, sanitizedProjectData, function (response) {
      response.children = children; // restore previous children
      response.assignments = assignments; // restore previous assignments
      gData.updateProject(response);
      callback && callback(response);
      ARC.expire('projects');
    });
  };

  _i.createProject = function (data, callback, error) {
    var url = _i.SERVER_ADDRESS_V1 + '/projects';

    // Convert starts_at and ends_at to Ruby date strings to strip out time zones.
    // Otherwise, conversion using JSON.stringify will convert any time zone to UTC.
    // If starts_at/ends_at are not dates, they are likely strings already converted to
    // ruby dates, so don't make any changes.
    if (data.starts_at && data.starts_at instanceof Date) {
      data.starts_at = data.starts_at.toRubyDate();
    }
    if (data.ends_at && data.ends_at instanceof Date) {
      data.ends_at = data.ends_at.toRubyDate();
    }

    postJSV1(url, data, function (response) {
      ARC.expire('projects');
      gData.updateProject(response);
      callback && callback(response);
    });
  };

  _i.bulkCreateProjects = function (data) {
    var url = _i.SERVER_ADDRESS_V1 + '/projects';

    postJSV1(url, { projects: data }, function (response) {
      ARC.expire('projects');
    });
  };

  _i.shiftProject = function (dataid, shiftval, callback, error) {
    // TODO: Move this to V1 API
    var url = _i.SERVER_ADDRESS + '/projects/' + dataid + '/shift';
    var payload = {};
    payload['shiftval'] = shiftval;
    new putJS(
      url,
      payload,
      function () {
        callback && callback();
        ARC.expire('projects');
      },
      error
    );
  };

  _i.copyProject = function (data, callback, error) {
    postJSV1(
      _i.SERVER_ADDRESS_V1 + '/projects/' + data.id + '/copy',
      {},
      function (return_val) {
        gData.updateProject(return_val);
        callback && callback(return_val);
        ARC.expire('projects');
      },
      error
    );
  };

  // NOTE This is new tasks, i.e., the `subtasks` model. Not to be confused with legacy tasks
  // `options` must be an object with `sourceAssignment` and `destinationAssignmentId` keys.
  _i.copySubtasksForAssignment = function (options, callback) {
    var sourceAssignmentId = options.sourceAssignmentId;
    var destinationAssignmentId = options.destinationAssignmentId;
    var assignableId = options.assignableId;
    var sourceAssignmentTasksURL =
      _i.SERVER_ADDRESS_V1 +
      '/projects/' +
      assignableId +
      '/assignments/' +
      sourceAssignmentId +
      '/subtasks?per_page=9999';
    new injectJS(sourceAssignmentTasksURL, function (json) {
      var destinationAssignmentTasksURL =
        _i.SERVER_ADDRESS_V1 +
        '/projects/' +
        assignableId +
        '/assignments/' +
        destinationAssignmentId +
        '/subtasks';
      var tasks = json.data;
      async.series(
        _.map(tasks, function (task) {
          return function (cb) {
            postJSV1(destinationAssignmentTasksURL, task, function () {
              cb();
            });
          };
        }),
        function () {
          callback && callback();
        }
      );
    });
  };

  _i.fulldeleteProject = function (data, callback, error) {
    var url = _i.SERVER_ADDRESS_V1 + '/projects/' + data.id;

    deleteJS(
      url,
      function () {
        gData.deleteProject(data, true /* fullDelete */);
        callback && callback();
        ARC.expire('projects');
      },
      function (response) {
        error && error(response);
      }
    );
  };

  _i.archiveProject = function (data, callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/projects/' + data.id;

    putJSV1(url, data, function (response) {
      _i.getAssignmentsForProj(callback, data.id);
      ARC.expire('projects');
    });
  };

  _i.deletePhase = function (data, callback, error) {
    ARC.expire('projects');
    _i.fulldeleteProject(data, callback, error);
  };

  _i.saveLeaveType = function (data, callback) {
    var url = _i.SERVER_ADDRESS + '/leave_types/' + data.id;
    var payload = {};
    payload['name'] = data.name;
    payload['leave_type'] = data.leave_type;
    putJSV1(url, payload, function (obj) {
      gData.updateLeaveType(obj.data);
      callback && callback(obj.data);
      ARC.expire('leave_types');
    });
  };

  _i.createLeaveType = function (data, callback) {
    var url = _i.SERVER_ADDRESS + '/leave_types';
    var payload = {};
    payload['name'] = data.name;
    payload['leave_type'] = data.leave_type;
    payload['type'] = 'LeaveType';
    payload['allow_halfday'] = 1;
    postJSV1(url, payload, function (obj) {
      gData.updateLeaveType(obj.data);
      callback && callback(obj.data);
      ARC.expire('leave_types');
    });
  };

  _i.deleteLeaveType = function (data, callback) {
    deleteJSV1(_i.SERVER_ADDRESS + '/leave_types/' + data.id, function () {
      gData.deleteLeaveType(data);
      callback && callback();
      ARC.expire('leave_types');
    });
  };

  _i.getBillRates = function (callback, project_id) {
    var url =
      _i.SERVER_ADDRESS_V1 +
      '/projects/' +
      project_id +
      '/bill_rates?per_page=100000';
    new injectJS(
      url,
      function (response) {
        gData.setBillRates(project_id, response.data);
        callback && callback();
      },
      null,
      false,
      false
    );
  };

  _i.getAccountBillRates = function (callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/bill_rates?per_page=100000';
    new injectJS(url, function (response) {
      gData.setAccountBillRates(response.data);
      callback && callback();
    });
  };

  _i.createOrUpdateAccountBillRates = function (billRates, callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/bill_rates/replace',
      data = { data: billRates };

    postJSV1(url, data, function (response) {
      gData.setAccountBillRates(response.data);
      callback && callback();
    });
  };

  _i.getBudgetCategories = function (callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/budget_items/categories';
    new injectJS(
      url,
      function (data) {
        gData.addBudget(data);
        callback && callback(data);
      },
      null,
      false,
      true
    );
  };

  function extractPageNumber(url) {
    return parseInt(url.split('per_page=')[1].match(/.*page=(\d+).*/)[1]);
  }

  function getBudgetPaginated(perPage, page, otherParams, acc, callback) {
    var url =
      _i.SERVER_ADDRESS_V1 +
      '/budget_items?per_page=' +
      perPage +
      '&page=' +
      page +
      '&' +
      otherParams;
    new injectJS(url, function (resp) {
      gData.addBudget(resp.data);
      var currData = acc.concat(resp.data);
      if (resp.paging.next) {
        var nextPage = extractPageNumber(resp.paging.next);
        getBudgetPaginated(perPage, nextPage, otherParams, currData, callback);
      } else {
        callback && callback(currData);
      }
    });
  }

  _i.getBudget = function (callback, project_id) {
    var otherParams;
    if (project_id) {
      otherParams = 'project_id=' + project_id + '&with_phases=true';
    } else {
      otherParams = 'no_assignables=true';
    }
    // Using a large page size here since the UI is not taking advantage of paging.
    // TODO: update UI on page fetched, not when last page is fetched
    var perPage = 9999;
    getBudgetPaginated(perPage, 1, otherParams, [], callback);
  };

  _i.createBudgetSingle = function (data, projectid, callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/budget_items',
      params = {};

    if (data.assignable_id) {
      params.assignable_id = data.assignable_id;
    }
    if (data.amount) {
      params.amount = data.amount;
    }
    if (data.category) {
      params.category = data.category;
    }
    if (data.peritem_amount) {
      params.peritem_amount = data.peritem_amount;
    }
    if (data.peritem_label) {
      params.peritem_label = data.peritem_label;
    }
    if (data.item_type) {
      params.item_type = data.item_type;
    }

    postJS(url, params, function (obj) {
      if (obj) {
        gData.addBudgetItem(obj);
        callback && callback(obj);
      }
    });
  };

  _i.updateBudgetItem = function (data, callback) {
    if (!data.id) return;

    var url =
        _i.SERVER_ADDRESS_V1 + '/budget_items/' + encodeURIComponent(data.id),
      params = {};
    if (data.assignable_id) {
      params.assignable_id = data.assignable_id;
    }
    if (data.amount) {
      params.amount = data.amount;
    }
    if (data.category) {
      params.category = data.category;
    }
    if (data.peritem_amount) {
      params.peritem_amount = data.peritem_amount;
    }
    if (data.peritem_label) {
      params.peritem_label = data.peritem_label;
    }

    putJS(url, params, function (dataret) {
      gData.updateBudgetItem(dataret);
      callback && callback(dataret);
    });
  };

  _i.deleteBudgetItem = function (data, callback) {
    if (!data.id) return;
    var url =
      _i.SERVER_ADDRESS_V1 + '/budget_items/' + encodeURIComponent(data.id);
    new deleteJS(url, function (dataret) {
      gData.deleteBudgetItem(data);
      callback && callback(dataret);
    });
  };

  _i.resetBrand = function (callback, onError) {
    var url = _i.SERVER_ADDRESS + '/organizations/delete_brand';

    deleteJS(
      url,
      function (response) {
        if (response.error) {
          if (onError) {
            onError(response);
          }
          return;
        }
        gData.resetBranding();
        callback && callback();
      },
      onError
    );
  };

  _i.updateBrand = function (data, callback, onError) {
    var url = _i.SERVER_ADDRESS + '/organizations/update_brand';

    var params = {};
    if (data.logoUrl) {
      params.logoUrl = data.logoUrl;
    }
    if (data.bgColor) {
      params.bgColor = data.bgColor;
    }
    if (data.headerColor) {
      params.headerColor = data.headerColor;
    }
    if (data.shadowEnabled != null) {
      params.shadowEnabled = data.shadowEnabled;
    }

    postJS(
      url,
      params,
      function (response) {
        if (response.error) {
          if (onError) {
            onError(response);
          }
          return;
        }
        if (response.data) ARC.expire('organizations');
        gData.updateBranding(response.data);
        callback && callback(response.data);
      },
      onError
    );
  };

  _i.getStatuses = function (callback) {
    new injectJS(
      _i.SERVER_ADDRESS + '/statuses',
      function (data) {
        gData.setStatuses(data);
        callback && callback();
      },
      null,
      false,
      false
    );
  };

  _i.getTimeEntries = function (callback, startDate, endDate, userId) {
    var url = _i.SERVER_ADDRESS + '/users/' + userId + '/time_entries?';

    if (startDate)
      url += 'startdate=' + encodeURIComponent(startDate.toRubyDate()) + '&';
    if (endDate)
      url += 'enddate=' + encodeURIComponent(endDate.toRubyDate()) + '&';
    if (userId) url += 'userid=' + userId;

    _i._getTimeEntriesInternal(url, callback);
  };

  _i._getTimeEntriesInternal = function (url, callback) {
    new injectJS(url, function (data) {
      gData.setTimeEntries(data);
      callback && callback(data);
    });
  };

  _i.deleteTimeEntry = function (data, callback, errorCallback) {
    var url = _i.SERVER_ADDRESS + '/time_entries/' + data.id;
    deleteJSV1(url, function () {
      if (data.error) {
        if (errorCallback) errorCallback(data);
      } else {
        gData.deleteTimeEntry(data);
        callback && callback(data);
      }
    });
  };

  _i.updateTimeEntry = function (data, callback, errorCallback) {
    gData.deleteTimeEntry(data);

    var url = _i.SERVER_ADDRESS + '/time_entries/' + data.id;
    var payload = {};

    payload['user_id'] = data.user_id;
    payload['assignable_id'] = data.assignable_id;
    payload['hours'] = data.hours;
    date = typeof data.date == 'string' ? new Date(data.date) : data.date;
    date.toStartOfDay();
    payload['date'] = date;
    if (data.task) {
      payload['task'] = data.task;
    }
    if (data.notes) {
      payload['notes'] = data.notes;
    }

    putJSV1(url, payload, function (response) {
      if (response.error) {
        if (errorCallback) errorCallback(response);
      } else {
        gData.addTimeEntry(response.data);
        callback && callback(response.data);
      }
    });
  };

  _i.createTimeEntry = function (data, callback, errorCallback) {
    var url = _i.SERVER_ADDRESS + '/time_entries';
    var payload = {};

    payload['user_id'] = data.user_id;
    payload['assignable_id'] = data.assignable_id;
    payload['hours'] = data.hours;
    date = typeof data.date == 'string' ? new Date(data.date) : data.date;
    date.toStartOfDay();
    payload['date'] = date;
    if (data.task) {
      payload['task'] = data.task;
    }
    if (data.notes) {
      payload['notes'] = data.notes;
    }
    postJSV1(url, payload, function (response) {
      if (response.error) {
        if (errorCallback) errorCallback(response);
      } else {
        gData.addTimeEntry(response.data);
        callback && callback(response.data);
      }
    });
  };

  _i.setUserSettingsValue = function (value, callback, errorCallback) {
    var me = gData.getMe();
    if (!me) return;
    var url = _i.SERVER_ADDRESS + '/users/' + me.id + '/usersettings';
    postJSV1(url, { newvalue: value }, function (response) {
      if (response.error) {
        if (errorCallback) errorCallback(response);
      } else {
        gData.getMe().user_settings = response.data.user_settings;
        callback && callback(response);
        ARC.expire('users');
      }
    });
  };

  _i.setStatus = function (data, callback, errorCallback) {
    var url = _i.SERVER_ADDRESS + '/statuses';
    var payload = {};

    payload['status'] = data.status;
    payload['user_id'] = data.user_id;
    if (data.assignable_id) payload['assignable_id'] = data.assignable_id;
    if (data.message) payload['message'] = data.message;

    postJSV1(url, payload, function (response) {
      if (response.error) {
        if (errorCallback) errorCallback(response);
      } else {
        gData.setStatus(response.data);
        callback && callback(response);
      }
    });
  };

  _i.findUserCalendar = function (callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/users/' + gData.getMe().id + '/calendar';
    new getJSON(url, callback);
  };

  _i.createUserCalendar = function (body, callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/users/' + gData.getMe().id + '/calendar';
    postJSV1(url, body, callback);
  };

  _i.updateUserCalendar = function (body, callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/users/' + gData.getMe().id + '/calendar';
    putJSV1(url, body, callback);
  };

  _i.getUserAuthorizedApplications = function (callback) {
    var url = window.APP_ENDPOINT + '/oauth/authorized_applications.json';
    new getJSON(url, callback);
  };

  _i.revokeOAuthApplication = function (id, callback) {
    var url = window.APP_ENDPOINT + '/oauth/authorized_applications/' + id;
    new deleteJSV1(
      url,
      function () {
        callback && callback();
      },
      function (error) {
        callback(error || 'failed to revoke OAuth application');
      }
    );
  };

  _i.getUserNotificationSettings = function (callback, error) {
    var impMode = getCookie(AppCookie.ImpMode) || null;
    var userId = gData.getMe().id;
    var params = {
      utc_offset: new Date().getTimezoneOffset(),
    };
    if (impMode) params.imp_mode = true;

    var url =
      ENS_ENDPOINT +
      '/notification_settings/users/' +
      userId +
      '?' +
      $.param(params);
    new getJSON(url, callback);
  };

  _i.isFollowingProjectById = function (projectId, callback) {
    var url =
      _i.SERVER_ADDRESS_V1 +
      '/projects/' +
      projectId +
      '/followers/' +
      gData.getMe().id;
    new injectJS(url, function (results) {
      callback && callback(null, results);
    });
  };

  _i.followProjectById = function (projectId, callback, errorCallback) {
    var userId = gData.getMe().id;
    var url = _i.SERVER_ADDRESS_V1 + '/projects/' + projectId + '/followers/';
    postJSV1(
      url,
      { id: userId },
      function (results) {
        callback(null, results);
      },
      function (error) {
        errorCallback(error);
      }
    );
  };

  _i.unfollowProjectById = function (projectId, callback) {
    var userId = gData.getMe().id;
    var url =
      _i.SERVER_ADDRESS_V1 + '/projects/' + projectId + '/followers/' + userId;
    deleteJS(
      url,
      function (results) {
        callback(null, results);
      },
      function (error) {
        callback(error || 'failed to unfollow project ' + projectId);
      }
    );
  };

  _i.getProjectNotificationSettings = function (projectId, callback) {
    var url = ENS_ENDPOINT + '/notification_settings/projects/' + projectId;
    new getJSON(url, callback);
  };

  _i.updateUserNotificationSettings = function (data) {
    var userId = gData.getMe().id;
    var body = {
      timezone_utc_offset: data.offset,
      notifications_enabled: data.status === 'off' ? false : true,
    };
    if (data.status !== 'off') {
      body.send_notifications = data.status;
    }

    var url = ENS_ENDPOINT + '/notification_settings/users/' + userId;
    putJSV1(url, body);
  };

  _i.updateProjectNotificationSettings = function (projectId, data, callback) {
    var url =
      _i.SERVER_ADDRESS_V1 + '/notification_settings/projects/' + projectId;
    putJSV1(url, data, callback, callback);
  };

  _i.createProjectNotificationSettings = function (data, callback) {
    var url = _i.SERVER_ADDRESS_V1 + '/notification_settings/projects/';
    postJSV1(url, data, callback);
  };

  _i.getS3CredentialsProjectImage = function (
    extension,
    projectId,
    callback,
    errorCallback
  ) {
    var url =
      _i.SERVER_ADDRESS +
      '/thumbcreds/project?resource_id=' +
      projectId +
      '&extension=' +
      extension;

    new injectJS(url, function (response) {
      if (response.error) {
        if (errorCallback) errorCallback(response);
      } else {
        callback && callback(response);
      }
    });
  };

  _i.getS3CredentialsNewProjectImage = function (
    extension,
    _projectId,
    callback,
    errorCallback
  ) {
    var url = _i.SERVER_ADDRESS + '/thumbcreds/project?extension=' + extension;

    new injectJS(url, function (response) {
      if (response.error) {
        if (errorCallback) errorCallback(response);
      } else {
        callback && callback(response);
      }
    });
  };

  _i.getS3CredentialsNewUserImage = function (
    extension,
    _userId,
    callback,
    errorCallback
  ) {
    var url = _i.SERVER_ADDRESS + '/thumbcreds/user?extension=' + extension;

    new injectJS(url, function (response) {
      if (response.error) {
        if (errorCallback) errorCallback(response);
      } else {
        callback && callback(response);
      }
    });
  };

  _i.getS3CredentialsUserImage = function (
    extension,
    userId,
    callback,
    errorCallback
  ) {
    var url =
      _i.SERVER_ADDRESS +
      '/thumbcreds/user?resource_id=' +
      userId +
      '&extension=' +
      extension;

    new injectJS(url, function (response) {
      if (response.error) {
        if (errorCallback) errorCallback(response);
      } else {
        callback && callback(response);
      }
    });
  };

  _i.getS3CredentialsLogoImage = function (
    extension,
    _orgId,
    callback,
    errorCallback
  ) {
    var url = _i.SERVER_ADDRESS + '/thumbcreds/logo?extension=' + extension;

    new injectJS(url, function (response) {
      if (response.error) {
        if (errorCallback) errorCallback(response);
      } else {
        callback && callback(response);
      }
    });
  };

  _i.getSearchData = function (callback, errorCallback, force, withDeleted) {
    var sd = gData.getSearchData();
    if (!sd) {
      gData.buildSearchData();
    }

    if (!sd || force) {
      var url = _i.SERVER_ADDRESS + '/search';
      if (withDeleted) {
        url += '?with_deleted=true';
      }
      new injectJS(url, function (response) {
        if (response.error) {
          if (errorCallback) errorCallback(response);
        } else {
          gData.setSearchData(response);
          callback && callback(response);
        }
      });
    } else {
      callback && callback(sd);
    }
  };

  _i.getSearchResults = function (query, callback, errorCallback) {
    var queryString = encodeURIComponent(query);
    var url = _i.SERVER_ADDRESS + '/search?query=' + queryString;
    new injectJS(url, function (response) {
      callback && callback(response);
    });
  };
}
