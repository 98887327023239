const DRAG_HANDLE_ICON = `
<svg width="8" height="18" viewBox="0 0 8 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<g>
<path d="M2.24993 3.79996C1.86333 3.79996 1.54993 4.11336 1.54993 4.49996C1.54993 4.88656 1.86333 5.19996 2.24993 5.19996H5.74993C6.13653 5.19996 6.44993 4.88656 6.44993 4.49996C6.44993 4.11336 6.13653 3.79996 5.74993 3.79996H2.24993Z" fill="#111111"/>
<path d="M2.24993 6.79996C1.86333 6.79996 1.54993 7.11336 1.54993 7.49996C1.54993 7.88656 1.86333 8.19996 2.24993 8.19996H5.74993C6.13653 8.19996 6.44993 7.88656 6.44993 7.49996C6.44993 7.11336 6.13653 6.79996 5.74993 6.79996H2.24993Z" fill="#111111"/>
<path d="M1.54993 10.5C1.54993 10.1134 1.86333 9.79996 2.24993 9.79996H5.74993C6.13653 9.79996 6.44993 10.1134 6.44993 10.5C6.44993 10.8866 6.13653 11.2 5.74993 11.2H2.24993C1.86333 11.2 1.54993 10.8866 1.54993 10.5Z" fill="#111111"/>
<path d="M2.24993 12.8C1.86333 12.8 1.54993 13.1134 1.54993 13.5C1.54993 13.8866 1.86333 14.2 2.24993 14.2H5.74993C6.13653 14.2 6.44993 13.8866 6.44993 13.5C6.44993 13.1134 6.13653 12.8 5.74993 12.8H2.24993Z" fill="#111111"/>
</g>
</svg>`;

const CHEVRON_DOWN_ICON = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 7.5L10 12.5L5 7.5" stroke="#979797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
const CHEVRON_UP_ICON = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 12.5L10 7.5L15 12.5" stroke="#979797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;
const LOCK_ICON = `<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.37705 3.22297C3.37705 2.3266 4.1037 1.59995 5.00007 1.59995C5.89643 1.59995 6.62308 2.3266 6.62308 3.22297V3.5409H3.37705V3.22297ZM1.97705 3.5409V3.22297C1.97705 1.5534 3.3305 0.199951 5.00007 0.199951C6.66963 0.199951 8.02308 1.5534 8.02308 3.22297V3.5409H9.12983C9.48881 3.5409 9.77983 3.83192 9.77983 4.1909V10.9018C9.77983 11.2608 9.48881 11.5518 9.12983 11.5518H0.870215C0.51123 11.5518 0.220215 11.2608 0.220215 10.9018V4.1909C0.220215 3.83192 0.51123 3.5409 0.870215 3.5409H1.97705ZM1.52021 10.2518V4.8409H8.47983V10.2518H1.52021ZM5.95277 7.09178C5.95277 7.3667 5.83633 7.61442 5.65007 7.78832V8.7185C5.65007 9.07748 5.35905 9.3685 5.00007 9.3685C4.64108 9.3685 4.35007 9.07748 4.35007 8.7185V7.78839C4.16377 7.61449 4.04729 7.36674 4.04729 7.09178C4.04729 6.56559 4.47385 6.13904 5.00003 6.13904C5.52621 6.13904 5.95277 6.56559 5.95277 7.09178Z" fill="#666666"/>
</svg>
`;
const INFO_ICON = `<svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="7.25" stroke="#979797" stroke-width="1.5"/>
<path d="M7.25 7.5H8V11.5" stroke="#979797" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
<circle cx="8" cy="5.25" r="0.75" fill="#979797"/>
</svg>
`;

const svgIcons = {
  DRAG_HANDLE_ICON,
  CHEVRON_DOWN_ICON,
  CHEVRON_UP_ICON,
  LOCK_ICON,
  INFO_ICON,
};

window.svgs = svgIcons;
