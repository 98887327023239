var available_locales = [
  { code: 'hy', label: 'Armenia' },
  { code: 'en-AU', label: 'Australia' },
  { code: 'de-AT', label: 'Austria' },
  { code: 'be', label: 'Belgium' },
  { code: 'pt-BR', label: 'Brazil' },
  { code: 'en-CA', label: 'Canada' },
  { code: 'zh-CN', label: 'China' },
  { code: 'cs', label: 'Czech Republic' },
  { code: 'da', label: 'Denmark' },
  { code: 'en-EG', label: 'Egypt' },
  { code: 'fi', label: 'Finland' },
  { code: 'fr', label: 'France' },
  { code: 'de', label: 'Germany' },
  { code: 'en-HK', label: 'Hong Kong' },
  { code: 'de-HU', label: 'Hungary' },
  { code: 'is', label: 'Iceland' },
  { code: 'en-IN', label: 'India' },
  { code: 'en-ID', label: 'Indonesia' },
  { code: 'en-IR', label: 'Ireland' },
  { code: 'he', label: 'Israel' },
  { code: 'it', label: 'Italy' },
  { code: 'ja', label: 'Japan' },
  { code: 'en-KY', label: 'Kenya' },
  { code: 'ko', label: 'Korea' },
  { code: 'ms', label: 'Malaysia' },
  { code: 'en-MU', label: 'Mauritius' },
  { code: 'es-MX', label: 'Mexico' },
  { code: 'nl', label: 'Netherlands' },
  { code: 'en-NZ', label: 'New Zealand' },
  { code: 'nn', label: 'Norway' },
  { code: 'en-PG', label: 'Papua New Guinea' },
  { code: 'en-PH', label: 'Philippines' },
  { code: 'pl', label: 'Poland' },
  { code: 'pt-PT', label: 'Portugal' },
  { code: 'ru', label: 'Russia' },
  { code: 'en-SA', label: 'Saudi Arabia' },
  { code: 'en-SG', label: 'Singapore' },
  { code: 'en-ZA', label: 'South Africa' },
  { code: 'es', label: 'Spain' },
  { code: 'sv-SE', label: 'Sweden' },
  { code: 'de-CH', label: 'Switzerland' },
  { code: 'zh-TW', label: 'Taiwan' },
  { code: 'th', label: 'Thailand' },
  { code: 'tr', label: 'Turkey' },
  { code: 'en-AE', label: 'United Arab Emirates' },
  { code: 'en-GB', label: 'United Kingdom' },
  { code: 'en-US', label: 'United States' },
];

var Z_POPUP = 100;
var Z_TIME_UNIT_BUTTONS = 50;
var Z_NAVIGATION_BUTTONS = 50;
var Z_TIME_SLIDER = 40;
var Z_PROJECT_BAR = 35;
var Z_ROW_HEADER = 30;
var Z_GRID_LINES = 20;
var Z_ASSIGNMENT_SELECTED = 10;
var Z_ASSIGNMENT = 1;
var Z_GRID_FREE_TIME_MASK = 0;

I18n.fallbacks = true;

document.addEventListener('DOMContentLoaded', function (e) {
  const el = document.getElementById('mainCon');
  if (window.isIframeView && el.className !== '') el.className = '';

  const isTranslationsModuleEnabled =
    window.accountSettings &&
    window.accountSettings.modules_enabled.find(
      (m) => m.name === 'translations' && m.value === '1'
    );

  I18n.language = isTranslationsModuleEnabled
    ? window.navigator.language
    : 'en';

  const armsLocale = localStorage.getItem('ARMSLocale');
  I18n.locale =
    (window.isIframeView && armsLocale) || window.navigator.languages[0];
  I18n.language =
    (window.isIframeView && armsLocale) || window.navigator.languages[0];
});

function alreadySignedIn() {
  return window.whoami != null;
}

function inImpMode() {
  return getCookie(AppCookie.ImpMode) == 'true';
}

function eventTimer() {
  var _i = this;
  _i.timestamps = {};

  _i.createTimestamp = function (pageSection, startOrEnd) {
    if (!_i.timestamps[pageSection]) {
      _i.timestamps[pageSection] = { start: null, end: null };
    }
    _i.timestamps[pageSection][startOrEnd] = performance.now();
  };

  _i.logTimeDifference = function (pageSection) {
    var start = _i.timestamps[pageSection].start;
    var end = _i.timestamps[pageSection].end;
    _i.log(pageSection, start, end);
  };

  _i.logTTI = function () {
    const pageInit = _i.timestamps.overall.start;
    const now = performance.now();
    _i.log('TTI', pageInit, now);
    _i.createGAEntry('TTI', null, now);
  };

  _i.log = function (label, evt1, evt2) {
    console.log('[MONITOR]' + label + ':     ' + (evt2 - evt1) / 1000 + 's');
  };

  _i.start = function () {
    _i.createTimestamp('overall', 'start');
  };

  _i.end = function () {
    _i.createTimestamp('overall', 'end');
    _i.logTimeDifference('overall');
    _i.logStats();
  };

  _i.logStats = function () {
    for (var section in _i.timestamps) {
      var wholePageTime =
        _i.timestamps.overall.end - _i.timestamps.overall.start;
      var end = _i.timestamps[section].end;
      var start = _i.timestamps[section].start || _i.timestamps.overall.start;
      if (!(end && start)) return;

      var sectionTime = end - start;
      _i.instrumentTime(section, sectionTime);
      _i.instrumentPercentage(section, sectionTime, wholePageTime);
    }
  };

  _i.instrumentPercentage = function (section, sectionTime, wholePageTime) {
    var percentage = Math.round((sectionTime / wholePageTime) * 100);
    console.log('[MONITOR]' + section + ': ' + percentage + '% of overall');
    _i.percentageToGA(section, percentage);
  };

  _i.percentageToGA = function (section, percentage) {
    _i.createGAEntry('percentageOfTotalLoad', section, percentage);
  };

  _i.instrumentTime = function (section, sectionTime) {
    console.log('[MONITOR]' + section + ': ' + sectionTime / 1000 + 's total');
    _i.timeToGA(section, sectionTime);
  };

  _i.timeToGA = function (section, sectionTime) {
    _i.createGAEntry('loadTime', section, sectionTime);
  };

  _i.createGAEntry = function (action, label, value) {
    var eLabel = 'org_' + gData.getUserAndProjectDataSize();
    if (label) eLabel += '_' + label;
    // GA expects value to be of type int
    trackGAEvent('analyticsPage', action, eLabel, Math.round(value));
  };
}

function requireAuthToken() {
  var hasToken = window.whoami && window.whoami.id,
    currentUrl;
  if (!hasToken) {
    deleteAllCookies();
    clearSessionStorage();
    currentUrl = location.pathname + location.search;
    setSessionCookie('signin_return_url', encodeURIComponent(currentUrl));
    window.location = SIGN_IN_URL;
  }
}

function getSigninReturnUrl() {
  var ru = decodeURIComponent(getCookie('signin_return_url'));
  if (
    ru == null ||
    ru == '' ||
    ru.indexOf('/') != 0 /* only allow relative urls */
  ) {
    ru = '/';
  }
  deleteCookie('signin_return_url');
  return ru;
}

function signOut(callback) {
  var signOutUrl = APP_ENDPOINT + '/api/v1/sessions/signout';
  if (inImpMode()) signOutUrl += '?imp=true';

  deleteAllCookies();
  clearSessionStorage();
  getJS(signOutUrl, null, callback, function () {
    __A(false, 'signout failed');
  });
}

var platform = {};
platform.touch = false;

if (!PRODUCTION_BUILD && BrowserDetect.OS == 'iPad') {
  platform.touch = true;
  platform.click = 'touchstart';
  platform.mouseDown = 'touchstart';
  platform.mouseUp = 'touchend';
  platform.dragstart = 'touchstart';
  platform.drag = 'touchmove';
  platform.dragend = 'touchend';
} else if (false) {
  //(window.navigator.msPointerEnabled)
  platform.touch = true;
  platform.click = 'click';
  platform.mouseDown = 'MSPointerDown';
  platform.mouseUp = 'MSPointerUp';
  platform.dragstart = 'MSPointerDown';
  platform.drag = 'MSPointerMove';
  platform.dragend = 'MSPointerUp';
} else {
  platform.click = 'click';
  platform.mouseDown = 'mousedown';
  platform.mouseUp = 'mouseup';
  platform.dragstart = 'mousedown';
  platform.drag = 'mousemove';
  platform.dragend = 'mouseup';
}

// Transform properties for different browsers
var transformPropertyNames = [
  'MozTransform',
  'webkitTransform',
  'OTransform',
  'msTransform',
];

// Notifications & Feed structures
var FeedControllers = {};
var FeedModels = {};
var FeedTemplates = {};

window.orgPageTimer = new eventTimer();
