function deletionModal(options) {
  const modal = document.createElement('div');
  modal.className = 'deletion-modal';
  modal.innerHTML = `
      <div class="modal-content">
        <p><strong style="font-size: 20px;">${I18n.t(
          'lbl_allow_timesheet_changes'
        )}</strong></p>
        <p>${I18n.t('msg_confirm_deletion')}</p>
        <button id="cancel-delete">${I18n.t('lbl_cancel')}</button>
        <button id="confirm-delete">${I18n.t('lbl_confirm')}</button>
      </div>
    `;
  document.body.appendChild(modal);

  // Add event listeners to the modal buttons
  document
    .getElementById('confirm-delete')
    .addEventListener('click', function () {
      // Handle deletion confirmation
      modal.remove();
    });

  document
    .getElementById('cancel-delete')
    .addEventListener('click', function () {
      // Handle deletion cancellation
      if (options && typeof options.onCancel === 'function') {
        options.onCancel();
      }
      modal.remove();
    });
}
