function ActiveProjectsView(
  attach,
  summaryattach,
  dollarview,
  hideWhenEmpty,
  renderCallback
) {
  var _i = this;

  if (!(this instanceof ActiveProjectsView)) {
    return new ActiveProjectsView(
      attach,
      summaryattach,
      dollarview,
      hideWhenEmpty
    );
  }

  _i.scale = -1;
  _i.attachTo = xGetElementById(attach);
  _i.summaryattach = xGetElementById(summaryattach);
  _i.activeprojects = [];
  _i.builtProjectsById = {};
  _i.builtProjectSummaryById = {};
  _i.showdollars = dollarview;
  _i.hideWhenEmpty = hideWhenEmpty;
  _i.showEverything = false;
  _i.dollars = _i.showdollars;
  _i.initialFetchCount = 5;

  _i.dollarsClick = function () {
    if (!_i.dollars) {
      _i.dollars = true;
      _i.render();
    }
  };

  _i.daysClick = function () {
    if (_i.dollars) {
      _i.dollars = false;
      _i.render();
    }
  };

  _i.render = function () {
    _i.scale = 0;
    _i.daysbutton.className =
      'blockFloat fnt-r-12 ' +
      (_i.dollars ? 'projectStatusNotSelected' : 'projectStatusSelected');
    _i.dollarsbutton.className =
      'blockFloat fnt-r-12 ' +
      (_i.dollars ? 'projectStatusSelected' : 'projectStatusNotSelected');
    removeAllChildren(_i.activeProjectsContainer);
    _i.buildLegend();
    _i.activeProjectsContainer.appendChild(_i.projcontainer);
    _i.updateProjects();
  };

  _i.getProjectsAPIURL = function (opts) {
    return (
      getBaseURL() +
      'api/v1/projects?fields=summary,children,budget_totals&per_page=' +
      opts.perPage +
      '&analytics_view=' +
      opts.view
    );
  };

  _i.buildLabelTextSpan = function (label) {
    var tspan = document.createElement('SPAN');
    tspan.className = 'fnt-r-12';
    tspan.innerText = label;
    return tspan;
  };

  _i.projectsloaded = function () {
    for (var i = 0; i < _i.mydata.projectDataViewArray.length; i++) {
      if (
        _i.builtProjectSummaryById[_i.mydata.projectDataViewArray[i].project.id]
      ) {
        continue;
      }

      var percent = 0;

      if (_i.dollars) {
        percent =
          ((_i.activeprojects[i].data.totalbudgeteddollars -
            _i.activeprojects[i].data.totalassigneddollars) /
            _i.activeprojects[i].data.totalbudgeteddollars) *
          100;
        percent = Math.abs(percent);
        if (
          _i.activeprojects[i].data.totalbudgeteddollars == 0 &&
          _i.activeprojects[i].data.totalassigneddollars != 0
        )
          percent = 'nobudget';
        else if (_i.activeprojects[i].data.totalbudgeteddollars == 0)
          percent = 'nobudget';
        else if (_i.activeprojects[i].data.totalassigneddollars == 0)
          percent = 100;
      } else {
        percent =
          ((_i.activeprojects[i].data.totalbudgeted -
            _i.activeprojects[i].data.totalassigned) /
            _i.activeprojects[i].data.totalbudgeted) *
          100;
        percent = Math.abs(percent);
        if (
          _i.activeprojects[i].data.totalbudgeted == 0 &&
          _i.activeprojects[i].data.totalassigned != 0
        )
          percent = 'nobudget';
        else if (_i.activeprojects[i].data.totalbudgeted == 0)
          percent = 'nobudget';
        else if (_i.activeprojects[i].data.totalassigned == 0) percent = 100;
      }

      _i.mydata.projectDataViewArray[i].projectpercent =
        new ProjectPercentScheduledSummaryView(
          _i.summarycontainer.id,
          _i.mydata.projectDataViewArray[i].project,
          _i.activeprojects[i].budgetstring,
          _i.activeprojects[i].over,
          percent,
          _i.dollars
        );

      var spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot settingsSpacerImage1';
      spacer.appendChild(getSprite(compSpritesButtonsIcons.grayLine180));
      _i.summarycontainer.appendChild(spacer);

      _i.builtProjectSummaryById[
        _i.mydata.projectDataViewArray[i].project.id
      ] = true;
    }

    if (gPageHeader) {
      gPageHeader.setSpinner(false);
    }
  };

  _i.updateProjectsDollars = function () {
    total = 0;
    for (var i = 0; i < _i.activeprojects.length; i++) {
      if (_i.activeprojects[i].loaded) {
        total++;
        if (_i.activeprojects[i].data.totalbudgeteddollars > _i.scale) {
          _i.scale = _i.activeprojects[i].data.totalbudgeteddollars;
        }

        if (_i.activeprojects[i].data.totalcompleteddollars > _i.scale) {
          _i.scale = _i.activeprojects[i].data.totalcompleteddollars;
        }

        if (_i.activeprojects[i].data.totalassigneddollars > _i.scale) {
          _i.scale = _i.activeprojects[i].data.totalassigneddollars;
        }
      }
    }

    if (total == _i.activeprojects.length) {
      removeAllChildren(_i.projcontainer);
      for (var i = 0; i < _i.activeprojects.length; i++) {
        if (_i.activeprojects[i].loaded) {
          _i.activeprojects[i].render(_i.scale, true);
          var spacer = document.createElement('DIV');
          spacer.className = 'blockFloatNot settingsSpacerImage1';
          spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
          _i.projcontainer.appendChild(spacer);
        }
      }
      _i.projectsloaded();
    }
  };

  _i.updateProjects = function () {
    if (_i.dollars) {
      return _i.updateProjectsDollars();
    }
    return _i.updateProjectsDays();
  };

  _i.updateProjectsDays = function () {
    total = 0;
    for (var i = 0; i < _i.activeprojects.length; i++) {
      if (_i.activeprojects[i].loaded) {
        total++;
        if (_i.activeprojects[i].data.totalbudgeted > _i.scale) {
          _i.scale = _i.activeprojects[i].data.totalbudgeted;
        }

        if (_i.activeprojects[i].data.totalcompleted > _i.scale) {
          _i.scale = _i.activeprojects[i].data.totalcompleted;
        }

        if (_i.activeprojects[i].data.totalassigned > _i.scale) {
          _i.scale = _i.activeprojects[i].data.totalassigned;
        }
      }
    }

    if (total == _i.activeprojects.length) {
      removeAllChildren(_i.projcontainer);
      for (var i = 0; i < _i.activeprojects.length; i++) {
        if (_i.activeprojects[i].loaded) {
          _i.activeprojects[i].render(_i.scale, false);
          var spacer = document.createElement('DIV');
          spacer.className = 'blockFloatNot settingsSpacerImage1';
          spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
          _i.projcontainer.appendChild(spacer);
        }
      }
      _i.projectsloaded();
    }
  };

  _i.buildProjects = function () {
    for (var i = 0; i < _i.mydata.projectDataViewArray.length; i++) {
      if (_i.builtProjectsById[_i.mydata.projectDataViewArray[i].project.id]) {
        continue;
      }

      _i.activeprojects.push(
        new ActiveProjectView(
          _i.projcontainer,
          _i.mydata.projectDataViewArray[i],
          _i
        )
      );
      var spacer = document.createElement('DIV');
      spacer.className = 'blockFloatNot settingsSpacerImage1';
      spacer.appendChild(getSprite(compSpritesButtonsIcons.spacerLineFull));
      _i.projcontainer.appendChild(spacer);

      _i.builtProjectsById[_i.mydata.projectDataViewArray[i].project.id] = true;
    }

    if (_i.activeprojects.length == 0) {
      _i.projectsloaded();
      removeAllChildren(_i.container);
      _i.buildHeader();
      var textdiv = document.createElement('DIV');
      textdiv.className = 'blockFloat activeProjectLegendText';
      textdiv.style.color = '#666666';
      textdiv.appendChild(
        _i.buildLabelTextSpan(I18n.t('msg_no_active_projects'))
      );
      _i.container.appendChild(textdiv);
      removeAllChildren(xGetElementById('pageRightContainer'));
    }
  };

  _i.buildLegend = function () {
    var container = document.createElement('DIV');
    container.className = 'blockFloatNot activeProjectLegend';
    _i.activeProjectsContainer.appendChild(container);

    var iconContainer = document.createElement('DIV');
    iconContainer.className = 'blockFloat activeProjectLegendIcon';
    getSprite(compSpritesButtonsIcons.barGray, iconContainer);
    container.appendChild(iconContainer);

    var textdiv = document.createElement('DIV');
    textdiv.className = 'blockFloat activeProjectLegendText';
    if (_i.dollars) {
      textdiv.appendChild(_i.buildLabelTextSpan(I18n.t('lbl_project_budget')));
    } else {
      textdiv.appendChild(
        _i.buildLabelTextSpan(I18n.t('lbl_project_budget_days'))
      );
    }
    container.appendChild(textdiv);

    iconContainer = document.createElement('DIV');
    iconContainer.className = 'blockFloat activeProjectLegendIcon';
    getSprite(compSpritesButtonsIcons.barDarkblue, iconContainer);
    container.appendChild(iconContainer);

    textdiv = document.createElement('DIV');
    textdiv.className = 'blockFloat activeProjectLegendText';
    if (_i.dollars) {
      textdiv.appendChild(_i.buildLabelTextSpan(I18n.t('lbl_incurred')));
    } else {
      textdiv.appendChild(_i.buildLabelTextSpan(I18n.t('lbl_incurred_days')));
    }
    container.appendChild(textdiv);

    iconContainer = document.createElement('DIV');
    iconContainer.className = 'blockFloat activeProjectLegendIcon';
    getSprite(compSpritesButtonsIcons.barLightblue, iconContainer);
    container.appendChild(iconContainer);

    textdiv = document.createElement('DIV');
    textdiv.className = 'blockFloat activeProjectLegendText';
    if (_i.dollars) {
      textdiv.appendChild(
        _i.buildLabelTextSpan(I18n.t('lbl_future_scheduled'))
      );
    } else {
      textdiv.appendChild(
        _i.buildLabelTextSpan(I18n.t('lbl_future_scheduled_days'))
      );
    }
    container.appendChild(textdiv);
  };

  _i.buildHeader = function () {
    _i.header = document.createElement('DIV');
    _i.header.className = 'blockFloatNot activeProjectHeaderContainer';
    _i.container.appendChild(_i.header);
    _i.headerText = document.createElement('H2');
    _i.headerText.className = 'blockFloat fnt-r-20';
    _i.headerText.innerHTML = _i.dollars
      ? I18n.t('lbl_active_projects_tracked_in_currency')
      : I18n.t('lbl_active_projects_tracked_in_days');
    _i.header.appendChild(_i.headerText);

    _i.activeProjectsContainer = document.createElement('DIV');
    _i.activeProjectsContainer.className = 'blockFloatNot';
    _i.container.appendChild(_i.activeProjectsContainer);
  };

  _i.buildShowEverythingLink = function () {
    var numberOfHiddenProjects = _i.mydata.hiddenProjects;
    if (!_i.showEverything && numberOfHiddenProjects > 0) {
      var more = document.createElement('DIV');
      more.className = 'blockFloat fnt-r-12 activeProjectShowMoreProjects';
      more.innerHTML = I18n.t('msg_show_projects', {
        count: numberOfHiddenProjects,
      });
      _i.container.appendChild(more);

      more.addEventListener('click', function () {
        gPageHeader && gPageHeader.setSpinner(true);
        _i.showEverything = true;
        more.innerHTML = '';
        _i.loadMoreSpinner = new loadingSpinnerView(_i.container);
        _i.container.appendChild(_i.loadMoreSpinner);
        var gaLabel = 'Show More Projects Link: ';
        gaLabel += _i.dollars ? 'Currency' : 'Days';
        trackGAEvent('Analytics Page', 'click', gaLabel);
        var url = _i.getProjectsAPIURL({ perPage: 99999, view: _i.view });
        injectJS(
          url,
          function (response) {
            _i.container.removeChild(_i.loadMoreSpinner);
            var projects = response.data;
            _i.mydata = new ActiveProjectsView_Data(_i.showdollars, projects);
            _i.mydata.updateActiveProjects();
            _i.buildProjects();
            _i.updateProjects();
          },
          function (jqXHR, textStatus, errorShown) {
            _i.handleShowMoreError();
          }
        );
      });
    }
  };

  _i.handleShowMoreError = function () {
    _i.handleError(_i.loadMoreSpinner, 'more projects');
  };

  _i.handleInitError = function () {
    _i.handleError(_i.spinner, 'budget data');
    renderCallback();
  };

  _i.handleError = function (spinnerToRemove, errorType) {
    _i.container.removeChild(spinnerToRemove);
    var errorMsg = document.createElement('div');
    errorMsg.className = 'blockFloat fnt-r-12 organizationShowMoreError';
    errorMsg.innerHTML =
      `${I18n.t('err_unable_to_get_item')} ` +
      errorType +
      `. ${I18n.t('msg_contact_support')} ` +
      window.SMARTSHEET_SUPPORT_URL;
    gPageHeader.setSpinner(false);
    _i.container.appendChild(errorMsg);
  };

  _i.constructor = function () {
    if (gPageHeader) gPageHeader.setSpinner(true);
    _i.container = document.createElement('DIV');
    _i.container.className = 'blockFloatNot activeProjectViewContainer';
    _i.attachTo.appendChild(_i.container);
    _i.buildHeader();
    _i.spinner = new loadingSpinnerView(_i.container);

    _i.view = _i.dollars ? 'TimeFees' : 'TimeFeesDays';
    var url = _i.getProjectsAPIURL({
      perPage: _i.initialFetchCount,
      view: _i.view,
    });
    injectJS(
      url,
      function (response) {
        var projects = response.data;
        if (projects.length != 0) {
          _i.buildLegend();
          _i.summarycontainer = document.createElement('DIV');
          _i.summarycontainer.className =
            'blockFloatNot activeProjectUserViewContainer';
          _i.summarycontainer.id =
            'activeProjectSummaryViewContainer' + getGuid();

          var existingChildViewContainer = _i.summaryattach.querySelector(
            '.activeProjectUserViewContainer'
          );
          if (dollarview && !!existingChildViewContainer) {
            // handles day view loading first
            _i.summaryattach.prepend(_i.summarycontainer);
          } else {
            _i.summaryattach.appendChild(_i.summarycontainer);
          }

          _i.summaryheader = document.createElement('DIV');
          _i.summaryheader.className =
            'blockFloatNot activeProjectHeaderContainer2';
          _i.summarycontainer.appendChild(_i.summaryheader);
          _i.summaryheaderText = document.createElement('DIV');
          _i.summaryheaderText.className = 'blockFloat fnt-r-20';
          _i.summaryheaderText.innerHTML = I18n.t('lbl_forecasted_budget');
          _i.summaryheader.appendChild(_i.summaryheaderText);
          _i.projcontainer = document.createElement('DIV');
          _i.projcontainer.className = 'blockFloatNot';
          _i.activeProjectsContainer.appendChild(_i.projcontainer);
        } else {
          var textdiv = document.createElement('DIV');
          textdiv.className = 'blockFloatNot activeProjectLegendText';
          textdiv.style.color = '#727272';
          textdiv.appendChild(
            _i.buildLabelTextSpan(I18n.t('msg_no_active_projects'))
          );
          _i.container.appendChild(textdiv);
        }
        var remainingProjects = response.paging.count - _i.initialFetchCount;
        _i.mydata = new ActiveProjectsView_Data(
          _i.showdollars,
          projects,
          remainingProjects
        );
        _i.mydata.updateActiveProjects();
        _i.container.removeChild(_i.spinner);
        _i.postInit();
      },
      function (jqXHR, textStatus, errorShown) {
        _i.handleInitError();
      }
    );
  };

  _i.postInit = function () {
    if (_i.mydata.projectDataViewArray.length == 0 && _i.hideWhenEmpty) {
      gPageHeader.setSpinner(false);
    } else {
      _i.buildProjects();
      _i.updateProjects();
      _i.buildShowEverythingLink();
    }
    renderCallback && renderCallback();
  };

  _i.constructor();
}
