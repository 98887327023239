/*
 * Format filters that are supported by the TextInput control.
 */
var TextInputFilter = {
  None: 0,
  Integer: 1,
  Numeric: 2,
  Email: 3,
  TimeEntry: 4,
};

var _TextInputFilterFloatRegEx = RegExp(
  '^\\d*' + I18n.lookup('number.format').separator + '?\\d*$'
);

var _TextInputFilterIntegerRegEx = /^\d*$/;
var _TextInputFilterTimeEntryRegEx = /^\d*(?:\:|\.)?\d?\d?$/;

/*
 * oninput callbacks that implement text input filtering. Elements in this
 * associative array map to the values in TextInputFilter (above).
 */
var TextInputFilterHandlers = {
  // None
  0: function (e) {
    return true;
  },

  // Integer
  1: function (e) {
    e = e || window.event;
    el = e.target;
    if (_TextInputFilterIntegerRegEx.test(el.value)) {
      el.lvv = el.value; // last valid value
    } else {
      el.value = el.lvv || '';
    }
    return true;
  },

  2: function (e) {
    e = e || window.event;
    el = e.target;
    if (_TextInputFilterFloatRegEx.test(el.value)) {
      el.lvv = el.value; // last valid value
    } else {
      el.value = el.lvv || '';
    }
    return true;
  },

  // Email (TBD)
  3: function (e) {
    return true;
  },

  // Hours and optionally, minutes. We allow input like 1.5 or 1:30
  4: function (e) {
    e = e || window.event;
    el = e.target;
    if (
      _TextInputFilterFloatRegEx.test(el.value) ||
      _TextInputFilterTimeEntryRegEx.test(el.value)
    ) {
      el.lvv = el.value; // last valid value
    } else {
      el.value = el.lvv || '';
    }
    return true;
  },
};

function TextInput(
  attach,
  label,
  initial_value,
  container_style, // deprecated - specify width instead
  label_style, // deprecated - specify width instead
  input_style, // deprecated - specify width instead
  width,
  textInputFormat,
  onBlurCallback,
  onEnterCallback,
  onChangeCallback,
  change_id,
  onFocusCallback,
  options,
  labelIcon,
  shouldDisableInput,
  disablePopupText
) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  _i.inputContainer;
  _i.inputText;
  _i.label = label;
  _i.input_style = input_style;
  _i.initial_value = initial_value;
  _i.guid = Awe.getGuid();
  _i.onEnterCallback = onEnterCallback;
  _i.onChangeCallback = onChangeCallback;
  _i.onFocusCallback = onFocusCallback;
  _i.onBlurCallback = onBlurCallback;
  _i.change_id = change_id;
  _i.options = options || {};
  _i.labelIcon = labelIcon;
  _i.shouldDisableInput = shouldDisableInput;
  _i.disablePopupText = disablePopupText;
  _i.phasesAlertPopup;

  _i.alertData = [
    {
      type: 'label',
      value: null,
      label: 'label',
    },
  ];

  // ids
  _i.inputID = 'input' + _i.guid;

  _i.sanitizeInput = function () {
    // Changing the input value will move the cursor. This should only be done if
    // sanitization actually changed the value.
    var sanitizedInput = _i.inputText.value
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/^\s+/, '');
    if (sanitizedInput != _i.inputText.value) {
      _i.inputText.value = sanitizedInput;
    }
  };

  _i.handleEnterKeyPressCallback = function (e) {
    if (_i.shouldDisableInput) {
      return false;
    }
    e = e || window.event;
    if (e.keyCode == 13 && _i.onEnterCallback) {
      Awe.cancelEvent(e);
      _i.sanitizeInput();
      _i.onEnterCallback();
    }
    return true;
  };

  _i.onChangeInternalCallback = function (e) {
    if (textInputFormat) {
      TextInputFilterHandlers[textInputFormat](e);
    }
    if (_i.onChangeCallback) {
      _i.sanitizeInput();
      _i.onChangeCallback(_i.inputText.value, _i.change_id);
    }
    return true;
  };

  _i.showAlert = function () {
    if (_i.alertMessage == null || _i.alertMessage == '') return;

    _i.alertPopup.show('alertmsgshow' + _i.guid);
  };

  _i.buildAlertMessage = function () {
    _i.alertData[0].label = _i.alertMessage;
  };

  _i.setAlert = function (alertmsg) {
    _i.alertMessage = alertmsg;
    _i.buildAlertMessage();
    _i.alertPopup = new selectionPopup(
      350 * window.rm.l10n.getWidthMultiplier(),
      40,
      'mainCon',
      _i.alertData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onAlertClick,
      false,
      null /*blurCallback*/,
      'nw',
      null /*preferredDirections*/,
      true /*noautorotate*/
    );
    _i.showAlert();
  };

  _i.hideAlert = function () {
    _i.alertPopup.hide();
  };

  _i.getValue = function () {
    _i.sanitizeInput();
    return _i.inputText.value;
  };

  var correctDecimal =
    I18n.lookup('number.format').separator == ','
      ? function (n) {
          return n.replace(',', '.');
        }
      : function (n) {
          return n;
        };

  _i.getValueAsNumber = function () {
    return +correctDecimal(_i.getValue());
  };

  _i.setValue = function (value) {
    _i.inputText.value = value;
  };

  _i.setMaxLength = function (value) {
    _i.inputText.maxLength = value;
  };

  _i.setDefaultText = function (str) {
    _i.inputText.placeholder = str;
    if (navigator.appName == 'Microsoft Internet Explorer')
      _i.inputText.value = str;
  };

  _i.select = function () {
    _i.inputText.select();
  };

  _i.onTextInputClick = function () {
    _i.inputText.select();
    xRemoveEventListener(
      _i.inputText,
      platform.click,
      _i.onTextInputClick,
      false
    );
  };

  _i.onTextInputBlur = function () {
    if (_i.onBlurCallback) {
      _i.sanitizeInput();
      return _i.onBlurCallback();
    } else {
      return false;
    }
  };

  _i.onTextInputFocus = function () {
    if (_i.onFocusCallback) {
      _i.sanitizeInput();
      return _i.onFocusCallback();
    } else {
      return false;
    }
  };

  _i.constructor = function () {
    _i.inputContainer = document.createElement('DIV');
    _i.inputContainer.className =
      container_style || 'blockFloat defaultTextInputContainerSmall';

    _i.inputText = document.createElement('INPUT');
    if (textInputFormat == TextInputFilter.Email) {
      _i.inputText.setAttribute('type', 'email');
    } else {
      _i.inputText.setAttribute('type', 'text');
    }
    _i.inputText.className =
      input_style || 'blockFloatNot fnt-r-15 defaultTextInputSmall';
    _i.inputText.id = _i.inputID;

    if (label) {
      _i.inputText.setAttribute(
        'data-client-id',
        label.replace(/\s+/g, '-').toLowerCase()
      );
    }

    _i.inputText.setAttribute(
      'aria-describedby',
      _i.options.ariaDescribedby || ''
    );
    _i.inputText.value = initial_value;

    if (_i.label) {
      _i.labelText = document.createElement('LABEL');
      _i.labelText.className =
        label_style || 'blockFloatNot fnt-r-13 defaultTextInputLabel';
      _i.inputText.setAttribute('id', _i.inputID);
      _i.labelText.setAttribute('for', _i.inputID);
      _i.labelText.innerText = _i.label;
      if (_i.labelIcon) {
        const parentDiv = document.createElement('DIV');
        parentDiv.style.display = 'flex';
        parentDiv.appendChild(_i.labelText);
        parentDiv.appendChild(_i.labelIcon);
        _i.inputContainer.appendChild(parentDiv);
      } else {
        _i.inputContainer.appendChild(_i.labelText);
      }
    } else if (_i.options.arialabelledbyId) {
      _i.inputText.setAttribute('aria-labelledby', _i.options.arialabelledbyId);
    }

    if (_i.options.maxLength) {
      _i.inputText.setAttribute('maxlength', options.maxLength);
    }
    if (_i.shouldDisableInput) {
      _i.inputText.readOnly = true;
      _i.inputText.style.color = '#666666';

      if (_i.disablePopupText) {
        _i.phasesAlertPopup = phasesAlertPopup(
          _i.disablePopupText,
          _i.inputContainer
        );
        const mouseInOrFocus = () => {
          _i.phasesAlertPopup.show(_i.labelIcon, null, 12);
        };
        const mouseOutOrBlur = () => {
          _i.phasesAlertPopup.hide();
        };
        xAddEventListener(_i.inputText, 'mouseover', mouseInOrFocus, true);
        xAddEventListener(_i.inputText, 'focus', mouseInOrFocus, true);
        xAddEventListener(_i.inputText, 'mouseout', mouseOutOrBlur, true);
        xAddEventListener(_i.inputText, 'blur', mouseOutOrBlur, true);
      }
    }
    _i.inputContainer.appendChild(_i.inputText);
    _i.alertText = document.createElement('DIV');
    _i.alertText.className = 'blockFloatNot';
    _i.alertText.id = 'alertmsgshow' + _i.guid;
    _i.inputContainer.appendChild(_i.alertText);

    if (width) {
      _i.inputContainer.style.width = width + 'px';
      _i.inputText.style.width = _i.inputText.className.includes(
        'workStatusInput'
      )
        ? width + 'px'
        : width - 20 /* per defaultTextInputLabel */ + 'px';
      if (label) _i.labelText.style.width = width + 'px';
    }

    if (_i.attachTo) _i.attachTo.appendChild(_i.inputContainer);

    if (textInputFormat || onChangeCallback) {
      xAddEventListener(_i.inputText, 'input', _i.onChangeInternalCallback);
    }

    if (onEnterCallback) {
      xAddEventListener(
        _i.inputText,
        'keydown',
        _i.handleEnterKeyPressCallback
      );
    }

    xAddEventListener(_i.inputText, platform.click, _i.onTextInputClick, false);
    xAddEventListener(_i.inputText, 'blur', _i.onTextInputBlur, false);
    xAddEventListener(_i.inputText, 'focus', _i.onTextInputFocus, false);
  };
  _i.constructor();
}

function TextArea(
  attach,
  label,
  initial_value,
  container_style,
  label_style,
  input_style,
  callback
) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  _i.inputContainer;
  _i.label = label;
  _i.label_style = label_style;
  _i.initial_value = initial_value;
  _i.input_style = input_style;
  _i.container_style = container_style;
  _i.guid = Awe.getGuid();
  _i.alertData = [
    {
      type: 'label',
      value: null,
      label: 'label',
    },
  ];

  // ids
  if (label) {
    _i.inputID = label.replace(/\s+/g, '-') + _i.guid;
  }

  _i.sanitizeInput = function () {
    // Changing the input value will move the cursor. This should only be done if
    // sanitization actually changed the value.
    var sanitizedInput = _i.inputText.value.replace(/(<([^>]+)>)/gi, '');
    if (sanitizedInput != _i.inputText.value) {
      _i.inputText.value = sanitizedInput;
    }
  };

  _i.showAlert = function () {
    if (_i.alertMessage == null || _i.alertMessage == '') return;

    _i.alertPopup.show('alertmsgshow' + _i.guid);
  };

  _i.buildAlertMessage = function () {
    _i.alertData[0].label = _i.alertMessage;
  };

  _i.setAlert = function (alertmsg) {
    _i.alertMessage = alertmsg;
    _i.buildAlertMessage();
    _i.alertPopup = new selectionPopup(
      350,
      40,
      'mainCon',
      _i.alertData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onAlertClick,
      false,
      null /*blurCallback*/,
      'nw',
      null /*preferredDirections*/,
      true /*noautorotate*/
    );
    _i.showAlert();
  };

  _i.getValue = function () {
    _i.sanitizeInput();
    return _i.inputText.value;
  };

  _i.constructor = function () {
    _i.inputContainer = document.createElement('DIV');
    _i.inputContainer.className = _i.container_style;

    _i.attachTo.appendChild(_i.inputContainer);

    _i.labelText = document.createElement('LABEL');
    _i.labelText.className = label_style;
    _i.labelText.innerText = label;
    _i.inputContainer.appendChild(_i.labelText);

    _i.inputText = document.createElement('TEXTAREA');
    _i.inputText.rows = 3;
    _i.inputText.cols = 33;
    _i.inputText.className = input_style;
    _i.inputText.id = _i.inputID;
    _i.labelText.setAttribute('for', _i.inputID);
    _i.inputText.value = initial_value;
    _i.inputContainer.appendChild(_i.inputText);

    _i.alertText = document.createElement('DIV');
    _i.alertText.className = 'blockFloatNot';
    _i.alertText.id = 'alertmsgshow' + _i.guid;
    _i.inputContainer.appendChild(_i.alertText);

    if (callback) {
      xAddEventListener(_i.inputText, 'input', callback);
    }
  };
  _i.constructor();
}

function PasswordInput(
  attach,
  label,
  initial_value,
  container_style,
  label_style,
  input_style
) {
  var _i = this;

  _i.attachTo = xGetElementById(attach);
  _i.inputContainer;
  _i.inputText;
  _i.label = label;
  _i.label_style = label_style;
  _i.initial_value = initial_value;
  _i.input_style = input_style;
  _i.container_style = container_style;
  _i.guid = Awe.getGuid();

  _i.alertData = [
    {
      type: 'label',
      value: null,
      label: 'label',
    },
  ];

  // ids
  _i.inputID = 'password_id' + _i.guid;

  _i.showAlert = function () {
    if (_i.alertMessage == null || _i.alertMessage == '') return;

    _i.alertPopup.show('alertmsgshow' + _i.guid);
  };

  _i.buildAlertMessage = function () {
    _i.alertData[0].label = _i.alertMessage;
  };

  _i.setAlert = function (alertmsg) {
    _i.alertMessage = alertmsg;
    _i.buildAlertMessage();
    _i.alertPopup = new selectionPopup(
      350,
      40,
      'mainCon',
      _i.alertData,
      compSpritesButtonsIcons.wideDialogSpecs,
      _i.onAlertClick,
      false,
      null /*blurCallback*/,
      'nw',
      null /*preferredDirections*/,
      true /*noautorotate*/
    );
    _i.showAlert();
  };

  _i.getValue = function () {
    return _i.inputText.value;
  };

  _i.constructor = function () {
    _i.inputContainer = document.createElement('DIV');
    _i.inputContainer.className = _i.container_style;

    _i.attachTo.appendChild(_i.inputContainer);

    if (label) {
      _i.labelText = document.createElement('LABEL');
      _i.labelText.className = label_style;
      _i.labelText.innerText = label;
      _i.labelText.setAttribute('for', _i.inputID);
      _i.inputContainer.appendChild(_i.labelText);
    }

    _i.inputText = document.createElement('INPUT');
    _i.inputText.type = 'password';
    _i.inputText.name = 'password';
    _i.inputText.id = 'password_id' + _i.guid;
    _i.inputText.className = input_style;

    if (initial_value) _i.inputText.value = initial_value;
    else _i.inputText.value = '';

    _i.inputContainer.appendChild(_i.inputText);

    _i.alertText = document.createElement('DIV');
    _i.alertText.className = 'blockFloatNot';
    _i.alertText.id = 'alertmsgshow' + _i.guid;
    _i.inputContainer.appendChild(_i.alertText);
  };
  _i.constructor();
}
